import React, {useEffect, useState} from 'react';
import useToken from '../App/useToken';
import {Button, Grid, FormControlLabel, FormControl, RadioGroup, Radio, FormLabel} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export function PRExportCSVDialog({ open, onClose, fileName, setFileName, filter, searchCount, setErrorModalOpen, exportCsv}) {    
    const handleClose = () => {
        onClose();
    };
    const { token } = useToken();

    const handleFilenameChange = (e) => {
        setFileName(e.target.value)
    }

    return (
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"} >
        <DialogTitle>Products selected</DialogTitle>
        <DialogContent>
            <Grid container direction="column">
                <Grid item>
                    {searchCount !== undefined && searchCount > 0?
                    <FormControl>
                        <FormLabel>{searchCount}  items will be exported</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            onChange={handleFilenameChange}
                            defaultValue="0"
                         >
                            <FormControlLabel value="0" control={<Radio />} label="[ProductReport]_[DDMMYYYY]_[uid].csv" />
                            {filter.searchTerms.providerGLN !== undefined && filter.searchTerms.providerGLN !== null && filter.searchTerms.providerGLN !== '' ?
                                <FormControlLabel value="1" control={<Radio />} label="[ProductReport]_[DDMMYYYY]_[InformationProviderName]_[uid].csv" />
                            :''}
                        </RadioGroup>
                    </FormControl>
                    :"No products found with selected filters"}
                </Grid>
            </Grid>
            
        </DialogContent>
        <DialogActions>
            <Grid item container direction="row" columnSpacing={1} justifyContent="end">
                <Grid item>{searchCount !== undefined && searchCount > 0?<Button variant='main' onClick={() => exportCsv()}>Export</Button>:''}</Grid>
                <Grid item><Button variant='main' onClick={() => setErrorModalOpen(false)}>Cancel</Button></Grid>
            </Grid>
        </DialogActions>
      </Dialog>
    );
  }