import React, { useState, useEffect } from "react";
import { Grid, Tabs, Link, Box, Typography, Button } from '@mui/material';
import toolsProductDetailApi from "../../tools/toolsProductDetailApi";
import useProductDetailFilter from '../App/useProductDetailFilter';
import {useParams} from "react-router-dom";
import config from "../../config/config";
import ImgProdDefault from "../../img/defaultProduct.png";
import CircularStatic from "../CircularStatic/CircularStatic";
import ProductTab from "../ProductTab/ProductTab";

export default function PublicProductDetail() {
	const { getPublicProductDetail, getDataDefinition, getProductMenu } = toolsProductDetailApi();
	const { gtin } = useParams();
	const [product, setProduct] = useState(undefined);
	const [completeness, setCompleteness] = useState(0);
	const [enrichments, setEnrichments] = useState(undefined);
	const [packagingInformation] = useState([]);
	const FOOD_CATEGORY = "50000000";
	const [productMenu, setProductMenu] = useState([]);
	const [dataDefinition, setDataDefinition] = useState(undefined);

	useEffect(() => {
		getPublicProductDetail({ cu_gtin_code: gtin }).then(result => {
	        if(result.code === 1) {
	            if(result.response != undefined && result.response.new_product != undefined && result.response.new_product.products != undefined) {
	                if(Array.isArray(result.response.new_product.products) && result.response.new_product.products.length > 0){
	                    let product = result.response.new_product.products[0];
	                    setProduct(product);
	                    setCompleteness(product.enrichmentInfo.completed*100/product.enrichmentInfo.total);

	                    let marketing = {'1ws': product.enrichments['1WS_Processed'].ecommerce, 'TS' : product.enrichments['TrustedSource'].ecommerce}
	                    let ingredients = {'1ws': product.enrichments['1WS_Processed'].ingredients , 'TS' : product.enrichments['TrustedSource'].ingredients, 'Food' : product.Category === FOOD_CATEGORY ? true : false};
	                    let nutritional = {'1ws': {'processed': product.enrichments['1WS_Processed'].nutritional, 'nonProcessed' : undefined}, 'TS': {'processed': product.enrichments['TrustedSource'].nutritional, 'nonProcessed' : undefined}};
	                    let allergens = {'1ws': product.enrichments['1WS_Processed'].allergens, 'TS' : product.enrichments['TrustedSource'].allergens};
	                    let healthcare = {'1ws': product.enrichments['1WS_Processed'].healthcare, 'TS' : product.enrichments['TrustedSource'].healthcare};
	                    let dam = {'image_id' : product.image_id, 'images' : product.images};
	                    let packaging = {'1ws': setPackagingInformation(product), 'TS' : []};
	                    let sustainability = {'1ws': [], 'TS' : []};
	                    
	                    // Nutritional non processed
	                    product.enrichments['1WS'].map((elem) => {
	                        if(Object.keys(elem).indexOf("nutrientInformation") !== -1){
	                            nutritional['1ws']['nonProcessed'] = elem["nutrientInformation"];
	                        }
	                    })

	                    delete product.enrichments;
	                    delete product.healthcare;

	                    setEnrichments({
	                        "basicData" : product,
	                        "ecommerce" : marketing, 
	                        "ingredients" : ingredients,
	                        "nutritional" : nutritional,
	                        "allergens" : allergens,
	                        "healthcare" : healthcare,
	                        "dam" : dam,
	                        "packaging": packaging,
	                        "sustainability": sustainability
	                    });
	                }
	            }
	        }
	    });

	    getDataDefinition().then(result => {
            if(!result.error) {
                if(result.data != undefined) {
                    setDataDefinition(result.data)
                }
            }
        });

        getProductMenu().then(result => {
            if(!result.error) {
                if(result.data != undefined) {
                    setProductMenu(result.data)
                }
            }
        });
	}, [gtin]);

	const loadProductImg = ((imageId, images) => {
        let image = undefined;
        images.map((imageElem) => {
            if(imageElem.pID === imageId) {
                image = imageElem.image_name;
            }
        })

        if(image != undefined) {
            let imgSrc = image;

            if(imgSrc.indexOf("http") != -1){
                imgSrc = config.mediaPath +'thumbnail?url=' + encodeURIComponent(imgSrc);
            }else{
                imgSrc = config.mediaPath + 'thumbnail?url=' + encodeURIComponent(config.API_URL + config.detailmgPath + imgSrc);
            }

            return <Box component="img" style={imgStyle} src={imgSrc} ></Box>
        } else {
            return <Box component="img" style={imgStyle} src={ImgProdDefault}></Box>
        }
    });

	const setPackagingInformation =  ((product) => {
        //console.log(product.enrichments['1WS'])    
        product.enrichments['1WS'].map((element, index) => {
            if(element.packagingInformation !== undefined) {
                packagingInformation["packagingInformation"] = element.packagingInformation
            }
            if(element.packagingDate !== undefined) {
                packagingInformation["packagingDate"] = element.packagingDate
            }
        })

        return packagingInformation
    });

	let imgStyle = {width: "50%"};

	return  <Grid container>
				<Grid container item xs={12} sx={{background: '#1eb1e0', padding: '20px'}} alignContent="center">
                    <Typography variant="h4" component="h4" color="white" fontWeight="700" fontSize="30px">
                    	Product Details
                    </Typography>
                </Grid>
                {product !== undefined ? 
                <Grid container>
                	<Grid container item xs={12} height="20vh">
	                	<Grid container item xs={2} justifyContent="center" alignItems="center">
	                        {loadProductImg(product.image_id, product.images)}
	                    </Grid>
	                    <Grid container item xs={6} alignContent="center">
	                        <Grid item xs={12}><Typography variant="productTitle" fontSize="16px">{product.gtinName}</Typography></Grid>
	                        <Grid item xs={12}><Typography variant="productType" fontSize="14px">{product.productType}</Typography></Grid>
	                        <Grid item xs={12}><Typography variant="productSubtitle" fontSize="12px">{product.informationProviderName}</Typography></Grid>
	                    </Grid>
	                    <Grid container xs={4} justifyContent="center" alignItems="center">
		                    <Grid item>
		                        <CircularStatic total={completeness} />
		                    </Grid>
		                    <Grid item>
		                        <Typography variant="blue">Completeness</Typography>
		                    </Grid>
		                </Grid>
	                </Grid>
	                <Grid container item xs={12} height="65vh">
	                	<ProductTab tabsDefinition={productMenu} enrichments={enrichments} definition={dataDefinition} publicDomain={true}/>
	                </Grid>
                </Grid>
                :''}
			</Grid>
}