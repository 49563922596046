import React, { useState } from "react";
import { Box, Grid, Typography, FormControl, InputLabel, OutlinedInput, Button, Tooltip } from '@mui/material';
import config from "../../config/config";

const ImageThumb = ({ image , defaultImage, cleanImage}) => {
    let imgStyle = {width: "100px", height: "100px", borderRadius:"150px", filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",  flex: "none", order: "0", flexGrow: "0", cursor: 'pointer'};
    let imgUrl =    image !== undefined  && image !== "" && image !== null 
                        ? typeof image === "string" 
                            ? image.includes('base64') 
                                ? image 
                                : config.API_URL + "/public/uploaded_files/" + image 
                            : URL.createObjectURL(image) 
                        : undefined;
    return  imgUrl !== "" && imgUrl !== null && imgUrl !== undefined ?
            <Tooltip title="Remove Image">
                <Box 
                    component="img" 
                    style={imgStyle} 
                    className="pictureBox" 
                    src={imgUrl} 
                    onClick={cleanImage}
                    sx={{
                        '&:hover': {
                          backgroundColor: 'black',
                          opacity: [0.9, 0.8, 0.7],
                        },
                      }}
                >
                </Box> 
            </Tooltip>
        :
            <Box 
                component="img" 
                style={imgStyle} 
                className="pictureBox" 
                src={defaultImage}
            >
            </Box> 
        ;
};

export default function UploadProfileImg({value, setValue, defaultImage}) {

    const cleanImage = () => {
        setValue(undefined);    
    }

    return (
        <Grid container direction="row" alignItems={"center"} columnSpacing={2}>
            <Grid item>
                <ImageThumb image={value} defaultImage={defaultImage} cleanImage={cleanImage}/>
            </Grid>
            <Grid item>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <OutlinedInput
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type={"file"}
                        onChange={(e) => {setValue(e.target.files[0])}}
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="outlined" component="span" >
                            Upload Picture
                        </Button>
                    </label> 
                </FormControl>
            </Grid>
        </Grid> 
  );
}