import React, { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export function AlertResponse({ open, autoHideDuration, anchorOrigin, onCloseSnackbar, onCloseAlert, severity, variant, message}) {

	return  <Stack spacing={2} sx={{ width: '100%' }}>
	            <Snackbar 
	                open={open}
	                autoHideDuration={autoHideDuration}
	                anchorOrigin={ anchorOrigin }
	                onClose={onCloseSnackbar}
	            >
	            <Alert onClose={onCloseAlert} severity={severity} variant={variant}>
	                {message}
	            </Alert>
	            </Snackbar>
	        </Stack>
}