import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export function SimpleDialog({ open, onClose, title, component, textButton, actions}) {    
    const handleClose = () => {
        onClose();
    };

    return (
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"} >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <Box
                noValidate
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                }}
            >
                {component}
            </Box>
        </DialogContent>
        <DialogActions>
            {actions === undefined ? 
                <Button variant="orange" onClick={handleClose}>{textButton !== undefined && textButton !== ''?textButton:'Accept'}</Button>
            :
                actions
            }
        </DialogActions>
      </Dialog>
    );
  }