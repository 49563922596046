import React, { useEffect, useState } from "react";
import { Grid, Box, ListItem, Typography, ListItemButton } from '@mui/material';
import typeLables from "./TypeLabels"
import {useParams} from "react-router-dom";

export default function ProductHierarchyElementSimple({item, handleClick}) {
    const {GTIN} = useParams();

    let style = { backgroundColor: "#F2FCFF", borderRadius: "10px", width:"100%", fontSize: "1rem"}
    let productTypeLabelStyle = { paddingTop: '15px', paddingLeft: '8px', fontWeight: '700', fontSize: '12px'}
    let productQuantityStyle = {paddingTop: '8px', paddingLeft: '8px', fontSize: '12px', fontFamily: 'Proxima Nova'}
    let productNameStyle = {paddingLeft: '8px', fontSize: '10px', fontWeight: '700', color: '#3078B0', textTransform: 'uppercase'}

    return  <ListItem key={item.gtin} width="100%" disableGutters>
                <ListItemButton onClick={() => handleClick(item.GTIN)} width="100%" style={{paddingLeft: '8px', paddingRight: '8px'}} selected={GTIN === item.GTIN?true:false}>
                    <Grid style={style} width="100%" direction="column" rowSpacing={1} class="itemHierarchy">
                        <Grid item style={productTypeLabelStyle}>
                            <Typography variant="importantHierarchy">{item.productType} - {typeLables[item.productType]}</Typography>
                        </Grid>
                        <Grid item style={productQuantityStyle}>
                            {item.gtin} ({item.totalQuantityOfNextLowerLevelTradeItem === undefined ? 1 : item.totalQuantityOfNextLowerLevelTradeItem})
                        </Grid>
                        <Grid style={productNameStyle}>
                            {item.gtinName}
                        </Grid>
                    </Grid>
                </ListItemButton>  
            </ListItem>

       
}