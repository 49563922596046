import React from "react";
import { Box, Typography} from '@mui/material';
import { useNavigate } from "react-router-dom";

export default function DashPanel({panelStyle, panelData, icon, status}) {
    const navigate = useNavigate()

    const moveTo = () => {
        navigate("/product-browser/" + status)
    }

    const boxSX = {
        "&:hover": {
          cursor: "pointer",
        },
      };

    return  <Box sx={boxSX} style={panelStyle} onClick={moveTo}>
                <Typography variant="h4" component="h4">
                    {icon}
                </Typography>
                <Typography variant="h4" component="h4" fontSize="30px" lineHeight="36px">
                    {panelData.count}
                </Typography>
                <Typography variant="body1" component="body1">
                    {panelData.status_name}
                </Typography>
            </Box>;
}