const config = {
    API_URL: process.env.REACT_APP_API_URL,
    mediaPath: process.env.REACT_APP_MEDIA_PATH,
    thumbnailImgPath: process.env.REACT_APP_THUMBNAIL_IMG_PATH,
    detailmgPath: process.env.REACT_APP_DETAIL_IMG_PATH,
    APP_PROD_URL: "https://prod.trustedsource.co.za/",
    APP_PREPROD_URL: process.env.REACT_APP_PREPROD_URL,
    APP_PUBLIC_DOMAIN: process.env.REACT_APP_PUBLIC_DOMAIN,
    APP_PRIVATE_DOMAIN: process.env.REACT_APP_PRIVATE_DOMAIN
}

export default config;