import React, { useState } from 'react';
import { Grid, TextField, Button, Autocomplete, FormControlLabel, Switch, Link, Chip} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useFilter from '../App/useFilter';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export function PbFiltersDialog({ fixedFilters, availableCategories, setCategory, setCatAutocompleteValues, loadSubcategory, catAutocompleteValues, selectableSubCategories, setSubCategory, setSubcatAutocompleteValues, subcatAutocompleteValues, availableBrands, setBrand, setBrandAutocompleteValues, brandAutocompleteValues, filter, setSku, availableStatus, setStatus, setStatusAutocompleteValues, statusAutocompleteValues, setImages, open, setOpen, handleSearch, setCallToken, token}) {
    const handleClose = () => {
        setOpen(false);
    };

    const { clearFilters } = useFilter();
    const handleReset = () => {
        clearFilters();
        setCatAutocompleteValues([]);
        setSubcatAutocompleteValues([]);
        setBrandAutocompleteValues([]);
        setStatusAutocompleteValues([]);
    };

    function subcategoriesComboBox(props) {
        return <TextField {...props} label="All Subcategories" />
    }

    function categoriesComboBox(props) {
        return <TextField {...props} label="All Categories" />
    }

    function statusComboBox(props) {
        return <TextField {...props} label="All Status" />
    }

    function brandComboBox(props) {
        return <TextField {...props} label="All Brands" />
    }

    function BootstrapDialogTitle(props) {
      const { children, onClose, ...other } = props;

      return (
        <DialogTitle style={{padding: "25px 40px", fontSize: '20px', color:'#3284C6', fontWeight: 400, lineHeight:'24px', backgroundColor: '#ffffff', boxShadow: '0px -1px 8px rgba(30, 177, 224, 0.1)'}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                    position: 'absolute',
                    right: 40,
                    top: 25,
                    color: "#3284C6",
                    padding: '0'
                  }}
                >
                    <CloseIcon/>
                </IconButton>
              ) : null}
        </DialogTitle>
      );
    }

    BootstrapDialogTitle.propTypes = {
      children: PropTypes.node,
      onClose: PropTypes.func.isRequired,
    };

    let gridCellStyle = {padding:"5px 5px"};
    let inputText = {fontWeight: "100", width:"100%", fontFamily: "Proxima Nova"};

    return (
        <Dialog open={open} 
                onClose={handleClose} 
                fullWidth="true" maxWidth="xl" 
                TransitionComponent={Transition}
                transitionDuration={1000}
                PaperProps={{style: {backgroundColor: '#FBFBFB',borderRadius: '10px 0px 0px 10px'}}}
                >   
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Select your filters
            </BootstrapDialogTitle>
            <DialogContent sx={{padding: "40px!important"}}>
                <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} paddingBottom="40px">{fixedFilters}</Grid>
                <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} paddingBottom="40px">
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <Autocomplete
                            multiple
                            loading
                            limitTags={2}
                            disablePortal
                            fullWidth
                            disableCloseOnSelect
                            id="combo-box-categories"
                            options={availableCategories}
                            onChange={(e, v) => {setCategory(v);setCatAutocompleteValues(v);loadSubcategory(v)}}
                            renderInput={categoriesComboBox}        
                            value={catAutocompleteValues}
                            ChipProps={{
                                color:"multiChips",
                                variant:"multipleChips",
                                sx:{fontSize: '11px', 
                                    fontFamily: 'Proxima Nova', 
                                    lineHeight: '13px', 
                                    border: '1px solid #1EB1E0', 
                                    borderRadius: '57px', 
            
                                }
                            }}
                        />                 
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <Autocomplete
                            multiple
                            loading
                            limitTags={2}
                            disablePortal
                            fullWidth
                            disableCloseOnSelect
                            id="combo-box-subcategories"
                            options={selectableSubCategories}
                            onChange={(e, v) => {setSubCategory(v);setSubcatAutocompleteValues(v)}}
                            renderInput={subcategoriesComboBox}        
                            value={subcatAutocompleteValues}
                            ChipProps={{
                                color:"multiChips",
                                variant:"multipleChips",
                                sx:{fontSize: '11px', 
                                    fontFamily: 'Proxima Nova', 
                                    lineHeight: '13px', 
                                    border: '1px solid #1EB1E0', 
                                    borderRadius: '57px', 
            
                                }
                            }}

                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <Autocomplete
                            multiple
                            loading
                            limitTags={2}
                            disablePortal
                            fullWidth
                            disableCloseOnSelect
                            id="combo-box-brands"
                            options={availableBrands}
                            onChange={(e, v) => {setBrand(v);setBrandAutocompleteValues(v)}}
                            renderInput={brandComboBox}        
                            value={brandAutocompleteValues}
                            ChipProps={{
                                color:"multiChips",
                                variant:"multipleChips",
                                sx:{fontSize: '11px', 
                                    fontFamily: 'Proxima Nova', 
                                    lineHeight: '13px', 
                                    border: '1px solid #1EB1E0', 
                                    borderRadius: '57px', 
            
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} paddingBottom="40px">
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <TextField 
                            label="SKU"
                            id="sku-basic"
                            variant="outlined" 
                            style={inputText}
                            value={filter.search.sku}
                            onChange={(e) => {setSku(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <Autocomplete
                            multiple
                            loading
                            limitTags={2}
                            disablePortal
                            fullWidth
                            disableCloseOnSelect
                            id="combo-box-status"
                            options={availableStatus}
                            onChange={(e, v) => {setStatus(v);setStatusAutocompleteValues(v)}}
                            renderInput={statusComboBox}        
                            value={statusAutocompleteValues}
                            ChipProps={{
                                color:"multiChips",
                                variant:"multipleChips",
                                sx:{fontSize: '11px', 
                                    fontFamily: 'Proxima Nova', 
                                    lineHeight: '13px', 
                                    border: '1px solid #1EB1E0', 
                                    borderRadius: '57px', 
            
                                }
                            }}
                        />
                    </Grid>
                     <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle} >                    
                        <FormControlLabel control={<Switch style={{color:"primary"}} defaultChecked={filter.search.images == "images"} onChange={(e) => {setImages(e.target.checked ? "images" : null)}}/>} label="With images" sx={{color: '#1EB1E0'}}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{backgroundColor: '#FFFFFF', boxShadow: '0px -1px 8px rgba(30, 177, 224, 0.1)', padding: '30px 40px'}}>
                <Grid item container justifyContent={"space-between"} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button variant="orange-secondary" style={inputText} onClick={() => {handleClose();handleReset();}}>Reset filters</Button>
                    <Button variant="orange" style={inputText} onClick={() => {handleClose();handleSearch()}}>Apply filters</Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
  }