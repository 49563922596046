import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Typography, TextField,Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Tooltip } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {useParams} from "react-router-dom";
import Header from "../../../Header/Header";
import useToken from '../../../App/useToken';
import useUser from "../../../App/useUser";
import toolsManagementApi from "../../../../tools/toolsManagementApi";
import CircularProgress from '@mui/material/CircularProgress';
import UserInfo from "./UserInfo";
import UserEdit from "./UserEdit";
import ProfileImg from "../../../ProfileImg/ProfileImg";
import userTypes from "../userTypes";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { User } from "../../../../entities/User";
import {options} from "../../../../permission/selectOptions";
import { Can } from "@casl/react";
import { useSelector } from 'react-redux';
import useToolsNotifications from "../../../../tools/ToolsNotifications";
import usrBack from "../../../../img/userbackground.png"; 
import UserCard from "./userCard";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const ENTITY_NAME = "User"

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      width: 400,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        paddingTop: 20,
        paddingBottom: 20,
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

export default function UserDetail({userId}) {
    const {error, success} = useToolsNotifications();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { token } = useToken();
    const { user: userStorage } = useUser();
    const { id } = useParams();
    const { getUserDetails, getCompanyDetails, getCompanyListFull, updateUser } = toolsManagementApi();
    const [user, setUser] = useState(undefined);
    const [userBU, setUserBU] = useState(undefined);
    const [editMode, setEditMode] = useState(false);
    const [userTypeOptions, setUserTypeOptions] = useState([]);
    const [userPermission, setUserPermission] = useState(undefined)
    const userAbility = useSelector((state) => state.userAbility.value);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogText, setDialogText] = useState(undefined);
    const [dialogAcceptBtnText, setDialogAcceptBtnText] = useState(undefined);
    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const displayImg = (url) => {
        return <ProfileImg imgUrl={url} />
    }

    const displayUserType = (intType) => {
        return userTypes[intType]
    }

    const displayLanguage = (iso) => {
        let name = ""

        accountData["userLanguage"]["options"].map(lang => {
            if(lang.value === iso) {
                name = lang.label;
            }
        })

        return name
    }

    const displayBooleanValues = (value) => {
        return value?'Yes':'No'
    }

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(baseURL);
          };
        });
      };

    const handleUserImageChange = (value) => {
        if(value !== undefined) {
            getBase64(value).then(result => {
                setUserPartial("user_image", result)
            })
        } else {
            setUserPartial("user_image", undefined)
        }
    }

    const accountDataDefault = {
        "InformationProviderGLN" : { label : "GLN", display : undefined, type : "select", noEdit: false, onChange: undefined, options : undefined, textTransform: false},
        "CompanyName" : { label : "company", display : undefined, type : "text", noEdit: true, onChange: undefined, options : undefined},
        "user_type" : { label : "type", display : displayUserType, type : "select", noEdit: false, onChange: undefined, options : []},
        "userLanguage" : { label : "language", display : displayLanguage, type : "select", noEdit: false, onChange: undefined, options : [
            {label: "English", value: "EN"}
        ]},
        "user_name": {label: 'Login Username' , display: undefined, type: 'text',hide: false, noEdit: false, onChange: undefined, options: undefined},
        "user_password": {label: 'Login Password', display: undefined, type: 'password', hide: true, noEdit: false, onChange: false, options: false},
        "apiUser" : { label : "API user", display : displayBooleanValues, type : "switch", noEdit: false, onChange: undefined, options : undefined, textTransform: false},
        "isDataController" : { label : "Data controller", display : displayBooleanValues, type : "switch", noEdit: false, options : undefined},
    }
    
    const personalInfoDefault = { 
        "first_name" : { label : "First name", display : undefined, type : "text", noEdit: false, onChange: undefined, options : undefined},
        "last_name" : { label : "Last name", display : undefined, type : "text", noEdit: false, onChange: undefined, options : undefined},
        "email" : { label : "Email", display : undefined, type : "text", noEdit: false, onChange: undefined, options : undefined},
        "user_telephone" : { label : "Phone", display : undefined, type : "text", noEdit: false, onChange: undefined, options : undefined},
        "user_image" : { label : "Profile image", display : displayImg, hide:true, type : "img", noEdit: true, onChange: undefined, options : undefined},
    }
    const [companies, setCompanies] = useState([]);
    const [accountData, setAccountData] = useState(accountDataDefault);
    const [personalInfo, setPersonalInfo] = useState(personalInfoDefault)

    const setUserPartial = (key, value) => {
        let userCopy = {...user}
        userCopy[key] = value;

        setUser(userCopy)
    }

    const glnChange = (value) => {
        if(user !== undefined) {
            let company = undefined
            accountData["InformationProviderGLN"]["options"].map((c) => {
                if(c.value === value) {
                    company = c
                }
            })

            if(company !== undefined) {
                let userCopy = {...user}
                
                userCopy["InformationProviderGLN"] = company.value;
                userCopy["CompanyName"] = company.label;
                userCopy["user_company"] = company.id;

                setUser(userCopy)
            }
        }
    }

    const switchEditMode = () => {
        
        setUser(userBU)
        setEditMode(!editMode)
    }

    const saveData = (change) => {
        let userSend = {...user}
        userSend["token"] = token;
        updateUser(userSend).then(result => {
            if(result.code === 1) {
                success("User updated successfully");
                setUserBU(user);
                if(change !== false) {
                    setEditMode(!editMode);
                }
                if(result.response != undefined) {
                    
                }
            } else {
                error("User update error");
            }
        });        
    }

    const handleStateChange = (enable) => {
        const mainText = enable ? "User will be enabled." : "User will be disabled.";
        const btnText = enable ? "Yes, enable the user" : "Yes, disable the user";

        setUserPartial("is_active", enable ? 1 : 0);
        setDialogText(mainText);
        setDialogAcceptBtnText(btnText);
    }

    const handleDialogClose = (option) => {
        if(option) {
            saveData(false);
        }
        setDialogAcceptBtnText(undefined);
        setDialogText(undefined);
        setDialogOpen(false);
    }

    useEffect(() => {
        if(dialogText !== undefined && dialogAcceptBtnText !== undefined) {
            setDialogOpen(true);
        }
    }, [dialogText, dialogAcceptBtnText])

    useEffect(()=>{
        getCompanyListFull({searchTerms: "0", token: token}).then(result => {
            if(result.code === 1) {
                if(result.response != undefined) {
                    setCompanies(result.response);
                }
            }
        })
        getCompanyDetails({token: token}).then(result => {
                if(result.code === 1) {
                    if(result.response != undefined) {
                        setCompanies([result.response])
                    }
                }
            }).catch((error) => {
            setCompanies([{id: userStorage.company_id, CompanyGLN : userStorage.InformationProviderGLN, CompanyName : userStorage.companyName}])
        });
    }, [])

    useEffect(()=>{
        if(companies !== undefined){
            let copyAccountInfo = {...accountData};
            let processedCompanies = [];

            companies.map(company => {
                processedCompanies.push({label: company["CompanyName"], value: company["CompanyGLN"], id: company["id"]})
            })

            copyAccountInfo["InformationProviderGLN"]["options"] = processedCompanies;
            copyAccountInfo["InformationProviderGLN"]["onChange"] = glnChange;
            setAccountData(copyAccountInfo);
        }
    }, [companies])

    useEffect(() => {
        if(userId !== undefined) {
            getUserDetails({user_id: userId, token: token}).then(result => {
                if(result.code === 1) {
                    if(result.response != undefined) {
                        setUserBU(result.response);
                    }
                }
            });
        } else {
            if(id !== undefined) {
                getUserDetails({user_id: id, token: token}).then(result => {
                    if(result.code === 1) {
                        if(result.response != undefined) {
                            setUserBU(result.response);
                        }
                    }
                });
            }
        }
    }, [id, userId])

    useEffect(() => {
        setUser(userBU);
    }, [userBU])

    useEffect(() => {
        if(user !== undefined) {
            setUserPermission(new User(user.id.toString(), user.InformationProviderGLN, user.is_active === 1, user.enrichmentPermissions))
            console.log(user)
            let copyPersonalInfo = {...personalInfo};
            copyPersonalInfo["user_image"]["onChange"] = handleUserImageChange;
            setPersonalInfo(copyPersonalInfo);
        }
    }, [user])

    useEffect(() => {
        if(userStorage !== undefined) {
            let tempOptions = options[userStorage.user_type][ENTITY_NAME]["user_type"];
            let copyAccountData = {...accountData};
            copyAccountData["user_type"]["options"] = tempOptions;
            setAccountData(copyAccountData);
        }
    }, [userStorage])

    let iconStyle = {fontSize: "50px"}
    let subtitleStyle = {
        fontFamily: 'Figtree',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '26px',
        alignItems: 'center',
        color: '#4A4F53',
        width: 'auto'
    }

    let userBoxStyle = {
        backgroundImage: `url(${usrBack})`,
        backgroundRepeat:"no-repeat",
        backgroundSize:"contain" ,
        backgroundColor: "#FBFBFB",
    }

    const gridStyle = {
        background: "#FFFFFF",
        borderRadius:"10px", 
        paddingLeft: "20px",
        minHeight: "370px",
    }

    const mainStyle = {
        padding: "25px",
        paddingTop: "100px",
    }
    console.log(userBU)

    

    return  <Box name = "User"  height="100%" sx={userBoxStyle}>
                {userId === undefined ? 
                    <Header name="User" />
                : 
                    ""
                }                
                {user !== undefined ?
                    <Grid container item height={"90%"} overflow={"auto"} xs={12}>
                        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="flex-start" direction="row" height={"100%"} sx={mainStyle}>
                            <Grid item xs={0.5}></Grid>
                            <Grid item xs={3.5} container justifyContent="center" alignItems="flex-start"  sx={gridStyle} rowSpacing={1}>
                                <UserCard userBU={userBU} setUserBU={setUserBU} />
                            </Grid>
                            <Grid item xs={0.5}></Grid>
                            {editMode ? 
                                <Grid item container xs={7} sx={gridStyle} direction="column">
                                    <Can I="update" this={userPermission} ability={userAbility}>
                                        <Grid item container xs={12} direction="row" columnSpacing={1} justifyContent={"flex-end"} alignItems={"center"} sx={{ paddingTop: "15px !important", paddingBottom: "15px !important", paddingRight: "15px !important" }}>
                                            <Grid item>
                                                <Button 
                                                    onClick={saveData}
                                                    variant="emailButton"
                                                    startIcon={<CheckCircleOutlineOutlinedIcon style={{color:"#E16C43"}} />}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button 
                                                    onClick={switchEditMode}
                                                    startIcon={editMode ? <CancelOutlinedIcon style={{color:"#E16C43"}} /> : <CreateOutlinedIcon style={{color:"#E16C43"}} />}
                                                    variant="emailButton"
                                                >
                                                    {editMode ? "Cancel" : "Edit User"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Can>
                                    <Grid item xs={12}>
                                    <   Divider variant="fullWidth" color={"#B6B6B6"} />
                                    </Grid>
                                    <UserEdit user={user} accountData={accountData} personalInfo={personalInfo} setUserPartial={setUserPartial} />
                                </Grid>
                            :
                                <Grid item container xs={7} sx={gridStyle} direction="column">
                                    <Grid item container xs={12} direction="row" justifyContent={"flex-end"} alignItems={"center"} sx={{ paddingTop: "15px !important", paddingBottom: "15px !important", paddingRight: "15px !important"  }}>
                                        <Can I="update" this={userPermission} ability={userAbility}>
                                            <Grid item>
                                                <Button 
                                                    onClick={switchEditMode}
                                                    startIcon={editMode ? <CancelOutlinedIcon style={{color:"#E16C43"}} /> : <CreateOutlinedIcon style={{color:"#E16C43"}} />}
                                                    variant="emailButton"
                                                >
                                                    {editMode ? "Cancel" : "Edit User"}
                                                </Button>
                                            </Grid>
                                        </Can>
                                        <Can I="changeState" this="User" ability={userAbility}>
                                            <Grid item>
                                                <Button
                                                    id="dropdown-arrow-button"
                                                    aria-controls={open ? 'dropdown-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    disableElevation
                                                    onClick={handleClick}
                                                    height="100%"
                                                >
                                                    <Typography variant="p" component="p" style={subtitleStyle} >
                                                        <MoreHorizIcon />
                                                    </Typography>
                                                </Button>
                                                <StyledMenu
                                                    id="dropdown-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'dropdown-arrow-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                >
                                                    {userBU.is_active === 0 ?
                                                        <MenuItem onClick={() => handleStateChange(true)} disableRipple>
                                                            <CheckCircleIcon /> Enable User
                                                        </MenuItem>
                                                        :
                                                        <MenuItem onClick={() => handleStateChange(false)} disableRipple>
                                                            <CancelIcon /> Disable User
                                                        </MenuItem>
                                                    }
                                                </StyledMenu>
                                            </Grid>
                                        </Can>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider variant="fullWidth" color={"#B6B6B6"} />
                                    </Grid>
                                    <UserInfo user={user} accountData={accountData} personalInfo={personalInfo} />
                                </Grid>
                            }
                            <Grid item xs={0.5}></Grid>
                        </Grid>
                        <Dialog
                            open={dialogOpen}
                            onClose={() => handleDialogClose(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Are you sure?"}
                            </DialogTitle>
                            <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {dialogText}
                            </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={() => handleDialogClose(true)}>{dialogAcceptBtnText}</Button>
                            <Button onClick={() => handleDialogClose(false)}>No</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                :
                    <CircularProgress />
                }
            </Box>;
}