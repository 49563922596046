import React, {useEffect, useState} from "react";
import { Grid, Button, Typography, Divider } from '@mui/material';
import ProfileImg from "../../../ProfileImg/ProfileImg";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import toolsManagementApi from "../../../../tools/toolsManagementApi";
import useToolsNotifications from "../../../../tools/ToolsNotifications";
import useToken from "../../../App/useToken";

export default function UserCard({userBU, setUserBU}) {
    const { updateUser } = toolsManagementApi();
    const [newImage, setNewImage] = useState(undefined);
    const {error, success} = useToolsNotifications();
    const { token } = useToken();

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
          };
          console.log(fileInfo);
        });
      };

    useEffect(() => {
        if(newImage !== undefined) {
            console.log(newImage)
            getBase64(newImage).then(result => {
                let upd = {
                    "id": userBU["id"],
                    "first_name": userBU["first_name"],
                    "email": userBU["email"],
                    "user_name": userBU["user_name"]+"a",
                    "InformationProviderGLN": userBU["InformationProviderGLN"],
                    "user_telephone": userBU["user_telephone"],
                    "user_image": result,
                    "user_type": userBU["user_type"],
                    "is_active": userBU["is_active"],
                    "last_name": userBU["last_name"],
                    "remember_token": userBU["remember_token"],
                    "created_at": userBU["created_at"],
                    "updated_at": userBU["updated_at"],
                    "is_editable": userBU["is_editable"],
                    "created_by": userBU["created_by"],
                    "created_role": userBU["created_role"],
                    "userLanguage": userBU["userLanguage"],
                    "isDataController": userBU["isDataController"],
                    "passwordUpdatedDate": userBU["passwordUpdatedDate"],
                    "reset_timestamp": userBU["reset_timestamp"],
                    "login_attempts": userBU["login_attempts"],
                    "login_lock_stamp": userBU["login_lock_stamp"],
                    "last_activity_stamp": userBU["last_activity_stamp"],
                    "apiUser": userBU["apiUser"],
                    "platform": userBU["platform"],
                    "CompanyName": userBU["CompanyName"],
                    "user_company": userBU["user_company"],
                    "LanguageName": userBU["LanguageName"],
                    "token": token,
                }
    
                updateUser(upd).then(result => {
                    if(result.code === 1) {
                        success("User updated successfully");
                        setUserBU(upd);
                    } else {
                        error("User update error");
                    }
                });
            })
        }
    }, [newImage])

    const userState = (active) => {
        let component = <Button variant="userDisabledFull" fullWidth sx={{ textTransform: 'none', cursor: 'default' }} disableRipple >Disabled</Button>
        
        if(active === 1) {
            component = <Button variant="userActiveFull" fullWidth sx={{ textTransform: 'none', cursor: 'default' }} disableRipple >Active</Button>
        }

        return component
    }

    const buttonStyle = {
        color: '#666E71',
        borderWidth: '1px',
        borderColor: '#666E71',
    }

    return  <Grid container xs={8} justifyItems="center" item direction="column" rowSpacing={3} style={{paddingTop: "20px"}}>
                <Grid item container justifyContent="center" xs={12}><ProfileImg imgUrl={userBU["user_image"]} size={"200px"} upload={true} cardSize={"210px"} setValue={setNewImage} /></Grid>
                <Grid item container xs={12} rowSpacing={1}>   
                    <Grid item xs={12}><Typography variant="userTitle" fontSize="27px">{userBU["first_name"]} {userBU["last_name"]}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="userSubtitle" fontSize="20px">{userBU["CompanyName"]}</Typography></Grid>
                </Grid>
                <Grid item xs={12}><Divider variant="fullWidth" color={"#B6B6B6"} /></Grid>
                <Grid item container xs={12} rowSpacing={1}>
                    <Grid item xs={12}><Typography variant="userTitle" fontSize="20px">GLN</Typography></Grid>
                    <Grid item xs={12}><Typography variant="userSubtitle" fontSize="20px">{userBU["InformationProviderGLN"]}</Typography></Grid>
                </Grid>
                <Grid item container xs={12} rowSpacing={1}>
                    <Grid item xs={12}><Typography variant="userTitle" fontSize="20px">{userState(userBU["is_active"])}</Typography></Grid>
                    <Grid item xs={12}>
                        {userBU["email"] !== undefined && userBU["email"] !== "" ?
                                <Button
                                    target="_top"
                                    rel="noopener noreferrer"
                                    href={`mailto:` + userBU["email"]}
                                    startIcon={<EmailOutlinedIcon style={{color:"#1EB1E0"}  } />}
                                    variant="emailButton"
                                    fullWidth
                                >
                                    <Typography>
                                        Send Email
                                    </Typography>
                                </Button>
                            :
                            ''
                        }
                    </Grid>
                </Grid>
            </Grid>
}