import React, { useState, useEffect } from "react";
import useFilter from '../App/useFilter';
import { useQuery, } from '@tanstack/react-query'
import useToken from '../App/useToken';
import { Grid, Box, Typography } from '@mui/material';
import DiscontinuedDashPanel from "../DashPanel/DiscontinuedDashPanel";
import AllActiveDashPanel from "../DashPanel/AllActiveDashPanel";
import PublishedDashPanel from "../DashPanel/PublishedDashPanel";
import WithdrawnDashPanel from "../DashPanel/WithdrawnDashPanel";
import toolsDashboardApi from "../../tools/toolsDashboardApi";
import CircularProgress from '@mui/material/CircularProgress';

export default function DashboardProducts(props) {
    const { token, setToken } = useToken();
    const { getProducts } = toolsDashboardApi();
    const [products, setProducts] = useState(undefined)
    const { setCallToken } = useFilter();

    const { data } = useQuery(['dash_prods'], () => getProducts({"token": token}),{
        staleTime: 60 * 1000,
        refetchOnMount: false,
    });

    useEffect(() => {
        if(data !== undefined && data.code === 1 && products === undefined) {
            let dataProcessed = []
            data.response.entire_panel.map((elem) => {
                dataProcessed[elem.status_name] = elem;
            });
            setProducts(dataProcessed);
        }
    });
    
    useEffect(() => {
        setCallToken(token);
    }, [])
    
    if(products !== undefined) {        
        return  <Grid container direction="column">
                    <Typography component="p" variant="productTitle" style={{paddingBottom: "10px"}}>GTINS</Typography>
                    <Grid xs={12} item container direction="row">
                        <Grid xs={6} item>
                            <AllActiveDashPanel panel={products["All Active"]}/>
                        </Grid>
                        <Grid xs={6} item>
                            <PublishedDashPanel panel={products["Published"]}/>
                        </Grid>
                    </Grid>
                    <Grid xs={12} item container direction="row">
                        <Grid xs={6} item>
                            <DiscontinuedDashPanel panel={products["Discontinued"]}/>   
                        </Grid>
                        <Grid xs={6} item>
                            {products["Withdrawn/Deleted"] !== undefined?
                            <WithdrawnDashPanel panel={products["Withdrawn/Deleted"]}/>
                            :''}
                        </Grid>
                    </Grid>
                </Grid>;
    } else {
        return <Grid item container justifyContent="center" alignContent="center" xs={12} sx={{width: '100%', height: '100%'}}><CircularProgress /></Grid>;
    }
    
    
}   