import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, Select, MenuItem } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FileUpload from "../../../FileUpload/FileUpload";
import UploadProfileImg from "../../../UploadProfileImg/UploadProfileImg";
import defaultImg from "../../../../img/defaultProfile.jpg"
import UserPassword from './UserPassword';

export default function UserEdit({user, accountData, personalInfo, setUserPartial}) {   

    let inputStyle = { 
        width: "100%", 
        fontFamily: "Proxima Nova", 
        height: "50.24px",
        "&:root": {
            fontWeight: "100",
            heigth: "1rem",
            fontSize: "1rem",
            lineHeight: "1rem",
        },
    };

    const gridStyle = {
        background: "#FFFFFF",
        borderRadius:"10px", 
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        paddingLeft: "20px",
        minHeight: "600px"
    }
    
    const editData = (key, value, type, options = undefined, onChange = undefined) => {
        let input = undefined
        console.log(value)
        switch(type) {
            case "text":
                input = <TextField 
                            id={key + "-basic"}
                            variant="outlined" 
                            value={value}
                            onChange={(e) => {setUserPartial(key, e.target.value)}}
                            style={inputStyle}
                        />
                break;
            case "img":
                input = <UploadProfileImg value={value} setValue={onChange} defaultImage={defaultImg} />
                break;
            case "select":
                if(options !== undefined) {
                    input = <Select
                                id={key + "-basic"}
                                value={value}
                                onChange={(e) => {onChange !== undefined ? onChange(e.target.value) : setUserPartial(key, e.target.value)}}
                                style={inputStyle}
                            >
                                {options.map((op) => (
                                    <MenuItem value={op.value}>{op.label}</MenuItem>
                                ))}
                            </Select>
                }
                break;
            case "switch":
                input = <Select
                            id={key + "-basic"}
                            value={value !== null && value !== undefined ? value ? 1 : 0 : 0}
                            onChange={(e) => {setUserPartial(key, e.target.value == 1)}}
                            style={{width: '100%'}}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                break;
            case "password":
                input = <UserPassword value={user.user_password} setUserPartial={setUserPartial} keyName="user_password"/>     
                break;

        }

        return input
    }

    const renderColumn = (object, user) => {
        return  Object.keys(object).map((key) => (
                    !object[key]["noEdit"] ?
                        <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center">
                            <Grid item container xs={4} justifyContent="flex-start"><Typography variant="userInfoLabel">{object[key]["label"]}</Typography></Grid>
                            <Grid item xs={8} ><Typography variant="userInfoValue">{editData(key, user[key], object[key]["type"], object[key]["options"], object[key]["onChange"])}</Typography></Grid>
                        </Grid>
                    :
                        ""
                ))
    }

    let iconStyle = {fontSize: "50px"}

    return  <Grid container xs={12} p={3}>
                <Grid item xs={6}>
                    <Grid item xs={12}><Typography variant="userInfoTitle">Account Info</Typography></Grid>
                    <Grid container item xs={12} rowSpacing={1} paddingTop={2}>{ renderColumn(accountData, user) }</Grid>
                </Grid>
                <Grid item xs={6} paddingLeft={3}>
                    <Grid item xs={12}><Typography variant="userInfoTitle">Personal Info</Typography></Grid>
                    <Grid container item xs={12} rowSpacing={1} paddingTop={2}>{ renderColumn(personalInfo, user) }</Grid>
                </Grid>
            </Grid>;
}