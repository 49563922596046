import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

export function GlnDialog({ open, onClose, title, options }) {
	//const { value: valueProp } = props;
  	const [value, setValue] = useState();
  	const radioGroupRef = useRef(null);

	const handleEntering = () => {
		if (radioGroupRef.current != null) {
			radioGroupRef.current.focus();
		}
	};

	const handleCancel = () => {
		onClose();
	};

	const handleOk = () => {
		onClose(value);
	};

	const handleChange = (event) => {
		setValue(event.target.value);
	};
	
	return (<Dialog
		sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
		maxWidth="xs"
		TransitionProps={{ onEntering: handleEntering }}
		open={open}

		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers>
				<RadioGroup
					ref={radioGroupRef}
					aria-label="ringtone"
					name="ringtone"
					value={value}
					onChange={handleChange}
				>
					{options.map((option) => (
				<FormControlLabel
					value={option.value}
					key={option.value}
					control={<Radio />}
					label={option.label}
				/>
				))}
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel}>
				Cancel
				</Button>
				<Button onClick={handleOk}>Submit</Button>
			</DialogActions>
		</Dialog>
		)
	}