export class Company {
  static get modelName() {
    return 'Company'
  }
  constructor(id, gln, active, enrichmentPermissions) {
    this.id = id;
    this.gln = gln;
    this.active = active;
    this.enrichmentPermissions = enrichmentPermissions;
  }
}