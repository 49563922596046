import ToolsApi from './ToolsApi';
import config from "../config/config"

export default function toolsDashboardApi() {
    const { post, get } = ToolsApi();

    const getDashboardProducts = async (body) => {
        let url = config.API_URL + "/api/dashboard";
        let query = undefined
        return post(url, query, body);
    };

    const getDashboardMessages = async (query) => {
        let url = config.API_URL + "/api/message-board";
        
        let body = undefined
        return get(url, query, body);
    };

    const getDashboardWidgets = async (body) => {
        let url = config.API_URL + "/api/dashboard-recent-products";
        let query = undefined
        return post(url, query, body);
    };

    return {
        getProducts: getDashboardProducts,
        getMessages: getDashboardMessages,
        getWidgets: getDashboardWidgets
    };
}