import React, { useState, useEffect } from "react";
import useToken from '../App/useToken';
import { Grid } from '@mui/material';
import useUserManagementFilter from '../App/useUserManagementFilter';
import useCompanyManagementFilter from '../App/useCompanyManagementFilter';
import entities from "./Columns";
import Header from "../Header/Header";
import CompanyUserManagementFilter from "./CompanyUserManagementFilter.js"
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useNavigate } from "react-router-dom";

export default function CompanyUserManagement({defaultService}) {
    const navigate = useNavigate();
    const { filter: userFilter, setCallToken: userSetCallToken, setPageNumber: userSetPageNumber, setAllValue: userSetAllValue, setStatus: userSetStatus  } = useUserManagementFilter();
    const { filter: companyFilter, setCallToken: companySetCallToken, setPageNumber: companySetPageNumber, setAllValue: companySetAllValue, setStatus: companySetStatus } = useCompanyManagementFilter();
    const { token } = useToken();
    const [name, setName] = useState("");
    const [columns, setColumns] = useState([]);
    const [path, setPath] = useState("");
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [localPage, setLocalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState("list");

    const SERVICE_COMPANY = "company";
    const SERVICE_USER = "user";
    const [service, setService] = useState(defaultService)

    const statusCompanyOptions = [
        {
            label: 'Active',
            value: '1',
            key: '1'
        },
        {
            label: 'Disabled',
            value: '3',
            key: '3'
        }
    ]

    const statusUserOptions = [
        {
            label: 'Active',
            value: '1',
            key: '1'
        },
        {
            label: 'Disabled',
            value: '0',
            key: '0'
        }
    ]
    
    useEffect(() => {
        entities.map((elem) => {
            if(elem.service === service) {
                setName(elem.name);
                setColumns(elem.columns);
                setPath(elem.path);
                setRows([]);
                setRowCount(0);
            }
        })
    }, [service])

    useEffect(() => {
        setService(defaultService);
    }, [defaultService])

    useEffect(() => {
        userSetCallToken(token);
        companySetCallToken(token);
    }, [])

    // This set the Page field and throws the Product Filter useEffect
    const onPageChange = ((newPage) => {
        service === SERVICE_COMPANY ? companySetPageNumber(newPage) : userSetPageNumber(newPage);
        setLocalPage(newPage);
        setLoading(true);
    });

    const showCompany = ((params) => {
        let targetUrl = "/editcompany/" + params.row.id;
        navigate(targetUrl);
    })

    const showUser = ((params) => {
        let targetUrl = "/edituser/" + params.row.id;
        navigate(targetUrl);
    })
    

    let bodyWrapperStyle = {width:"100%", height:"80%"};
    let tableStyle = {width:"100%", height:"70%"};
    
    return  <Grid height="100%">
                <Header name={name}/>
                <Grid container direction="column" style={bodyWrapperStyle}>
                    <CompanyUserManagementFilter 
                        fields={columns} 
                        filter={service === SERVICE_COMPANY ? companyFilter : userFilter} 
                        setAllValue={service === SERVICE_COMPANY ? companySetAllValue : userSetAllValue} 
                        setFilterStatus={service === SERVICE_COMPANY ? companySetStatus : userSetStatus}
                        setPageNumber={service === SERVICE_COMPANY ? companySetPageNumber : userSetPageNumber}
                        statusOptions={service === SERVICE_COMPANY ? statusCompanyOptions : statusUserOptions}
                        page={service === SERVICE_COMPANY ? companyFilter.pageNumber : userFilter.pagenumber}
                        setRows={setRows}
                        setRowCount={setRowCount}
                        rowCount={rowCount}
                        path={path}
                        setLocalPage={setLocalPage}
                        setLoading={setLoading}
                        service={service}
                        setService={setService}
                        view={view}
                        setView={setView}
                    />
                    <Grid container style={tableStyle}>
                        {rows.length > 0 
                            ?
                            <div style={{ height: '100%', width: '100%', padding: '25px 25px'}}>
                                <DataGridPro
                                    onRowClick={service === SERVICE_COMPANY ? showCompany : showUser}
                                    pagination
                                    rowCount={rowCount}
                                    paginationMode="server"
                                    onPageChange={onPageChange}
                                    rows={rows}
                                    getRowId={(row) => rows.indexOf(row)}
                                    columns={columns}
                                    page={localPage}
                                    pageSize={service === SERVICE_COMPANY ? companyFilter.pageSize : userFilter.recordlimit}
                                    rowsPerPageOptions={[service === SERVICE_COMPANY ? companyFilter.pageSize : userFilter.recordlimit]}
                                    loading={loading}
                                    disableSelectionOnClick
                                />
                            </div>
                            :
                                ''
                        }
                    </Grid>
                </Grid>
            </Grid>;
}
