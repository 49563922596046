import React, { useRef, useState, useEffect } from "react";
import { Grid, Typography} from "@mui/material";
import ProfileBox from "../ProfileBox/ProfileBox";

export default function Header({name}) {
    const [headerBoxHeight, setHeaderBoxHeight] = useState("0");
    const headerBox= useRef(null);
    
     useEffect(() => {
        setHeaderBoxHeight(headerBox.current.clientHeight+'px');
    }, []); 

    let mainStyle = {
        height : "10%", 
        minHeight : "10%"
    };
    let titleStyle = {
        justifyContent: "left", 
        alignItems:"center", 
        height : "100%", 
        backgroundColor:"#1eb1e0", 
        paddingLeft:"75px"
    };
    let profileStyle = {
        justifyContent: "center", 
        alignItems:"center",
        height : "100%",  
        backgroundColor:"#3284C6"
    };
    return  <Grid container direction="row" style={mainStyle} ref={headerBox}>
                <Grid container item direction="row" style={titleStyle} xs={9}>
                    <Typography variant="h4" component="h4" color="white" fontWeight="700" fontSize="40px">
                        {name}
                    </Typography>
                </Grid>
                <Grid container item style={profileStyle} xs={3} className="profileBox">
                    <ProfileBox headerBoxHeight={headerBoxHeight}/>
                </Grid>
            </Grid>;
}