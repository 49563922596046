import React, { useEffect, useState } from "react";
import { TextField, Switch, Box, Button, Grid, Typography } from '@mui/material';
import ImgProdDefault from "../../../img/brokenLink.png";
import DownloadIcon from '@mui/icons-material/Download';
import config from "../../../config/config";
import useUser from '../../App/useUser';
import toolsEnrichmentWizardApi from "../../../tools/toolsEnrichmentWizardApi";
import useToken from '../../App/useToken';
import { AlertResponse } from "../../Alert/AlertResponse";
import { Can } from "@casl/react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTheme } from '@mui/material/styles';

export default function DAMDetail({data, mainImage, setMainImage, isPreview, productImages, setProductImages, productPermission, userAbility, selectedDam, setSelectedDam, hasPrimary}) {
    const { token } = useToken()
    const { removeAsset, setNewMainImage } = toolsEnrichmentWizardApi()
    const { user, getUser } = useUser();
    const [responseMessage, setResponseMessage] = useState('')
    const [responseSeverity, setResponseSeverity] = useState('')
    const [responseSeverityVariant, setResponseSeverityVariant] = useState('')
    const [open, setOpen] = useState(false) 
    const [action, setAction] = useState('')
    const [newPid, setNewPid] = useState()
    const theme = useTheme();
    const handleA = (e) =>{
        //console.log("AAAA");
    }

    const getComponent = (value, key, onChange, type) => {
        switch(type) {
            case "text":
                return <TextField 
                            disabled
                            id={key + "-basic"}
                            variant="outlined" 
                            style={inputText}
                            value={value}
                            onChange={(e) => {onChange(e.target.value)}}
                        />
            case "switch":
                return 
        }
    }

    const getImgUrl = (url) => {
        let image = url

        if(image != undefined) {
            let imgSrc = image;

            if(imgSrc.indexOf("http") != -1){
                imgSrc = config.mediaPath +'medium?url=' + encodeURIComponent(imgSrc);
            }else{
                imgSrc = config.mediaPath + 'medium?url=' + encodeURIComponent(config.API_URL + config.detailmgPath + imgSrc);
            }

            return imgSrc
        } else {
            return ImgProdDefault
        }
    }

    const setBrokenLink = (ev) => {
        ev.target.src = ImgProdDefault;
    }

    const setNewMain = (nextValue) => {
        if(nextValue && nextValue == true) {
            let newImage = {
                gtin: data.GTIN,
                InformationProviderGLN: data.InformationProviderGLN,
                pID: data.pID,
                TargetMarketCountryCode: data.TargetMarketCountryCode,
                token: token
            }

            setNewMainImage(newImage, token).then(result => {
                if(!result.error) {
                    setNewPid(result.data.pID)
                    setAction('setMainImage')
                    setResponseMessage("Updated correctly " + result.data.image_name)
                    setResponseSeverity("success")
                    setOpen(true)
                } else {
                    setResponseMessage(result.data.data)
                    setResponseSeverity("error")
                    setOpen(true)
                }
            })
        }

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        if(action == 'delete') {
            updateProductImages()
        }
        
        if(action == 'setMainImage' && newPid !== undefined) {
            updateMainImage(newPid)
        }
        
        setOpen(false);
    }

    const updateProductImages = () => {
        let copyOfProductImages = [...productImages]
        copyOfProductImages.map((img, index) => {
            if(img.pID == data.pID) {
                copyOfProductImages.splice(index, 1)
            }
        })

        setProductImages(copyOfProductImages)

        if(productImages !== undefined) {
            if(selectedDam+1 < productImages.length) {
                setSelectedDam(selectedDam+1)
            } else {
                setSelectedDam(selectedDam-1)
            }
        }
    }

    const updateMainImage = (pID) => {
        let copyOfData = {...data}
        data["pID"] = pID
        setMainImage(copyOfData.pID)
    }

    const deleteImage = () => {
        removeAsset(data, token).then(result => {
            if(!result.error) {
                setAction('delete')
                setResponseMessage(result.data)
                setResponseSeverity("success")
                setOpen(true)


            } else {
                setResponseMessage(result.data)
                setResponseSeverity("error")
                setOpen(true)
            }
        })
    }

    const download = e => {
        fetch(e.target.href, {
            method: "GET",
            headers: {}
        }).then(response => {
            response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "image.png"); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        }).catch(err => {
            console.log(err);
        });
    }

    let imgStyle = {maxWidth: "100%"};
    let thumbsContainer = {display: 'inline-grid', gridGap: '5px', overflowX: 'auto', padding: '5px', gridTemplateColumns: 'repeat(5, 5fr)', gridAutoFlow: 'column'}
    let inputText = {fontWeight: "100", width:"100%"};  
    let displayGeneralData = {
        "GTIN" : {"label" : "GTIN", "component" : "text"},
        "media_type" : {"label" : "Asset Type", "component" : "text"},
        "alt_tag" : {"label" : "Asset Name", "component" : "text"},
        "effective_start_date" : {"label" : "Valid From", "component" : "text"},
        "effective_end_date" : {"label" : "Expiration Date", "component" : "text"},
    }

    let displayImageData = {
        "imageBackground" : {"label" : "Image Background", "component" : "text"},
        "source" : {"label" : "Asset Source", "component" : "text"},
        "imageFacing" : {"label" : "Image Facing", "component" : "text"},
        "typeOfInformation" : {"label" : "Type of Information", "component" : "text"},
        "fileVersion" : {"label" : "File Version", "component" : "text"},
        //"image_pID" : {"label" : "Main Image", "component" : "switch", evaluate},
    }

    return  <Grid item container direction="column" height={"100%"} width={"100%"}>
                <Grid item container direction="row" height={"90%"} width={"100%"} justifyContent="space-between">
                    <Grid item container direction="column" height={"100%"} width={"45%"} rowSpacing={1}>
                        {Object.keys(displayGeneralData).map((elem) => (
                            <Grid item container direction="row" alignItems="center">
                                <Grid item width={"50%"} ><Typography variant="PDValue">{displayGeneralData[elem]["label"]}</Typography></Grid>
                                <Grid item width={"50%"} ><Typography variant="PDValue">{getComponent(data[elem], elem, handleA, displayGeneralData[elem]["component"])}</Typography></Grid>
                            </Grid>
                        ))}
                        {Object.keys(displayImageData).map((elem) => (
                            <Grid item container direction="row" alignItems="center">
                                <Grid item width={"50%"} ><Typography variant="PDValue">{displayImageData[elem]["label"]}</Typography></Grid>
                                <Grid item width={"50%"} ><Typography variant="PDValue">{getComponent(data["damInfo"][elem], elem, handleA, displayImageData[elem]["component"])}</Typography></Grid>
                            </Grid>
                        ))}
                        <Grid item container direction="row" alignItems="center">
                            <Grid item width={"50%"} ><Typography variant="PDValue">Is Primary</Typography></Grid>
                            <Grid item width={"50%"} pt="8px" pl="25px"><Typography variant="PDValue">{data["damInfo"]["is_primary"] === true? <CheckCircleOutlineIcon style={{color:theme.palette.positive.main}}/> : <RemoveCircleOutlineIcon/>}</Typography></Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center">
                            <Grid item width={"50%"} ><Typography variant="PDValue"> Main Image</Typography></Grid>
                            <Grid item width={"50%"} >
                                <Can I="update" this={productPermission} ability={userAbility}>
                                    {!isPreview ?
                                        <Switch style={{color:"primary"}} defaultChecked={data["pID"] == mainImage} onChange={(e) => {setNewMain(e.target.checked)}} disabled={hasPrimary}/>
                                    :
                                        ''
                                    }
                                </Can>
                                <Can I="view" this={productPermission} ability={userAbility}>
                                    {!isPreview ?
                                        <Switch style={{color:"primary"}} defaultChecked={data["pID"] == mainImage} onChange={(e) => {setNewMain(e.target.checked)}} disabled/>
                                    :
                                        ''
                                    }
                                </Can>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" minHeight={"100%"} width={"50%"} justifyContent="center" alignItems="center"
                    style={{background: 'linear-gradient(0deg, rgba(50, 132, 198, 0.5) 0%, rgba(255, 255, 255, 0.05) 50%)', padding:'0 25px 25px 25px', borderRadius: '10px'}}>
                        <Box minHeight="500px" alignItems="center" display="flex" >
                            <Box component="img" onError={setBrokenLink} style={imgStyle} src={getImgUrl(data["image_gtin"])} borderRadius="10px"></Box>
                        </Box>
                        <Grid item container justifyContent="center" alignItems="center" style={thumbsContainer}> 
                            {productImages.map((value, index) => (
                                <Box component="img" onError={setBrokenLink} src={getImgUrl(value["image_gtin"])} maxWidth="70px" maxHeight="70px" border={data["image_gtin"] === value["image_gtin"]?'3px solid #1EB1E0':'3px solid #ffffff'} borderRadius="10px" onClick={()=>setSelectedDam(index)}></Box>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction="row" height={"90%"} width={"100%"} justifyContent="space-between" p={1}>
                    <Grid item container direction="column" height={"100%"} width={"45%"} rowSpacing={1}>
                        <Grid item container direction="row" justifyContent="flex-end" columnSpacing={1}>
                            <Grid item></Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" minHeight={"100%"} width={"50%"} justifyContent="flex-end" alignItems="center" paddingBottom="30px">
                        <Grid item container direction="row" alignItems="flex-end" justifyContent="flex-end" columnSpacing={1}>
                            <Grid item>
                                <Can I="update" this={productPermission} ability={userAbility}>
                                    {!isPreview ?
                                        <Button variant="orange-secondary" size="small" onClick={deleteImage}>Delete Asset</Button>
                                    :
                                        ''
                                    }
                                </Can>
                            </Grid>
                            <Grid item>
                                {!isPreview ?
                                    <Button variant="inverted-main" size="small" component="a" href={getImgUrl(data["image_gtin"]) + "?force=true"} target="_blank" download onClick={e => download(e)}> <DownloadIcon/>Download Asset</Button>
                                :
                                    ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <AlertResponse
                    open={open}
                    autoHideDuration="1000"
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    onCloseSnackbar={handleClose}
                    onCloseAlert={handleClose} 
                    severity={responseSeverity}
                    message={responseMessage}
                ></AlertResponse>
            </Grid>
            ;        
}