import React from "react";
import { Grid, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function UserInfo({user, accountData, personalInfo}) {

    let iconStyle = {fontSize: "50px"}

    const gridStyle = {
        background: "#FFFFFF",
        borderRadius:"10px", 
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        paddingLeft: "20px",
        minHeight: "370px",
    }

    const renderColumn = (object, user) => {
        return Object.keys(object).map((key) => (
                    object[key]["hide"] === undefined ||  object[key]["hide"] === false ?
                        <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center">
                            <Grid item container xs={6} justifyContent="flex-start"><Typography variant="userInfoLabel" textTransform="capitalize">{object[key]["textTransform"] === false?object[key]["label"]:object[key]["label"].toLowerCase()}</Typography></Grid>
                            <Grid item xs={6} ><Typography variant="userInfoValue">{object[key]["display"] !== undefined ? object[key]["display"](user[key]) : user[key]}</Typography></Grid>
                        </Grid>
                    :
                        ''
                ))
    }

    return  <Grid container item xs={12} direction="row" justifyContent="space-around" alignItems="flex-start" spacing={2} sx={{paddingTop: "15px !important"}}>
                <Grid item container xs={12} sm={12} md={12} lg={5} xl={5} direction="column" justifyContent="flex-start" alignItems="flex-start" rowSpacing={1}>
                    <Grid item container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item xs={11} ><Typography variant="userInfoTitle">Account Info</Typography></Grid>                                     
                    </Grid>
                    { renderColumn(accountData, user) }
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={5} xl={5} direction="column" justifyContent="flex-start" alignItems="flex-start" rowSpacing={1}>
                    <Grid item container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item xs={11} ><Typography variant="userInfoTitle">Personal Info</Typography></Grid>   
                    </Grid>
                    { renderColumn(personalInfo, user) }
                </Grid>
            </Grid>                    
}