import React, { useEffect, useState } from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, Grid } from '@mui/material';
import ImgProdDefault from "../../img/defaultProduct.png";
import config from "../../config/config";
import Checkbox from "../Checkbox/Checkbox";
import { useSelector, useDispatch } from 'react-redux';
import columnOrderByTypes from "./columnOrderByTypes";
import { setProductBrowserLocalPage } from '../../features/productBrowserSlice';

export default function ProductTable({altColumns, page, rows , totalRows, onPageChange, handleOnRowClick, loading, setSelectedRows, selectOption, rowId, setOrderBy, setLoading, setPage}) {
    const columns = [
        {
            field: 'GUID',
            headerName: 'GUID',
            minWidth: 170,
            align: 'left',
            hide: true,
        },
        { 
            field: 'image', 
            headerName: 'Image', 
            minWidth: 100, 
            align: 'center',
            padding: 'none',
            renderCell: (params) => loadProductImg(params.row),
            sortable: false
        },
        {
            field: 'publishedGTIN',
            headerName: 'Published GTIN',
            minWidth: 150,
            align: 'left',
        },
        {
            field: 'GTIN',
            headerName: 'GTIN',
            minWidth: 130,
            align: 'left',
        },
        {
            field: 'gtinName',
            headerName: 'Product Name',
            minWidth: 250,
            align: 'left',
            renderCell: (params) => loadProductName(params.row)
        },
        {
            field: 'Variant',
            headerName: 'Variant',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'netContent',
            headerName: 'Net Content',
            minWidth: 170,
            align: 'left',
            valueGetter: (params) => `${params.row.netContent || ''} ${params.row.netContentUom || ''}`,
            sortable: false
        },
        {
            field: 'languageSpecificBrandName',
            headerName: 'Brand',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'alternateItemIdentificationId',
            headerName: 'SKU',
            minWidth: 170,
            align: 'left',
            sortable: false
        },
        {
            field: 'InformationProviderGLN',
            headerName: 'IP GLN',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'CompanyName',
            headerName: 'IP Name',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'lastSyncDate',
            headerName: 'Last Sync',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'TargetMarketCountryCode',
            headerName: 'Target Market',
            minWidth: 50,
            align: 'left',
            hide: true,
        },
        {
            field: 'productType',
            headerName: 'Product Type',
            minWidth: 50,
            align: 'left',
            hide: true,
        },
        {
            field: 'total_images',
            headerName: 'Nr of Images',
            minWidth: 50,
            align: 'left',
            hide: true,
        },
    ];

    const dispatch = useDispatch();
    
    const setBrokenLink = (ev) => {
        ev.target.src = ImgProdDefault;
    }

    const loadProductImg = ((row)=> {
        let colorStatus = '';
        switch (row.status_id) {
            case 4:
                colorStatus = '6px solid #002c6c';
            break;
            case 3:
                colorStatus = '6px solid rgb(83, 83, 83)';
            break;
            case 2:
                colorStatus = '6px solid #E16C43';
            break;    
            default:
                colorStatus = '';
            break;
        }

       
        let imgStyle = {maxWidth: "100%", borderRadius: '4px', maxHeight: '100%'};
        let imgContainer = {width: '50px', padding: '2px', borderRadius: '4px', height: '50px'}
        if(row.product_image_name != undefined) {
            let imgSrc = row.product_image_name;

            if(imgSrc.indexOf("http") != -1) {
                imgSrc = config.mediaPath + 'thumbnail?url=' + encodeURIComponent(imgSrc);
            } else {
                imgSrc = config.mediaPath + 'thumbnail?url=' + encodeURIComponent(config.API_URL + config.detailmgPath + imgSrc);
            }
            return  <Grid container alignItems="center" justifyContent="center" direction="row" border={colorStatus} style={imgContainer}>
                        <Box component="img" style={imgStyle} src={imgSrc} onError={setBrokenLink}></Box>
                    </Grid>
        } else {
            return  <Grid container alignItems="center" justifyContent="center" direction="row" border={colorStatus} style={imgContainer}>
                        <Box component="img" style={imgStyle} src={ImgProdDefault}></Box>
                    </Grid>
        }
    })

    const loadProductName = ((row) => {
        return <Box class="MuiDataGrid-cellContent" style={{color: "#3078B0", fontWeight: "700"}}>{row.gtinName}</Box>;
    });

    const onRowsSelectionHandler = ((ids) => {
        setSelectedRows(ids)
    });

    const handleSearch =  (() => {
        setLoading(true);
        setPage("0");
        dispatch(setProductBrowserLocalPage(0));
        setColumnSort(false)
    });

    const [columnSort, setColumnSort] = useState(false);
    
    const handleSortModelChange = (sortModel) => {
        let orderByRule = '';
        
        if(sortModel.length > 0) {
            sortModel.map((value, index) => {
                orderByRule = altColumns === undefined?columnOrderByTypes[value.field]+'/'+value.sort:columnOrderByTypes[value.field+'Alt']+'/'+value.sort   
            });
        
            setOrderBy(orderByRule);
            setColumnSort(true);
        }
    };

    useEffect(() => {
        if(columnSort) {
            handleSearch()    
        }
        
    },[columnSort]);

    return (
        <div style={{ height: '100%', width: '100%'}}>
          <DataGridPro
            onRowClick={handleOnRowClick}
            loading={loading}
            pagination
            rowCount={totalRows}
            paginationMode="server"
            onPageChange={onPageChange}
            rows={rows}
            columns={altColumns === undefined ? columns : altColumns}
            page={page}
            getRowId={rowId}
            pageSize={25}
            rowsPerPageOptions={[25]}
            checkboxSelection={selectOption === false ? false : true}
            disableSelectionOnClick
            onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
            components={{
              //Use BaseCheckbox for added a header title to Select All
              BaseCheckbox: Checkbox
            }}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
          />
        </div>
    );
}