import { createSlice } from '@reduxjs/toolkit'


export const productBrowserSlice = createSlice({
  name: 'productBrowser',
  initialState: {
    productBrowserResults: [],
    productBrowserCountResults: 0,
    productBrowserLocalPage: 0,
    productBrowserFilter: {
        count: "false",
        pageNumber: "",
        pageSize: 25,
        search: {
            GTIN: undefined,
            brand: [],
            category: [],
            description: undefined,
            images: undefined,
            name: undefined,
            productType: "EA",
            providerGLN: null,
            status: [],
            sub_category: [],
            sku: undefined,
            OrderBy: "ts_tm_item.lastSyncDate/desc",
            wayLabel: undefined
        },
        type: "BROWSER",
        token: "",
    },
    productBrowserFilterCount: {
        count: "true",
        search: {
            GTIN: undefined,
            brand: [],
            category: [],
            description: undefined,
            images: undefined,
            name: undefined,
            productType: "EA",
            providerGLN: null,
            status: [],
            sub_category: [],
            sku: undefined,
            OrderBy: "ts_tm_item.lastSyncDate/desc",
            wayLabel: undefined
        },
        type: "BROWSER",
        token: "",
    }
  },
  reducers: {
    setProductBrowserResults: (state, productBrowserResults) => {
      state.productBrowserResults = productBrowserResults.payload
    },
    setProductBrowserCountResults: (state, productBrowserCountResults) => {
      state.productBrowserCountResults = productBrowserCountResults.payload
    },
    setProductBrowserLocalPage: (state, productBrowserLocalPage) => {
      state.productBrowserLocalPage = productBrowserLocalPage.payload
    },
    setProductBrowserFilter: (state, productBrowserFilter) => {
      state.productBrowserFilter = productBrowserFilter.payload
    },
    setProductBrowserFilterCount: (state, productBrowserFilterCount) => {
      state.productBrowserFilterCount = productBrowserFilterCount.payload
    },
  },
})

export const { setProductBrowserResults } = productBrowserSlice.actions
export const { setProductBrowserCountResults } = productBrowserSlice.actions
export const { setProductBrowserLocalPage } = productBrowserSlice.actions
export const { setProductBrowserFilter } = productBrowserSlice.actions
export const { setProductBrowserFilterCount } = productBrowserSlice.actions

export default productBrowserSlice.reducer