import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button, ListItemText, ToggleButtonGroup, ToggleButton, Select, MenuItem, FormControl} from '@mui/material';
import toolsManagementApi from "../../tools/toolsManagementApi";
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { BrowserRouter, useNavigate  } from 'react-router-dom';
import Multiselect from "../Multiselect/Multiselect";

export default function ReportsFilters({statusOptions, view, setView, rowCount, setService, service, fields, filter, setAllValue, setFilterStatus, setPageNumber, page, setRows, setRowCount, path, setLocalPage, setLoading}) {
    const { searchManagement, searchManagementCount} = toolsManagementApi();
    const [searchAny, setSearchAny] = useState("");
    const [status, setStatus] = useState([]);
    const navigate = useNavigate();

    let addPath = {"company" : "/company/new", "user" : "/user/new"};

    useEffect(() => {
        console.log(status)
        setFilterStatus(status)
    }, [status])

    useEffect(() => {
        onChangeSearchAny("");
        setStatus([]);
    }, [fields, service])

    useEffect(() => {
        if(page !== undefined) {
            searchManagement(filter, path).then(result => {
                setLoading(false);
                if(result.code == 1) {
                    if(result.response != undefined && Array.isArray(result.response)) {
                        setRows(result.response)
                    }
                }
            });
            if(page === 0){
                searchManagementCount(filter, path).then(result => {
                    if(result.code == 1) {
                        if(result.response != undefined) {
                            setRowCount(result.response)
                        }
                    }
                });
            }
            setPageNumber(undefined);
        }
    }, [page])

    const onChangeSearchAny = ((value) => {
        setAllValue(value);
        setSearchAny(value);
    });

    // HANDLERS    
    const handleViewChange = (event, nextView) => {
        if (nextView !== null) {
            setView(nextView);
        }
    };

    const search = (() => {
        setLoading(true);
        setPageNumber(0);
        setLocalPage(0);
    });

    const addNew = (() => {
        if(service !== undefined) {
            navigate(addPath[service])
        }
    })

    // STYLES
    let filtersStyle = {height:"15%", padding: "25px", fontFamily: "Proxima Nova"};
    let gridCellStyle = {padding:"5px 5px", height: "50%"};
    let inputText = {fontWeight: "100", width:"100%"};    
    let inputStyle = { width: "100%" };
    let toggleStyle = {textTransform: "none", selected:{background: "primary"}};
    let selectStyle = { 
        width: "100%", 
        fontFamily: "Proxima Nova", 
        height: "50.24px",
        "&:root": {
            fontWeight: "100",
            heigth: "1rem",
            fontSize: "1rem",
            lineHeight: "1rem",
        },
    };

    

    return  <Grid container direction="row" style={filtersStyle} >
                <Grid container direction="row" xs={9} spacing={3}>
                    <Grid item container direction="row" xs={4} spacing={3} alignItems="center">
                        <Grid item xs={6}>
                            <Select
                                fullWidth
                                labelId="service-select-label"
                                id="service-sort-select"
                                value={service}
                                onChange={(event) => setService(event.target.value)}
                            >
                                <MenuItem value={"company"}>Company</MenuItem>
                                <MenuItem value={"user"}>User</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item justify="flex-end"  xs={2}>
                            <Typography variant="important">{rowCount}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item  container direction="row" xs={4} spacing={3} alignItems="center" justifyItems={"flex-start"}>
                        <Grid item xs={10}>
                            <TextField 
                                label="Search by any column"
                                id={"all-basic"}
                                variant="outlined" 
                                style={inputText}
                                value={searchAny}
                                onChange={(e) => {onChangeSearchAny(e.target.value)}}
                            />
                        </Grid>
                    </Grid>
                    <Grid item  container direction="row" xs={4} spacing={3} alignItems="center" justifyItems={"flex-start"}>
                        <Grid item xs={10}>
                            <Multiselect 
                                style={selectStyle} 
                                renderLabel="All status" 
                                labelRenderized={true}
                                options={statusOptions} 
                                value={status} 
                                setValue={setStatus} 
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="row" xs={3} spacing={3} alignItems="center" justifyContent="flex-end">
                    <Grid item xs={6}>
                        <Button variant="orange" style={inputText} onClick={search}>Search</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="orange-secondary" style={inputText} onClick={addNew}>Add {service}</Button>
                    </Grid>
                    {/* <Grid item xs={4}>
                        <ToggleButtonGroup
                            width={"100%"}
                            value={view}
                            orientation="horizontal"
                            exclusive
                            onChange={handleViewChange}
                        >
                            <ToggleButton style={toggleStyle} value="module" aria-label="module">
                                <ViewModuleIcon disabled/> Grid
                            </ToggleButton>
                            <ToggleButton style={toggleStyle} value="list" aria-label="list">
                                <ViewListIcon disabled/> List
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid> */}
                </Grid>
            </Grid>
}