import React, { useState } from "react";
import { useQuery, } from '@tanstack/react-query'
import useToken from '../App/useToken';
import { DataGridPro } from '@mui/x-data-grid-pro';
import toolsDashboardApi from "../../tools/toolsDashboardApi";
import { Box, Typography, Grid } from '@mui/material';
import ImgProdDefault from "../../img/defaultProduct.png";
import config from "../../config/config"
import CircularProgress from '@mui/material/CircularProgress';

export default function DashboardWidgets(props) {
    const { token, setToken } = useToken();
    const { getWidgets} = toolsDashboardApi();
    const [widgetsData, setWidgetsData] = useState([]);

    const { data } = useQuery([], () => getWidgets({
        "token": token,
        "count": "false",
        "pageNumber": "0",
        "pageSize": 25,
        "searchTerms":  {
            OrderBy: "lastSyncDate/desc",
            QueryType: "and"
        }
    }).then(result => {
        if( result != undefined && !result.error && typeof result.response === 'object') {
            setWidgetsData(result.response);
        }
    }));

    const columns = [
        { 
            field: 'product_image_name', 
            headerName: 'Image', 
            minWidth: 50, 
            renderCell: (params) => loadProductImg(params.row)
        },
        {
            field: 'GTIN',
            headerName: 'GTIN',
            minWidth: 170,
            align: 'left'
        },
        {
            field: 'gtinName',
            headerName: 'Product Description',
            minWidth: 450,
            align: 'left'
        },
        {
            field: 'TargetMarketCountryCode',
            headerName: 'TM',
            minWidth: 80,
            align: 'left'
        },
        {
            field: 'lastSyncDateFilter',
            headerName: 'lastSyncDate',
            minWidth: 170,
            align: 'left'
        },
        {
            field: 'InformationProviderGLN',
            headerName: 'IP GLN',
            minWidth: 150,
            align: 'left'
        },
        {
            field: 'dataRecipientGLN',
            headerName: 'DR GLN',
            minWidth: 150,
            align: 'left'
        }
    ];

    const setBrokenLink = (ev) => {
        ev.target.src = ImgProdDefault;
    }

    const loadProductImg = ((row)=>{
        let imgStyle = {width: "50%",};
        if(row.product_image_name != undefined) {
            let imgSrc = row.product_image_name;

            if(imgSrc.indexOf("http") != -1) {
                imgSrc = config.mediaPath +'thumbnail?url=' + encodeURIComponent(imgSrc);
            } else {
                imgSrc = config.mediaPath + 'medium?url=' + encodeURIComponent(config.API_URL + config.detailmgPath + imgSrc);
            }
            return <Box component="img" style={imgStyle} src={imgSrc} onError={setBrokenLink}></Box>
        } else {
            return <Box component="img" style={imgStyle} src={ImgProdDefault}></Box>
        }
    })

    if(widgetsData.length > 0) {
        return  <Box style={{ height: '100%', width: '100%' }}>
                    <Typography component="p" variant="productTitle" style={{paddingBottom: "10px"}}>Recent Updates</Typography>
                    <DataGridPro
                        rows={widgetsData}
                        columns={columns}
                        page={0}
                        getRowId={(r) => r.dataRecipientGLN + '-' + r.GTIN + '-' + r.InformationProviderGLN + '-' + r.TargetMarketCountryCode}
                        pageSize={25}
                        rowsPerPageOptions={[25]}
                        density='standart'
                    />
                </Box>;
    } else {
        return <Grid item container justifyContent="center" alignContent="center" xs={12} sx={{width: '100%', height: '100%'}}><CircularProgress /></Grid>;
    }
}