import React, { useEffect, useState } from "react";
import { TextField, Switch, Box, Button, Grid, Typography } from '@mui/material';
import ImgProdDefault from "../../../img/brokenLink.png";
import DownloadIcon from '@mui/icons-material/Download';
import config from "../../../config/config";
import toolsEnrichmentWizardApi from "../../../tools/toolsEnrichmentWizardApi";
import useToken from '../../App/useToken';
import { AlertResponse } from "../../Alert/AlertResponse";
import { Can } from "@casl/react";

export default function PublicDAMDetail({data, mainImage, setMainImage, isPreview, productImages, setProductImages, productPermission, userAbility, selectedDam, setSelectedDam}) {
    const handleA = (e) =>{
        //console.log("AAAA");
    }

    const getComponent = (value, key, onChange, type) => {
        switch(type) {
            case "text":
                return <TextField 
                            disabled
                            id={key + "-basic"}
                            variant="outlined" 
                            style={inputText}
                            value={value}
                            onChange={(e) => {onChange(e.target.value)}}
                        />
            case "switch":
                return 
        }
    }

    const getImgUrl = (url) => {
        let image = url

        if(image != undefined) {
            let imgSrc = image;

            if(imgSrc.indexOf("http") != -1){
                imgSrc = config.mediaPath +'medium?url=' + encodeURIComponent(imgSrc);
            }else{
                imgSrc = config.mediaPath + 'medium?url=' + encodeURIComponent(config.API_URL + config.detailmgPath + imgSrc);
            }

            return imgSrc
        } else {
            return ImgProdDefault
        }
    }

    const setBrokenLink = (ev) => {
        ev.target.src = ImgProdDefault;
    }

    let imgStyle = {maxWidth: "100%"};
    let thumbsContainer = {display: 'inline-grid', gridGap: '5px', overflowX: 'auto', padding: '5px', gridTemplateColumns: 'repeat(5, 5fr)', gridAutoFlow: 'column'}
    let inputText = {fontWeight: "100", width:"100%"};  
    let displayGeneralData = {
        "GTIN" : {"label" : "GTIN", "component" : "text"},
        "media_type" : {"label" : "Asset Type", "component" : "text"},
        "alt_tag" : {"label" : "Asset Name", "component" : "text"},
        "effective_start_date" : {"label" : "Valid From", "component" : "text"},
        "effective_end_date" : {"label" : "Expiration Date", "component" : "text"},
    }

    let displayImageData = {
        "imageBackground" : {"label" : "Image Background", "component" : "text"},
        "source" : {"label" : "Asset Source", "component" : "text"},
        "imageFacing" : {"label" : "Image Facing", "component" : "text"},
        "typeOfInformation" : {"label" : "Type of Information", "component" : "text"},
        "fileVersion" : {"label" : "File Version", "component" : "text"},
        //"image_pID" : {"label" : "Main Image", "component" : "switch", evaluate},
    }
    
    return  <Grid item container >
                <Grid item container direction="row" justifyContent="space-between">
                    <Grid item container xs={12} md={6} justifyContent="center" alignItems="center" marginBottom="30px" style={{background: 'linear-gradient(0deg, rgba(50, 132, 198, 0.5) 0%, rgba(255, 255, 255, 0.05) 50%)', padding:'25px', borderRadius: '10px'}}>
                        <Box alignItems="center" display="flex" >
                            <Box component="img" onError={setBrokenLink} style={imgStyle} src={getImgUrl(data["image_gtin"])} borderRadius="10px"></Box>
                        </Box>
                        <Grid item container justifyContent="center" alignItems="center" style={thumbsContainer}> 
                            {productImages.map((value, index) => (
                                <Box component="img" onError={setBrokenLink} src={getImgUrl(value["image_gtin"])} maxWidth="70px" maxHeight="70px" border={data["image_gtin"] === value["image_gtin"]?'3px solid #1EB1E0':'3px solid #ffffff'} borderRadius="10px" onClick={()=>setSelectedDam(index)}></Box>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6} rowSpacing={1}>
                        {Object.keys(displayGeneralData).map((elem) => (
                            <Grid item container direction="row" alignItems="center">
                                <Grid item width={"50%"} ><Typography variant="PDValue">{displayGeneralData[elem]["label"]}</Typography></Grid>
                                <Grid item width={"50%"} ><Typography variant="PDValue">{getComponent(data[elem], elem, handleA, displayGeneralData[elem]["component"])}</Typography></Grid>
                            </Grid>
                        ))}
                        {Object.keys(displayImageData).map((elem) => (
                            <Grid item container direction="row" alignItems="center">
                                <Grid item width={"50%"} ><Typography variant="PDValue">{displayImageData[elem]["label"]}</Typography></Grid>
                                <Grid item width={"50%"} ><Typography variant="PDValue">{getComponent(data["damInfo"][elem], elem, handleA, displayImageData[elem]["component"])}</Typography></Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            ;        
}