import React, { useEffect, useState } from "react";
import { Grid, Typography } from '@mui/material';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import BatchPredictionOutlinedIcon from '@mui/icons-material/BatchPredictionOutlined';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import productDataDisplayName from './helpers/DisplayNames';
import propertiesDefinition from './helpers/PropertiesDefinition';
import RenderHelper from './helpers/RenderHelper';
import ProductProperty from "./ProductProperty";

export default function BasicData({data, definition, messageId}) {
    const [extendedJson, setExtendedJson] = useState(undefined)
    const [bannedFields, setBannedFields] = useState(undefined)
    const {renderBlock} = RenderHelper();
    // revisar item parsed
    const addedFields = [
            "ENRICHMENTINFO",
            "ALLERGENS",
            "ENRICHMENTS",
            "FLEX",
            "ITEMPARSED",
            "HIERARCHY",
            "IMAGES",
            "HEALTHCARE",
            "GLOBALCLASSIFICATIONCATEGORY",
            "ENRICHMENTS",
            "1WS",
            "TRUSTEDSOURCE",
            "ECOMMERCE",
            "HIERARCHY",
            "ALLERGENS",
            "NUTRITIONAL",
            "HEALTHCARE",
            "ISCONSUMERUNIT",
            "ISDISPATCHUNIT",
            "ISINVOICEUNIT",
            "TARGETMARKET",
            "IMAGES",
            "TOTAL",
            "COMPLETED",
            "IMAGE_ID",
            "PID",
            "BRANDNAME",
            "ISTRADEITEMDISPATCHUNIT",
            "ISTRADEITEMACONSUMERUNIT",
            "ISTRADEITEMAINVOICEUNIT",
            "ISTRADEITEMANORDERABLEUNIT",
            "PACKAGINGMARKEDRETURNABLE",
            "HASBATCHNUMBER",
            "ISVARIABLEWEIGHTITEM",
            "PRODUCTMARKEDWITHINGREDIENTS",
            "ISORDERABLEUNIT",
            "VAT_EXEMPT",
            "DANGEROUSGOODSHAZARDOUSCODE",
            "DANGEROUSGOODSREGULATIONCODE",
            "HANDLINGINSTRUCTIONSCODEREFERENCE",
        ];

    useEffect(()=>{
        let itemJson = data; 

        if(itemJson !== undefined) {
            let itemJsonExtend = [];
            for(var i in itemJson.flex) {
                let name = Object.entries(itemJson.flex[i])[0][0];
                itemJsonExtend[name] = itemJson.flex[i][name];
            }
            itemJsonExtend = {...itemJson, ...itemJsonExtend};

            // Add fields exclusive from Product
            itemJsonExtend.creationDate = data.creationDate;
            itemJsonExtend.lastSyncDate = data.lastSyncDate;
            setExtendedJson(itemJsonExtend);

            if(definition !== undefined) {
                let banned = addedFields;
                
                Object.keys(definition).map(key => {
                    Object.keys(definition[key]).map(value => {
                        banned.push(definition[key][value].fieldName.toUpperCase())
                    })
                })
                setBannedFields(banned);
            }
        }

    }, [data])
    
    const renderOtherObject = (obj, displayName) => {
        let data = undefined
        if(displayName === '' || bannedFields.indexOf(displayName.toUpperCase()) === -1){
            let keys = Object.keys(obj);
            data = keys.map(elem => {
                if(typeof(obj[elem]) === "object") {
                    return renderOtherObject(obj[elem], elem)
                } else {
                    if(bannedFields.indexOf(elem.toUpperCase()) === -1){
                        return  <Grid item container spacing={12} direction="row">
                                    <Grid item xs={6}>
                                        <Typography variant="PDLabel">
                                            {productDataDisplayName[displayName] !== undefined || productDataDisplayName[elem] !== undefined ? 
                                                !isNaN(elem) ? productDataDisplayName[displayName] : productDataDisplayName[elem]:
                                                typeof elem === "string" && isNaN(Number(elem))?elem.split(/(?=[A-Z])/).join(' '):displayName.split(/(?=[A-Z])/).join(' ')
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}><Typography variant="PDValue">{obj[elem] !== undefined? renderOtherObjectValue(obj[elem]):''}</Typography></Grid>
                                </Grid>
                    } else {
                        return '';
                    }
                }
            })
        }

        return data;
    }


    const renderProductData = () => {
        var prodDefinition = [...Object.entries(definition.product)];
        prodDefinition.sort((a,b) => a.id - b.id);

        let result = renderBlock(prodDefinition,<LightbulbOutlinedIcon color="primary" />, "Product", extendedJson, data);
        return result
    };
    
    const renderPropertiesData = () => {
        return  <Grid item container direction="column" rowSpacing={2}>
                    <Grid item container direction="row">
                        <Typography variant="PDIcon"><BatchPredictionOutlinedIcon color="primary" /></Typography><Typography variant="PDTitle">Properties</Typography>
                    </Grid>
                    <Grid item container direction="row" columnSpacing={12} style={valuesStyle} justifyContent="space-between">
                        {propertiesDefinition.map(elem => (
                            <ProductProperty label={elem.displayName} value={data[elem.key] !== undefined && (data[elem.key] === true || data[elem.key] === 'true')} />
                        ))}
                    </Grid>
                </Grid>
    };

    const renderMeasurementData = () => {
        let result = '';
        if(definition.measurements !== undefined) {
            var prodDefinition = [...Object.entries(definition.measurements)];
            prodDefinition.sort((a,b) => a.id - b.id);

            result = renderBlock(prodDefinition,<CropFreeOutlinedIcon color="primary" />, "Measurement", extendedJson, data);
        }
        return result
    };

    const renderDatesData = () => {
        let result = '';
        if(definition.dates !== undefined) {
            var prodDefinition = [...Object.entries(definition.dates)];
            prodDefinition.sort((a,b) => a.id - b.id);

            result = renderBlock(prodDefinition,<CalendarTodayOutlinedIcon color="primary" />, "Dates", extendedJson, data);
        }
        return result
    };

    const renderTaxData = () => {
        let result = '';
        if(definition.tax !== undefined) {
            var prodDefinition = [...Object.entries(definition.tax)];
            prodDefinition.sort((a,b) => a.id - b.id);

            result = renderBlock(prodDefinition,<RequestPageOutlinedIcon color="primary" />, "Tax", extendedJson, data);
        }
        return result
    };

    const renderPackagingData = () => {
        let result = '';
        if(definition.packaging !== undefined) {
            var prodDefinition = [...Object.entries(definition.packaging)];
            prodDefinition.sort((a,b) => a.id - b.id);

            result = renderBlock(prodDefinition,<Inventory2OutlinedIcon color="primary" />, "Packaging", extendedJson, data);
        }
        return result
    };

    const renderLogisticData = () => {
        let result = '';
        if(definition.logistics !== undefined) {
            var prodDefinition = [...Object.entries(definition.logistics)];
            prodDefinition.sort((a,b) => a.id - b.id);
            result = renderBlock(prodDefinition,<LocalShippingOutlinedIcon color="primary" />, "Logistic", extendedJson, data);
        }
        return result
    };

    const renderOtherData = () => {
        if(bannedFields !== undefined || Array.isArray(bannedFields)){
            return  <Grid item container direction="column" rowSpacing={2}>
                        <Grid item container direction="row">
                            <Typography variant="PDIcon"><TagOutlinedIcon color="primary" /></Typography><Typography variant="PDTitle">Other</Typography>
                        </Grid>
                        <Grid item container direction="column" rowSpacing={1} style={valuesStyle}>
                                {renderOtherObject(extendedJson, '')}
                                {messageId !== '' && messageId !== null?
                                <Grid item container spacing={12} direction="row">
                                    <Grid item xs={6}>
                                        <Typography variant="PDLabel">Message ID</Typography>
                                    </Grid>
                                    <Grid item xs={6}><Typography variant="PDValue">{messageId}</Typography></Grid>
                                </Grid>
                                :''}
                        </Grid>
                    </Grid>
        } else {
            return '';
        }
    };

    const renderOtherObjectValue = (value) => {
        if(typeof value === 'boolean') {
            return value?'True':'False'
        }

        return value
    };

    let valuesStyle = {paddingLeft: "25px"}

    return  <Grid container direction="column" rowSpacing={4}>
                {renderProductData()}
                {renderPropertiesData()}
                {renderMeasurementData()}
                {renderDatesData()}
                {renderTaxData()}
                {/* renderPackagingData() */}
                {renderLogisticData()}
                {renderOtherData()}
            </Grid>;
}