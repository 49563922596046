import React, { useEffect, useState } from "react";
import { Tabs, Tab, Grid, Typography } from '@mui/material';
import SourceSelector from "./Enrichments/SourceSelector";

export default function EnrichmentWrapper({view, data}) {
    const [source, setSource] = useState("1ws");
    const [show, setShow] = useState(undefined);

    useEffect(() => {
        setShow(React.cloneElement(view, { data: data[source]}))
    }, [view, data, source]);

    let gridStyle = { paddingLeft: "25px" }

    return  <Grid container direction="column" rowSpacing={5}>
                <SourceSelector value={source} setValue={setSource} />
                <Grid item container style={gridStyle}>
                    {data[source] !== undefined && Object.keys(data[source]).length > 0 ? 
                        show
                    :
                        <Typography variant="productSubtitle">
                            No data found
                        </Typography>
                    }                     
                </Grid>
            </Grid>;
}