import React, { useState, useEffect } from "react";
import ToolsApi from './ToolsApi';
import config from "../config/config"

export default function toolsWorkListApi() {
    const { data, post, get } = ToolsApi();

    const getAutoQcData = async (body) => {
        let url = config.API_URL + "/api/autoqcdata";
        
        let query = undefined
        return post(url, query, body);
    };
    
    const getAutoQcFilter = async (body) => {
        let url = config.API_URL + "/api/autoQcFilter";
        
        let query = undefined
        return post(url, query, body);
    };
 
    const moveAutoQctoManualQc = async(body) => {
        let url = config.API_URL + "/api/moveautoqctomanualqc";
        let query = undefined
        return post(url, query, body);
    };

    const getAutoQcById = async(body) => {
        let url = config.API_URL + "/api/getautoqcbyid";
        let query = undefined
        return post(url, query, body);
    };

    const getManualQcData = async (body) => {
        let url = config.API_URL + "/api/manualqcdata";
        
        let query = undefined
        return post(url, query, body);
    };

      const acceptPublication = async(body) => {
        let url = config.API_URL + "/api/acceptpublication";
        let query = undefined
        return post(url, query, body);
    };

    const getManualQcById = async(body) => {
        let url = config.API_URL + "/api/getmanualqcbyid";
        let query = undefined
        return post(url, query, body);
    };

    const getRejectionCodes = async(body) => {
        let url = config.API_URL + "/api/rejection-codes";
        let query = undefined

        return post(url, query, body)
    };

    const rejectPublication = async(body) => {
        let url =config.API_URL + "/api/rejectpublication";
        let query = undefined

        return post(url, query, body)
    };

    const getManualQcRevisionById = async(body) => {
        let url = config.API_URL + "/api/getmanualqcrevisionbyid";
        let query = undefined
        return post(url, query, body)
    };

    const getAutoQcRevisionById = async(body) => {
        let url = config.API_URL + "/api/getautoqcrevisionbyid";
        let query = undefined

        return post(url, query, body)
    }

    return {
        getAutoQcData,
        getAutoQcFilter,
        moveAutoQctoManualQc,
        getAutoQcById,
        getManualQcData,
        acceptPublication,
        getManualQcById,
        getRejectionCodes,
        rejectPublication,
        getManualQcRevisionById,
        getAutoQcRevisionById
    };
}