import React, { useRef, useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

export function AutocompleteComboBox({id, filter, availableGlns, user, getCategoriesByGln, setAvailableCategories, setAvailableSubCategories, setSelectableSubCategories, selectableBrands, setAvailableBrands, setProviderGLN, token}) {
    function processIpgln(element) {
        let label = (element.label.replace(/ /g, '')).split('-')

        if(label.slice(-1) === filter.search.providerGLN) {
            return element
        } 
    }

    const ipGlnComboBox = (props) => {
        const currentOption = availableGlns.find(
            processIpgln
        );

        if(currentOption !== undefined) {
            props.inputProps.value = currentOption.label;
        } else if(user.user_type === "1" && availableGlns.length == 0) {
            props.inputProps.value = user.InformationProviderGLN
        } 

        return <TextField {...props} label="IP Name" />;
    }

    const setCategoriesGLN = (e, v) => {
        let value = ''
        if(typeof v !== undefined && v !== null) {
            value = (v.label.replace(/ /g, '')).split('-')
        }
        
        if(Array.isArray(value)) {
            let data = {
                "selectedGLN": value[1],
                "token": token
            }
            getCategoriesByGln(data).then(response => {

                if(response.categories != undefined && Array.isArray(response.categories) && response.categories.length > 0) {
                    let availableCat = [];
                    response.categories.map((elem, i) => {
                        availableCat.push({label: elem.label, value: elem.value, key: elem.value})
                    })
                    setAvailableCategories(availableCat);
                }

                if(response.subcategories != undefined && Array.isArray(response.subcategories) && response.subcategories.length > 0) {
                    let availableSubCat = [];
                    response.subcategories.map((elem, i) => {
                        availableSubCat.push({label: elem.label, value: elem.value, key: elem.value, category: elem.category})       
                    })
                    setAvailableSubCategories(availableSubCat);
                    setSelectableSubCategories(availableSubCat);
                }
            })
        }

        //Set brands by GLN
        let availableBrandsGln = [];

        selectableBrands.map((item,index)=> {
            if(item.InformationProviderGLN === value[1]) {
                availableBrandsGln.push({label: item.label, value: item.label, key: item.label})
            }
        })

        setAvailableBrands(availableBrandsGln);
    }

    let inputText = {fontWeight: "100", width:"100%", fontFamily: "Proxima Nova"};

    return  <Autocomplete
                disablePortal
                id={id}
                style={inputText}
                options={availableGlns}
                onChange={(e, v) => {setProviderGLN(e, v); setCategoriesGLN(e, v)}}
                value={filter.search.providerGLN}
                renderInput={ipGlnComboBox}
                defaultValue={user.user_type === "1" && availableGlns.length == 0?user.InformationProviderGLN:''}
            />
}