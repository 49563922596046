import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setProductBrowserFilter, setProductBrowserFilterCount, setProductBrowserResults, setProductBrowserCountResults, setProductBrowserLocalPage } from '../../features/productBrowserSlice';

export default function useFilter() {
    const filter = useSelector((state) => state.productBrowser.productBrowserFilter); //from redux store
    const filterCount = useSelector((state) => state.productBrowser.productBrowserFilterCount); //from redux store

    const dispatch = useDispatch();

    const setFilterKeyValue = (key, value) => {
        let copyOfObject = JSON.parse(JSON.stringify(filter));
        copyOfObject[key] = value;
        dispatch(setProductBrowserFilter(copyOfObject));
    };

    const setFilterCountKeyValue = (key, value) => {
        let copyOfObject = JSON.parse(JSON.stringify(filterCount));
        copyOfObject[key] = value;
        dispatch(setProductBrowserFilterCount(copyOfObject));
    };

    const setFilterSearchKeyValue = (key, value) => {
        let copyOfObject = JSON.parse(JSON.stringify(filter));
        copyOfObject["search"][key] = value;
        dispatch(setProductBrowserFilter(copyOfObject));
    };

    const setFilterCountSearchKeyValue = (key, value) => {
        let copyOfObject = JSON.parse(JSON.stringify(filterCount));
        copyOfObject["search"][key] = value;
        dispatch(setProductBrowserFilterCount(copyOfObject));
    };

    const setCount = (value) => {
        setFilterKeyValue("count", value)
    };

    const setPageNumber = (value) => {
        setFilterKeyValue("pageNumber", value)
    };

    const setPageSize = (value) => {
        setFilterKeyValue("pageSize", value)
    };

    const setType = (value) => {
        setFilterKeyValue("type", value)
        setFilterCountKeyValue("type", value)
    };

    const setGtin = (value) => {
        setFilterSearchKeyValue("GTIN", value)
        setFilterCountSearchKeyValue("GTIN", value)
        
    };

    const setBrand = (value) => {
        let selectedBrands = [];
        value.map((item, index) => {
            selectedBrands.push(item.value)
        });
        setFilterSearchKeyValue("brand", selectedBrands)
        setFilterCountSearchKeyValue("brand", selectedBrands)
    };

    const setCategory = (value) => {
        let selectedCategories = [];
        value.map((item, index) => {
            selectedCategories.push(item.value)
        });
        setFilterSearchKeyValue("category", selectedCategories)
        setFilterCountSearchKeyValue("category", selectedCategories)
    };

    const setDescription = (value) => {
        setFilterSearchKeyValue("description", value)
        setFilterCountSearchKeyValue("description", value)
    };

    const setImages = (value) => {
        setFilterSearchKeyValue("images", value)
        setFilterCountSearchKeyValue("images", value)
    };

    const setName = (value) => {
        setFilterSearchKeyValue("name", value)
        setFilterCountSearchKeyValue("name", value)
    };

    const setProductType = (value) => {
        setFilterSearchKeyValue("productType", value)
        setFilterCountSearchKeyValue("productType", value)
    };

    const setProviderGLN = (event, values) => {
        let value = ''
        
        if(typeof values !== undefined && values !== null) {
            value = (values.label.replace(/ /g, '')).split('-')
        }

        setFilterSearchKeyValue("providerGLN", value.slice(-1))
        setFilterCountSearchKeyValue("providerGLN", value.slice(-1))
    };

    const setStatus = (value) => {
        let selectedStatus = [];
        value.map((item, index) => {
            selectedStatus.push(item.value)
        });

        setFilterSearchKeyValue("status", selectedStatus)
        setFilterCountSearchKeyValue("status", selectedStatus)
    };

    const setSubCategory = (value) => {
        let selectedSubCategories = [];
        value.map((item, index) => {
            selectedSubCategories.push(item.value)
        });
        setFilterSearchKeyValue("sub_category", selectedSubCategories)
        setFilterCountSearchKeyValue("sub_category", selectedSubCategories)
    };

    const setCallToken = (value) => {
        setFilterKeyValue("token", value)
        setFilterCountKeyValue("token", value)
    };

    const setSku = (value) => {
        setFilterSearchKeyValue("sku", value)
        setFilterCountSearchKeyValue("sku", value)
    };

    const setOrderBy = (value) => {
        setFilterSearchKeyValue("OrderBy", value)
        setFilterCountSearchKeyValue("OrderBy", value)
    };

    const clearFilters = () => {
        dispatch(setProductBrowserResults([]))
        dispatch(setProductBrowserCountResults(0))
        dispatch(setProductBrowserLocalPage(0))
        let copyOfObject = JSON.parse(JSON.stringify(filter));
        copyOfObject["search"]['GTIN'] = '';
        copyOfObject["search"]['brand'] = [];
        copyOfObject["search"]['category'] = [];
        copyOfObject["search"]['sub_category'] = [];
        copyOfObject["search"]['name'] = '';
        copyOfObject["search"]['providerGLN'] = null;
        copyOfObject["search"]['sku'] = undefined;
        copyOfObject["search"]['images'] = undefined;
        copyOfObject["search"]['status'] = [];
        dispatch(setProductBrowserFilter(copyOfObject));

        let copyOfFilterCount = JSON.parse(JSON.stringify(filterCount));
        copyOfFilterCount["search"]['GTIN'] = '';
        copyOfFilterCount["search"]['brand'] = [];
        copyOfFilterCount["search"]['category'] = [];
        copyOfFilterCount["search"]['sub_category'] = [];
        copyOfFilterCount["search"]['name'] = '';
        copyOfFilterCount["search"]['providerGLN'] = null;
        copyOfFilterCount["search"]['sku'] = undefined;
        copyOfFilterCount["search"]['images'] = undefined;
        copyOfFilterCount["search"]['status'] = [];
        dispatch(setProductBrowserFilterCount(copyOfFilterCount));
    };

    return {
        filter,
        filterCount,
        setCount,
        setPageNumber,
        setPageSize,
        setType,
        setGtin,
        setBrand,
        setCategory,
        setDescription,
        setImages,
        setName,
        setProductType,
        setProviderGLN,
        setStatus,
        setSubCategory,
        setCallToken,
        setSku,
        setOrderBy,
        clearFilters
    };
}
