import React, { useState, useEffect } from "react";
import { Link, Grid, Typoghraphy, Button, Typography, Box } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import toolsEnrichmentWizardApi from "../../../tools/toolsEnrichmentWizardApi";
import toolsProductBrowserApi from "../../../tools/toolsProductBrowserApi";
import { GlnDialog } from "../../Dialog/GlnDialog";
import useToken from '../../App/useToken';
import DOMPurify from "dompurify";

export default function TemplateBase({title, text, url}) {
    const { token } = useToken()
    const { getTemplateFile } = toolsEnrichmentWizardApi()
    const { getAvailableGlns } = toolsProductBrowserApi();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(undefined);
    const [modalGlns, setModalGlns] = useState([]);
    const [template, setTemplate] = useState("");

    const getDataControllersGlns = () => {
        getAvailableGlns({
            "token": token
        }).then(result => {
            if(result.error) {
                alert('Error changing active GLN');
            } else {
                if(result.data) {
                    if (result.data.length > 0) {
                        var GLNs = [];
            
                        result.data.forEach(element => {
                            var label = '';
                            if (element.CompanyName === null) {
                                label = element.InformationProviderGLN;
                            } else {
                                label = element.CompanyName+"-"+element.InformationProviderGLN;
                            }
                            GLNs.push({value: element.InformationProviderGLN, label: label});
                        });

                        if(GLNs.length <= 1) {
                            enrichTemplateFile(template, null)
                        } else {
                            console.log(GLNs)
                            setModalTitle("Select GLN");
                            setModalOpen(true)
                            setModalGlns(GLNs)
                        }
                    } else {
                        enrichTemplateFile(template, null)
                    }
                }
            }
        })
    }

    useEffect(()=>{
        console.log(template)
        if(template !== '' && template !== undefined && template !== null) {
            getDataControllersGlns()
        }
    }, [template])

    const downloadTemplate = (title, gln) => {
        console.log(title)
        //let template = ''
        if(title === 'marketing data') {
            setTemplate('ecommerce')
        } else if (title === 'digital assets meta data') {
            setTemplate('dam')
            
        } else {
            let type = title.split(' ')
            setTemplate(type[0])
        }
    }
    
    const enrichTemplateFile = (template, gln) => {
        getTemplateFile({
            "token": token,
            "template": template,
            "gln": gln
        }).then(result => {
            if(result.code == 0) {
                if(result.response !== undefined) {
                    window.open(result.response)
                }
            }
        })
    }

    const handleModalClose = (gln) => {
        setModalOpen(false);
        setModalTitle("");
        
        if(gln !== undefined) {
            enrichTemplateFile(template, gln)    
        }
    }

    let buttonStyle = {
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "400px",
        height: "80px",
        border: "6px solid #F2FCFF",
        marginTop: '30px',
        marginBottom: '30px'
    }

    let textButtonStyle = {
        fontFamily: 'Proxima Nova',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "30px",
        lineHeight: "30px",
        alignItems: "center",
        textAlign: "center",
        color: "#1EB1E0",
        textDecorationLine: "underline",
        textTransform: "capitalize",
    }

    return  <Grid container item xs={12} direction="column">
                <Grid item><Typography variant="titleEnrichments">{title}</Typography></Grid>
                <Grid item><Typography variant={"mainEnrichmentsText"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text, { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}></Typography></Grid>
                
                <Grid item container justifyContent={"center"} alignContent={"center"} minHeight={'80px'}>
                    <Grid item>
                        {title !== 'digital assets' ?
                        <Button 
                            style={buttonStyle}
                            component={Link}
                            startIcon={<DownloadIcon style={{width:'30px', height: '30px'}} />}
                            onClick={()=>downloadTemplate(title, null)}
                        >
                            <Typography style={textButtonStyle}>Download Template</Typography>
                        </Button>: ''}
                    </Grid>
                </Grid>
                <GlnDialog open={modalOpen} onClose={handleModalClose} title={modalTitle} options={modalGlns}  />
            </Grid>;
}