import React from "react";
import { Grid, Box, Typography, Button, Card, CardMedia, Avatar, FormControl, OutlinedInput} from "@mui/material";
import defaultImg from "../../img/defaultProfile.jpg";
import config from "../../config/config";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export default function ProfileImg({imgUrl, size, upload, cardSize, setValue}) {
    let cardStyle={
        width: cardSize !== undefined ? cardSize : size !== undefined ? size : "45px", 
        height: cardSize !== undefined ? cardSize : size !== undefined ? size : "45px",
        background: 'rgba(0,0,0,0)',
        borderWidth: '0px',
        justifyContent: "center", display: "flex"
    }
    let imgStyle = {width: size !== undefined ? size : "40px", height: size !== undefined ? size : "40px", borderRadius:"200px"};
    let finalUrl =  imgUrl !== undefined && imgUrl !== "" && imgUrl !== null ?
                        typeof imgUrl === "string" ? 
                            imgUrl.includes('base64') ?
                                imgUrl
                            : config.API_URL + "/public/uploaded_files/" + imgUrl 
                        : URL.createObjectURL(imgUrl) 
                    : undefined;
    let buttonUploadStyle = {
        color: "white", 
    }

    const uploadButton = () => {
        return  <FormControl sx={{ m: 1, width: '50px' }}>
                    <OutlinedInput
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type={"file"}
                        onChange={(e) => {setValue(e.target.files[0])}}
                    />
                    <label htmlFor="raised-button-file">
                        <Button  sx={buttonUploadStyle} component="span">
                            <Avatar sx={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #1EB1E0;" }}>
                                <CameraAltIcon fontSize={"medium"} />
                            </Avatar>
                        </Button>
                    </label> 
                </FormControl>
    }

    return  finalUrl !== undefined ?
                <Card style={cardStyle} variant="outlined">
                    <div style={{ position: "relative" }}>
                        <CardMedia style={imgStyle}  component="img" image={finalUrl}/> 
                        <div style={{position: "absolute", color: "white", bottom: 10, right: -11}}>
                            {upload ? 
                                uploadButton()
                            : 
                                ''
                            }   
                        </div>
                    </div>
                </Card>
                
            :
                <Card style={cardStyle} variant="outlined">
                    <div style={{ position: "relative" }}>
                        <CardMedia style={imgStyle}  component="img" image={defaultImg}/> 
                        <div style={{position: "absolute", color: "white",bottom: 10, right: -11}}>
                            {upload ? 
                                uploadButton()
                            : 
                                ''
                            }   
                        </div>
                    </div>
                </Card>
            ;
}