import React, { useState, useEffect } from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

export default function QcProductTable({view, rows, columns, supplierFilter, onRowsSelectionHandler, setQcData, setQcFilter, handleOnCellClick, handleRowClick, handleActionRow, setFilterModel, setRowCount}) {

    return  <Grid item xs={10} paddingRight="20px">
                <Box style={{ height: '100%', width: '100%'}}>
                    <DataGridPro
                    rows={rows}
                    columns={columns}
                    getRowId={(r) => r.publishedGTIN + '-' + r.GTIN + '-' + r.InformationProviderGLN + '-' + r.targetMarket}
                    pageSize={25}
                    rowsPerPageOptions={[25]}
                    checkboxSelection
                    disableSelectionOnClick={true}
                    hideFooterSelectedRowCount
                    onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                    filterModel={{
                        items: supplierFilter
                    }}
                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                    onCellClick={handleOnCellClick}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                    }
                    onStateChange={(e) => {
                        if(setRowCount !== undefined) {
                            setRowCount(e.pagination.rowCount)
                        }
                    }}
                    />
                </Box>
            </Grid>;
}