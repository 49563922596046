import React from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import HandymanIcon from '@mui/icons-material/Handyman';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import ForumIcon from '@mui/icons-material/Forum';
import QrCodeIcon from '@mui/icons-material/QrCode';

    let menuOptions = {
        gridStyle: {backgroundColor : "#1eb1e0", color: "white", textDecoration: "none", height: "100%"},
        itemStyle: {
            '*:hover': {
                background: "#3078B0",
            }
        },
        boxLogo: {backgroundColor : "white", height : "10%", minHeight : "70px"},
        boxBottom: {backgroundColor : "white", height : "10%", minHeight : "70px"},
        menuWrapperStyle: {backgroundColor : "primary",width: "100%", height: "80%", overflow: "auto"},
        menuStyle: {width: "100%"},
        menuItems: {
            "dashboard": {
                "label": "Dashboard",
                "icon": <DashboardIcon />,
                "link": "/dashboard",
                "submenu": false
            },
            "products": {
                "label": "Product Browser",
                "icon" : <ScatterPlotIcon />,
                "link": "/product-browser",
                "submenu": false
            },
            "workList": {
                "label": "Work List",
                "icon" : <BarChartIcon />,
                "submenuLinks": {
                    "autoQc": {
                        "label": "Auto QC",
                        "link": "/autoqc",
                    },
                    "manualQc": {
                        "label": "Manual QC",
                        "link": "/manualqc",
                    },
                },
                "submenu": true
            },
            "enrichments": {
                "label": "Enrichments",
                "icon" : <ElectricBoltIcon />,
                "link": "/importexcelfile",
                "submenu": false
            },
            "digitalLink": {
                "label": "Digital Link",
                "icon" : <QrCodeIcon />,
                "link": "/digital-link",
                "submenu": false
            },
            "prefixManagement": {
                "label": "Prefix Management",
                "icon" : <InsertDriveFileIcon />,
                "link": "/insertprefix",
                "submenu": false
            },
            "companyAndUserManagement": {
                "label": "Company and User Management",
                "icon" : <BusinessCenterIcon />,
                "link": "/company",
                "submenu": false
            },
            "companyManagement": {
                "label": "Company Management",
                "icon" : <BusinessCenterIcon />,
                "link": "",
                "submenu": false
            },
            "userManagement": {
                "label": "User Management",
                "icon" : <PersonIcon />,
                "link": "",
                "submenu": false
            },
            "messagesBoard": {
                "label": "Messages Board",
                "icon" : <ForumIcon />,
                "link" :"/messages-board",
                "submenu": false
            },
            "reports": {
                "label": "Reports",
                "icon" : <InsertDriveFileIcon />,
                "submenuLinks": {
                    "companyManagementReport": {
                        "label": "Company Management Report",
                        "link": "/reports/company-historical",
                    },
                    "dataRecipientReport": {
                        "label": "Data Recipient Report",
                        "link": "/reports/retailer-report",
                    },
                    "loginLogoutReport": {
                        "label": "Login/Logout",
                        "link": "/reports/login-logout",
                    },
                    "productReport": {
                        "label": "Product Report",
                        "link": "/product-reports",
                    },
                    "qcManagementReport": {
                        "label": "QC Management",
                        "link": "/reports/qc-management",
                    },
                    "supplierReport": {
                        "label": "Supplier Report",
                        "link": "/reports/supplier-report",
                    },
                    "syncListDataReport": {
                        "label": "Sync List Data",
                        "link": "/reports/sync-list-data",
                    },
                    "userManagementReport": {
                        "label": "User Management",
                        "link": "/reports/signed-user",
                    }
                },
                "submenu": true
            },
            "resources": {
                "label" : "Resources",
                "icon": <HandymanIcon />,
                "submenuLinks": {
                    // "User Reference Guides": {
                    //     "link" :"",
                    //     "isExternalLink": true,
                    // },
                    "checkDigit": {
                        "label" : "Check Digit",
                        "link" :"https://www.gs1.org/services/check-digit-calculator",
                        "isExternalLink": true,
                    },
                    "gs1SaPortal": {
                        "label" : "GS1 SA Portal",
                        "link" :"https://gs1zace.gs1za.org/",
                        "isExternalLink": true,
                    },
                    "searchGln": {
                        "label" : "Search GLN",
                        "link":"https://gepir.gs1.org/index.php/search-by-gln",
                        "isExternalLink": true
                    },
                    "verifiedByGs1": {
                        "label" : "Verified by GS1",
                        "link" :"https://www.gs1.org/services/verified-by-gs1",
                        "isExternalLink": true,
                    }
                },
                "userGroups": [
                    "Supplier Admin", 
                    "Data Recipient Admin", 
                    "GS1 Admin",
                    "Data Recipient [Public]",
                    "Supplier User",
                    "Data Recipient User",
                    "GS1 User"
                ],
                "submenu": true
            }
        },
        imgStyle: {width: "70%"},
        textStyle: {color: "white", textDecoration: "none", fontWeight: '700', fontSize: '16px', lineHeight: '16px'}
    }


export default menuOptions;