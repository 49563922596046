import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Grid, Typography, Container } from '@mui/material';
import BasicData from "./Enrichments/BasicData";
import Ecommerce from "./Enrichments/Ecommerce";
import Packaging from "./Enrichments/Packaging";
import Ingredients from "./Enrichments/Ingredients";
import Nutritional from "./Enrichments/Nutritional";
import Allergens from "./Enrichments/Allergens";
import Healthcare from "./Enrichments/Healthcare";
import Sustainability from "./Enrichments/Sustainability";
import DAM from "./Enrichments/DAM";
import CircularProgress from '@mui/material/CircularProgress';
import EnrichmentWrapper from "./EnrichmentWrapper";


export default function ProductTab({tabsDefinition, enrichments, definition, productPermission, userAbility, publicDomain, messageId}) {
    const [value, setValue] = React.useState(0);
    const [tabs, setTabs] = React.useState(undefined);
    
    if(typeof enrichments !== 'undefined') {
        //Remove healthcare if product Category !== 51000000
        tabsDefinition.map((elem, key) => {
            if(elem.menu === "healthcare" && enrichments['basicData'].Category !== "51000000") {
                tabsDefinition.splice(key, 1);
            }

            if(elem.menu === "nutritional" && enrichments['basicData'].Category !== "50000000") {
                tabsDefinition.splice(key, 1);
            }
        })

        if(typeof enrichments !== 'undefined' && enrichments !== null && enrichments['basicData'].Category !== "51000000") {
          delete enrichments.healthcare  
        }

        if(typeof enrichments !== 'undefined' && enrichments !== null && enrichments['basicData'].Category !== "50000000") {
          delete enrichments.nutritional  
        }
    }

    useEffect(() => {
        if(enrichments !== undefined && definition !== undefined) {
            if(enrichments['basicData'].globalClassificationCategory !== undefined) {
                let basicData = []
                basicData = {...enrichments['basicData'], ...basicData};
                if(enrichments['basicData'].globalClassificationCategory.name !== undefined) {
                    basicData.globalClassificationCategoryName = enrichments['basicData'].globalClassificationCategory.name;    
                }

                if(enrichments['basicData'].globalClassificationCategory.code !== undefined) {
                    basicData.globalClassificationCategoryCode = enrichments['basicData'].globalClassificationCategory.code;    
                }

                enrichments['basicData'] = basicData;
            }

            if(enrichments['basicData'].Category === "51000000") {
                setTabs({"basicData" : <BasicData data={enrichments['basicData']} definition={definition} messageId={messageId} />, 
                    "ecommerce" : <Ecommerce />, 
                    "ingredients" : <Ingredients />,
                    "allergens" : <Allergens />,
                    "healthcare" : <Healthcare />, 
                    "packaging" : <Packaging />,
                    "sustainability" : <Sustainability/>,
                    "dam" : <DAM data={enrichments['dam']} isPreview={false} productPermission={productPermission} userAbility={userAbility} isPublicDomain={publicDomain} />
                })
            } else if(enrichments['basicData'].Category === "50000000") {
                setTabs({"basicData" : <BasicData data={enrichments['basicData']} definition={definition}  messageId={messageId} />, 
                    "ecommerce" : <Ecommerce />, 
                    "ingredients" : <Ingredients />, 
                    "nutritional" : <Nutritional />, 
                    "allergens" : <Allergens />,
                    "packaging" : <Packaging />,
                    "sustainability" : <Sustainability/>,
                    "dam" : <DAM data={enrichments['dam']} isPreview={false} productPermission={productPermission} userAbility={userAbility} isPublicDomain={publicDomain} />
                })
            } else {
                setTabs({"basicData" : <BasicData data={enrichments['basicData']} definition={definition}  messageId={messageId} />, 
                    "ecommerce" : <Ecommerce />, 
                    "ingredients" : <Ingredients />,
                    "allergens" : <Allergens />,
                    "packaging" : <Packaging />,
                    "sustainability" : <Sustainability/>,
                    "dam" : <DAM data={enrichments['dam']} isPreview={false} productPermission={productPermission} userAbility={userAbility} isPublicDomain={publicDomain} />
                })
            } 
        }
    }, [enrichments, definition])

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={2}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
    
    let mainDataStyle = {overflowY: "scroll"}
    return  <Grid container height="100%" xs={12} >
                <Grid sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }} >
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" scrollButtons="auto" variant="scrollable">
                        <Tab label="Basic Data" {...a11yProps(0)} />
                        {tabsDefinition.map((elem, key) => (
                            <Tab label={elem.displayName} {...a11yProps(key+1)} />
                        ))}
                        <Tab label="Digital Assets" {...a11yProps(tabsDefinition.length+1)} />
                    </Tabs>
                </Grid>
                {enrichments !== undefined && tabs !== undefined ? 
                    <Grid container height="100%" sx={12} justifyContent="center" alignItems="center">
                        <Box display="flex" height="90%" sx={12} width="100%" style={mainDataStyle} justifyContent="center">
                            <Box item height="100%" width="95%" sx={10} >
                                <TabPanel value={value} index={0}>
                                    {tabs["basicData"]}
                                </TabPanel>
                                {tabsDefinition.map((elem, key) => (
                                    <TabPanel value={value} index={key+1}>
                                        <EnrichmentWrapper view={tabs[elem.menu]} data={enrichments[elem.menu]}/>
                                    </TabPanel>
                                ))}
                                <TabPanel value={value} index={tabsDefinition.length+1}>
                                    {tabs["dam"]}
                                </TabPanel>
                            </Box>
                        </Box>
                    </Grid>
                : 
                    <CircularProgress />
            }
            </Grid>

}