import useToken from '../components/App/useToken';
import { useNavigate  } from 'react-router-dom';

export default function ToolsApi() {
    const { tokenValid, setTokenValid } = useToken();
    const navigate = useNavigate();

    const postCall = async (url, query, body) => {
        let newUrl = url;
        if(query !== undefined) {
            newUrl = newUrl + "?" + encodeGetParams(query);
        }
        
        const response = await fetch(newUrl, {
            method: "POST",
    
            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify(body),
        });
        let actualData = await response.json();
        
        if(actualData.code === 2 || actualData.data == 'Invalid token') {
           navigate('/login');
        } 

        return actualData;
    };

    const putCall = async (url, query, body) => {
        let newUrl = url;
        if(query !== undefined) {
            newUrl = newUrl + "?" + encodeGetParams(query);
        }
        
        const response = await fetch(newUrl, {
            method: "PUT",
    
            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify(body),
        });
        let actualData = await response.json();
        
        if(actualData.code === 2) {
            setTokenValid("false");
        }

        return actualData;
    };

    const deleteCall = async (url, query, body) => {
        let newUrl = url;
        if(query !== undefined) {
            newUrl = newUrl + "?" + encodeGetParams(query);
        }
        
        const response = await fetch(newUrl, {
            method: "DELETE",
    
            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify(body),
        });
        let actualData = await response.json();
        
        if(actualData.code === 2) {
            setTokenValid("false");
        }

        return actualData;
    };

    const getCall = async (url, query, body) => {
        let newUrl = url;
        if(query !== undefined) {
            newUrl = newUrl + "?" + encodeGetParams(query);
        }

        const response = await fetch(newUrl, {
            method: "GET",
    
            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify(body),
        });
        let actualData = await response.json();

        if(actualData.code === 2 || actualData.data == 'Invalid token') {
            navigate('/login');
        }

        return actualData;
    };

    const postFile =  async (url, query, body) => {
        let newUrl = url;

        const response =  await fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + query.token
            },
            method: "POST",
            body: body,
        });

        let actualData = await response.json();

        if(actualData.code === 2 || actualData.data == 'Invalid token') {
            navigate('/login');
        }

        return actualData;
    };

    const postImages =  async (url, query, body) => {
        const response = await fetch(url,{
            method: "POST",
            body: JSON.stringify(body),
        });

        let actualData = await response.json();

        if(actualData.code === 2 || actualData.data == 'Invalid token') {
            navigate('/login');
        }

        return actualData;
       
    };

    const encodeGetParams = p => 
        Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

    return {
        post: postCall,
        get: getCall,
        put: putCall,
        deleteCall,
        postFile,
        postImages
    };
}