import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Box, Tab, Tabs, TextField, Button, Typography, Checkbox, FormControlLabel} from '@mui/material';
import PropTypes from "prop-types";
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import tsLogo from "../../img/tslogo.png";
import tsBack from "../../img/loginback.png";
import gs1Logo from "../../img/gs1logo.png";
import tsMockupHome from "../../img/tsmockuphome.png"
import config from "../../config/config"
import { AlertResponse } from "../Alert/AlertResponse";
import toolsUserApi from "../../tools/toolsUserApi";
import { useDispatch } from 'react-redux';
import { setUserAbility } from "../../features/userAbilitySlice";
import defineAbilityFor from '../../permission/defineAbility';

async function loginUser(credentials) {
    return fetch(config.API_URL + "/api/login", {
        method: "POST",

        headers: {
            "Content-Type": "application/json",
        },

        body: JSON.stringify(credentials),
    }).then(
        (data) => data.json()

    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

let style = {
    welcome : {
        backgroundColor : "#3284C6", 
        paddingTop: "100px", 
        backgroundImage: `url(${tsBack})`, 
        backgroundPosition: 'top left',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh'
    },
    loginText : {marginTop : "50px", textAlign: "justify"},
    login : {height: "100%" , paddingTop: "100px"},
    loginElem : {marginTop : "40px"},
}

export default function Login() {
    const {login, recovery, resetPassword} = toolsUserApi();
    const [email, setUserName] = useState();
    const [password, setPassword] = useState();
    const [authenticated, setAuthenticated] = useState(false);
    const { token, setToken, tokenValid } = useToken();
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    const [responseMessage, setResponseMessage] = useState('')
    const [responseSeverity, setResponseSeverity] = useState('')
    const [responseSeverityVariant, setResponseSeverityVariant] = useState('')
    const [open, setOpen] = useState(false)
    const [passRecovery, setPassRecovery] = useState(false);
    const dispatch = useDispatch();
    const [value, setValue] = useState(config.APP_PROD_URL === window.location.href?0:1);
    const location = useLocation();
    const [confirmpassword, setConfirmPassword] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    let params = useParams();
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpen(false);
    }

    useEffect(()=>{
        if(token && tokenValid == "true" && !authenticated) {
            setAuthenticated(true);
            navigate('/dashboard')
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = await login({
            email,
            password,
        });

        //Show alert on invalid user
        if( token.code !== 1 && typeof token.status !== "success") {                
            setResponseMessage(token.status)
            setResponseSeverity("error")
            setResponseSeverityVariant("tsError")
            setOpen(true)
        }

        setToken(token.response.token);
        setUser(token.response);
    };

    const handleRecovery = async (e) => {
        e.preventDefault();
        
        recovery({email}).then(response => {
            if(response.code == 1) {
                setResponseSeverity("success")
            } else {
                setResponseSeverity("error")
            }
            setResponseMessage(response.status)
            setOpen(true)
        })
    };
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(newValue === 0) {
            window.location.replace(config.APP_PROD_URL)
        } else {
            window.location.replace(config.APP_PREPROD_URL)
        }
    };

    const onInputChange = e => {
        e.target.name === 'password'?setPassword(e.target.value):setConfirmPassword(e.target.value)
        validateInput()
    };

    const validateInput = e => {
        (password !== confirmpassword)?setErrorMessage('Password and Confirm Password must be the same.'):setErrorMessage('')
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        let data = {
            confirmpassword: confirmpassword,
            password: password,
            remember_token: params.token
        }

        resetPassword(data).then(response => {
            if(response !== undefined) {
                if(response.code == 1) {
                    setResponseSeverity("success")
                } else {
                    setResponseSeverity("error")
                }
                setResponseMessage(response.status)
                setOpen(true)
            }
        })
    };

    let form = '';

    if(passRecovery) {
        form = <form style={style.loginElem} onSubmit={handleRecovery}>
                    <TextField fullWidth style={style.loginElem} label="Email" variant="outlined" onChange={(e) => setUserName(e.target.value)}/>
                    <Button fullWidth style={style.loginElem} type="submit" variant="contained">Send recovery</Button>
                    <Button 
                        fullWidth 
                        style={style.loginElem} 
                        onClick={() => setPassRecovery(false)}>Go to Log In</Button>
                </form>
    } else if (location !== undefined && location.pathname.includes('/reset/')) {
        form = <form style={style.loginElem} onSubmit={handleResetPassword}>
                    <TextField
                        error={password !== confirmpassword}
                        helperText={errorMessage}
                        fullWidth 
                        style={style.loginElem} 
                        label="Password" 
                        variant="outlined" 
                        type="password" 
                        name="password" 
                        required
                        onChange={onInputChange} 
                        onBlur={validateInput}/>
                    <TextField
                        error={password !== confirmpassword}
                        helperText={errorMessage}
                        fullWidth 
                        style={style.loginElem} 
                        label="Confirm Password" 
                        variant="outlined" 
                        type="password"
                        name="confirmpassword" 
                        required 
                        onChange={onInputChange} 
                        onBlur={validateInput}/>
                    <Button
                        fullWidth 
                        style={style.loginElem} 
                        type="submit" 
                        variant="contained" 
                        disabled={password !== confirmpassword?true:false}>
                        Reset Password
                    </Button>
                    <Button 
                        fullWidth 
                        style={style.loginElem} 
                        onClick={() => navigate('/login')}>Go to Log In</Button>
                </form>
    } else {
        form = <Box>
                    <Typography color={"#3284C6"} variant="h4" component="h4">
                        Log In
                    </Typography>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" scrollButtons="auto" variant="scrollable" sx={{paddingTop: '20px'}}>
                        <Tab  sx={{ fontWeight: 'bold', paddingLeft: 0 }} label="Production" {...a11yProps(0)} />
                        <Tab  sx={{ fontWeight: 'bold' }} label="Preproduction" {...a11yProps(1)} />
                    </Tabs>  
                    <form style={style.loginElem} onSubmit={handleSubmit}>
                        <TextField fullWidth style={style.loginElem} label="Email" variant="outlined" onChange={(e) => setUserName(e.target.value)}/>
                        <TextField fullWidth style={style.loginElem} label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} type="password"/>
                        <FormControlLabel style={style.loginElem} control={<Checkbox />} label="Remember me" />
                        <Button fullWidth style={style.loginElem} type="submit" variant="contained">Log in</Button>
                        <Button fullWidth style={style.loginElem} onClick={() => setPassRecovery(true)}>Forgot Password</Button>
                    </form>
                </Box>
    }

    let loginText = {fontSize: '20px', fontFamily: 'Proxima Nova', lineHeight: '24px', color:'#FFFFFF', textAlign: 'justify'}

    return (
            <Grid item container xs={12}>
                <Grid item container xs={6} style={style.welcome}>
                    <Grid item container xs={12} alignContent="center" justifyContent="center">
                        <Box component="img" src={tsLogo}></Box>
                    </Grid>
                    <Grid item container xs={12} alignContent="center" justifyContent="center">
                        <Grid item container xs={8} sx={{paddingBottom: '30px', paddingTop: '30px'}}>
                            <Typography component="p" style={loginText} fontWeight="700">Welcome to the TrustedSource South Africa Product Experience Hub! </Typography> 
                            <Typography component="p" style={loginText}>The solution offered to
                            GS1 South Africa members, to empower brand-owners to create, enrich and distribute product
                            information as a single source of truth, compelling engagement between brand owners,
                            retailers, and consumers, to unlock benefits throughout the value chain.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center" alignContent="center">
                        <Grid item container xs={8}><Box component="img" src={tsMockupHome} maxWidth="100%"></Box></Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={6} sx={{paddingTop: '100px'}} rowSpacing={0} alignContent="start">
                    <Grid item container xs={12} justifyContent="center">
                        <Box component="img" src={gs1Logo} ></Box>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                        <Grid item container xs={10} justifyContent="center">
                            {form}
                        </Grid>
                        <AlertResponse
                            open={open}
                            autoHideDuration="100000"
                            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                            onCloseSnackbar={handleClose}
                            onCloseAlert={handleClose} 
                            severity={responseSeverity}
                            message={responseMessage}
                        ></AlertResponse>
                    </Grid>
                </Grid>
            </Grid>       
    );
}

