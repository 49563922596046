import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from '@mui/material';
import NutritionalDetails from "./NutritionalDetails";

export default function Nutritional({data}) {
    const [nutritionalData, setNutritionalData] = useState(undefined);

    useEffect(()=>{
        if(data !== undefined) {
            let newNutritional = undefined
            if(data["nonProcessed"] !== undefined && Array.isArray(data["nonProcessed"]) && data["nonProcessed"].length > 0) {
                let newData = data["nonProcessed"]
                
                newData.map((nutData, index) => {
                    if(nutData['nutrientDetail'] !== undefined){
                        nutData['nutrientDetail'].map((elem, key) => {
                            if(elem['nutrientTypeCode'] !== undefined && elem['nutrientTypeCode'] === 'ENER-') {
                                nutData['calories'] = {
                                    'cal' : Math.round(parseFloat(elem['quantityContained'][0]) / 4.184),
                                    'jul' : elem['quantityContained'][0]
                                }
                                delete nutData['nutrientDetail'][key];
                            }
                        })
                    }
                })
                
                newNutritional = newData
            } else {
                if(data["processed"] !== undefined && Array.isArray(data["processed"]) && data["processed"].length > 0) {
                    newNutritional = data["processed"]
                }
            }

            setNutritionalData(newNutritional);
        }
    }, [data])    

    // Count nutrientDetail entries
    var nutrientDetailSize = 0;
    var nutrientDetailTS = 0;

    if(Array.isArray(nutritionalData)) {
        nutritionalData.map((value, key) => {
            if(typeof value['nutrientDetail'] !== 'undefined' && value['nutrientDetail'].length > 0) {
                nutrientDetailSize++
            } else if (typeof value['source'] !== 'undefined' && value['source'] == "TS") {
                nutrientDetailTS++
            }
        })
    }

    let headerStyle = {borderBottom: "10px solid #000", padding: "0 0 0.25rem", margin: "0 0 0.5rem" }
    let boxStyle = {border: "1px solid #000", width: "400px", padding: "0.5rem"}
    let subHeader = {borderBottom: "2px solid rgb(0, 0, 0)"}

    return  nutritionalData !== undefined && (nutrientDetailSize > 0 || nutrientDetailTS > 0) ?
                nutrientDetailSize > 0 ?
                    <Grid container direction="row" columnSpacing={5}>
                        {nutritionalData.map((nElem) => (
                            typeof nElem['nutrientDetail'] !== 'undefined' ?
                            <Grid item xs={12} md={6} marginBottom="30px">
                                <Box style={boxStyle}>
                                    <Grid item container direction="column" style={headerStyle}>
                                        <Grid item p={1} style={{borderBottom: "1px solid #000"}}><Typography variant="nutritionalTagTitle">Nutrition Facts</Typography></Grid>
                                        <Grid item>
                                            <Typography variant="nutritionalTagSubTitle">
                                                {nElem['servingSizeDescription'] !== undefined ? Array.isArray(nElem['servingSizeDescription']) ? !isNaN(parseFloat(nElem['servingSizeDescription'][0]).toFixed(2)) ? parseFloat(nElem['servingSizeDescription'][0]).toFixed(2) + 'SERVING PER CONTAINER' : '': !isNaN(parseFloat(nElem['servingSizeDescription']).toFixed(2))?parseFloat(nElem['servingSizeDescription']).toFixed(2) + 'SERVING PER CONTAINER': '' : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="nutritionalTagSubTitle">
                                                SERVING SIZE {nElem['servingSize'] !== undefined ? Array.isArray(nElem['servingSize']) ? parseFloat(nElem['servingSize'][0]).toFixed(2) : parseFloat(nElem['servingSize']).toFixed(2) : ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="column" style={subHeader}>
                                        <Grid item><Typography variant="nutritionalTagSmall">Amount Per Serving</Typography></Grid>
                                        {typeof nElem['calories'] !== 'undefined' ?
                                            <Grid item container direction="row" justifyContent="space-between">
                                                <Grid item><Typography variant="nutritionalTagSmall" fontWeight="700">Calories</Typography></Grid>
                                                <Grid item><Typography variant="nutritionalTagSmall">{ parseFloat(nElem['calories']['cal']).toFixed(2) } kcal / {parseFloat(nElem['calories']['jul']).toFixed(2) } kj</Typography></Grid>
                                            </Grid>
                                        : ''
                                        }
                                    </Grid>
                                    <Grid item container justifyContent="flex-end">
                                        <Grid item><Typography variant="nutritionalTagXS">% Daily Value*</Typography></Grid>
                                    </Grid>
                                    
                                    <NutritionalDetails details={(nElem["nutrientDetail"])} />
                                     
                                    <Grid item container rowSpacing={1}>
                                        <Grid item container justifyContent="center">
                                            <Grid item><Typography variant="nutritionalTagSmallUpper">* PERCENT DAILY VALUES ARE BASED ON A 2,000 CALORIE DIET. YOUR DAILY VALUES MAY BE HIGHER OR LOWER DEPENDING ON YOUR CALORIE NEEDS</Typography></Grid>
                                        </Grid>
                                        <Grid item container justifyContent="flex-start">
                                            <Grid item><Typography variant="nutritionalTagSmallUpper">CALORIES PER GRAM:</Typography></Grid>
                                        </Grid>
                                        <Grid item container justifyContent="center">
                                            <Grid item><Typography variant="nutritionalTagSmallUpper">Fat 9 &bull; Carbohydrate 4 &bull; Protein 4</Typography></Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            : ''
                        ))}
                    </Grid>
                    :
                    <Grid container direction="row" columnSpacing={5}>
                    {nutrientDetailTS > 0 ?
                        <Grid item xs={6} marginBottom="30px">
                            <Box style={boxStyle}>
                                <Grid item container direction="column" style={headerStyle}>
                                    <Grid item p={1} style={{borderBottom: "1px solid #000"}}><Typography variant="nutritionalTagTitle">Nutrition Facts</Typography></Grid>
                                    <Grid item>
                                        <Typography variant="nutritionalTagSubTitle">
                                            {nutritionalData[0]['servingSizeDescription'] !== undefined ? Array.isArray(nutritionalData[0]['servingSizeDescription']) ? !isNaN(parseFloat(nutritionalData[0]['servingSizeDescription'][0]).toFixed(2)) ? parseFloat(nutritionalData[0]['servingSizeDescription'][0]).toFixed(2) + 'SERVING PER CONTAINER' : '': !isNaN(parseFloat(nutritionalData[0]['servingSizeDescription']).toFixed(2))?parseFloat(nutritionalData[0]['servingSizeDescription']).toFixed(2) + 'SERVING PER CONTAINER': '' : ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="nutritionalTagSubTitle">
                                            SERVING SIZE {nutritionalData[0]['servingSize'] !== undefined ? Array.isArray(nutritionalData[0]['servingSize']) ? parseFloat(nutritionalData[0]['servingSize']).toFixed(2) : nutritionalData[0]['servingSize'] : ""}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column" style={subHeader}>
                                    <Grid item><Typography variant="nutritionalTagSmall">Amount Per Serving</Typography></Grid>
                                    {typeof nutritionalData['calories'] !== 'undefined' ?
                                        <Grid item container direction="row" justifyContent="space-between">
                                            <Grid item><Typography variant="nutritionalTagSmall" fontWeight="700">Calories</Typography></Grid>
                                            <Grid item><Typography variant="nutritionalTagSmall">{ parseFloat(nutritionalData[0]['calories']['cal']).toFixed(2) } kcal / {parseFloat(nutritionalData[0]['calories']['jul']).toFixed(2) } kj</Typography></Grid>
                                        </Grid>
                                    : ''
                                    }
                                </Grid>
                                <Grid item container justifyContent="flex-end">
                                    <Grid item><Typography variant="nutritionalTagXS">% Daily Value*</Typography></Grid>
                                </Grid>
                                
                                <NutritionalDetails details={(nutritionalData)} />
                                 
                                <Grid item container rowSpacing={1}>
                                    <Grid item container justifyContent="center">
                                        <Grid item><Typography variant="nutritionalTagSmallUpper">* PERCENT DAILY VALUES ARE BASED ON A 2,000 CALORIE DIET. YOUR DAILY VALUES MAY BE HIGHER OR LOWER DEPENDING ON YOUR CALORIE NEEDS</Typography></Grid>
                                    </Grid>
                                    <Grid item container justifyContent="flex-start">
                                        <Grid item><Typography variant="nutritionalTagSmallUpper">CALORIES PER GRAM:</Typography></Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center">
                                        <Grid item><Typography variant="nutritionalTagSmallUpper">Fat 9 &bull; Carbohydrate 4 &bull; Protein 4</Typography></Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    : 
                        ''
                    }
                    </Grid>
            :
                <Typography variant="productSubtitle">
                    No data found
                </Typography>
}