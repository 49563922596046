import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../Login/Login';
import Dashboard from "../Dashboard/Dashboard";
import Company from "../Company/Company";
import Bootstrap from "../Bootstrap/Bootstrap";
import ProductBrowser from "../ProductBrowser/ProductBrowser";
import AutoQc from "../WorkList/AutoQc";
import ManualQc from "../WorkList/ManualQc";
import Reports from "../Reports/Reports";
import ProductDetail from "../ProductDetail/ProductDetail";
import CompanyUserManagement from "../CompanyUserManagement/CompanyUserManagement";
import EnrichmentsUpload from "../EnrichmentsUpload/EnrichmentsUpload";
import CompanyDetail from "../CompanyUserManagement/Detail/Company/CompanyDetail";
import UserDetail from "../CompanyUserManagement/Detail/User/UserDetail";
import PrefixManagement from "../PrefixManagement/PrefixManagement";
import CompanyNew from "../CompanyUserManagement/Detail/Company/CompanyNew";
import UserNew from "../CompanyUserManagement/Detail/User/UserNew";
import MessagesBoard from "../MessagesBoard/MessagesBoard";
import ProductReport from "../Reports/ProductReport/ProductReport";
import DigitalLink from "../DigitalLink/DigitalLink";
import PublicProductDetail from '../ProductDetail/PublicProductDetail';
import PublicBootstrap from '../Bootstrap/PublicBootstrap';
import config from "../../config/config";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

// Create a client
const queryClient = new QueryClient()


function App() {
    return (
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
              {config.APP_PUBLIC_DOMAIN == window.location.origin ?
              <Routes>
                  <Route path="/01/:gtin" element={<PublicBootstrap><PublicProductDetail /></PublicBootstrap>}></Route>
                </Routes>
                :
              <Routes>
                <Route path="/" element={<Login />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/dashboard" element={<Bootstrap><Dashboard /></Bootstrap>}></Route>
                <Route path="/company" element={<Bootstrap><CompanyUserManagement defaultService={"company"}/></Bootstrap>}></Route>
                <Route path="/product-browser" element={<Bootstrap><ProductBrowser /></Bootstrap>}></Route>
                <Route path="/product-browser/:status" element={<Bootstrap><ProductBrowser /></Bootstrap>}></Route>
                <Route path="/importexcelfile" element={<Bootstrap><EnrichmentsUpload /></Bootstrap>}></Route>
                <Route path="/reports/company-historical" element={<Bootstrap><Reports service={"company-historical"} /></Bootstrap>}></Route>
                <Route path="/reports/retailer-report" element={<Bootstrap><Reports service={"data-recipient-report"} /></Bootstrap>}></Route>
                <Route path="/reports/login-logout" element={<Bootstrap><Reports service={"login-logout"} /></Bootstrap>}></Route>
                <Route path="/product-reports" element={<Bootstrap><ProductReport /></Bootstrap>}></Route>
                <Route path="/reports/qc-management" element={<Bootstrap><Reports service={"qc-management"} /></Bootstrap>}></Route>
                <Route path="/reports/supplier-report" element={<Bootstrap><Reports service={"supplier-report"} /></Bootstrap>}></Route>
                <Route path="/reports/sync-list-data" element={<Bootstrap><Reports service={"sync-list-data"} /></Bootstrap>}></Route>
                <Route path="/reports/signed-user" element={<Bootstrap><Reports service={"signed-user"} /></Bootstrap>}></Route>
                <Route path="/autoqc" element={<Bootstrap><AutoQc /></Bootstrap>}></Route>
                <Route path="/manualqc" element={<Bootstrap><ManualQc /></Bootstrap>}></Route>
                <Route path="/insertprefix" element={<Bootstrap><PrefixManagement /></Bootstrap>}></Route>
                <Route path="/usermanagement" element={<Bootstrap><CompanyUserManagement defaultService={"user"}/></Bootstrap>}></Route>
                <Route path="/messages-board" element={<Bootstrap><MessagesBoard></MessagesBoard></Bootstrap>}></Route>
                <Route path="/product-detail/:publishedGTIN/:GTIN/:GLN/:MarketCode" element={<Bootstrap><ProductDetail /></Bootstrap>}></Route>
                <Route path="/editcompany/:id" element={<Bootstrap><CompanyDetail /></Bootstrap>}></Route>
                <Route path="/edituser/:id" element={<Bootstrap><UserDetail /></Bootstrap>}></Route>
                <Route path="/user/new/:company" element={<Bootstrap><UserNew /></Bootstrap>}></Route>
                <Route path="/user/new" element={<Bootstrap><UserNew /></Bootstrap>}></Route>
                <Route path="/company/new" element={<Bootstrap><CompanyNew /></Bootstrap>}></Route>
                <Route path="/reset/:token" element={<Login />}></Route>
                <Route path="digital-link" element={<Bootstrap><DigitalLink /></Bootstrap>}></Route>
              </Routes>
                }
              
          </BrowserRouter>
        </QueryClientProvider>
      );
    }
export default App;
