import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import useToken from '../App/useToken';
import toolsDashboardApi from "../../tools/toolsDashboardApi";
import { Grid, Box, Typography, CircularProgress } from '@mui/material';
import MessageItem from "./MessageItem";
import MessageNew from "./MessageNew";

export default function MessagesBoard() {
	const { token } = useToken()
	const { getMessages } = toolsDashboardApi()
	const [messages, setMessages] = useState([])
	const [expanded, setExpanded] = useState(false)
	const [refresh, setRefresh] = useState(true);

	const handleExpandedChange = (panel) => (event, isExpanded) => {
	    setExpanded(isExpanded ? panel : false)
	}

	const refreshData = () => {
		setRefresh(true)
	}

	useEffect(() => {
		if(refresh){
			getMessages({"token": token}).then(result => {
				if( result != undefined && !result.error && typeof result.data !== undefined) {
					setMessages([result.data])
				}
				setRefresh(false);
			});
			
		}
    }, [refresh]);

    return  <Grid style={{height: '100%'}}>
                <Header name="Messages Board"/>
                <Grid container item p={2}  sx={{overflowY: 'scroll', height:"90%"}}>
					<MessageNew refresh={refreshData} />
					<Grid item container width={"100%"} direction={"column"} spacing={1}>
						<Grid item>
							<Typography variant="productTitle">Messages</Typography>
						</Grid>
						<Grid item>
							{refresh ?
									<CircularProgress />
								:
									messages.length > 0 ?
										messages[0].map((value, index) => 
											<MessageItem value={value} index={index} expanded={expanded} handleExpandedChange={handleExpandedChange} refresh={refreshData} />
										)
									: 
										''
							}
						</Grid>
					</Grid>
                </Grid>
            </Grid>
}