import { createSlice } from '@reduxjs/toolkit'


export const userAbilitySlice = createSlice({
  name: 'userAbility',
  initialState: {
    value: undefined,
  },
  reducers: {
    setUserAbility: (state, action) => {
      state.value = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserAbility } = userAbilitySlice.actions

export default userAbilitySlice.reducer