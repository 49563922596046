import React, { useState, useEffect } from "react";
import { Grid, Select, MenuItem, TextField, FormControl, InputLabel, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SimpleDialog } from "../Dialog/SimpleDialog";
import { ReasonDialog } from "../Dialog/ReasonDialog";
import { TransferDialog } from "../Dialog/TransferDialog";
import toolsPrefixManagementApi from "../../tools/toolsPrefixManagementApi"
import useToken from '../App/useToken';
import useUser from "../App/useUser";
import { isFulfilled } from "@reduxjs/toolkit";
import useToolsNotifications from "../../tools/ToolsNotifications";

const types = [
    {id: 8, label: "GTIN 8"},
    {id: 12, label: "GTIN 12"},
    {id: 13, label: "GTIN 13"},
]

const digits = [
    {id: 12, label: "Single"},
    {id: 7, label: "7-Digit"},
    {id: 8, label: "8-Digit"},
    {id: 9, label: "9-Digit"},
    {id: 10, label: "10-Digit"},
    {id: 11, label: "11-Digit"},
]

const STATUS_ACTIVE = "active"
const STATUS_SUSPENDED = "suspended"
const STATUS_DELETED = "deleted"
const STATUS_REPLACED = "replaced"
const STATUS_TRANSFERED = "transfered"
const STATUS_TRANSFERED_EXPIRED = "transfered expired"
const STATUS_CANCELLED = "cancelled"

export default function PrefixDetail({details, companies, modalType, close}) {
    const { activatePrefix, cancelPrefix, createPrefix, suspendPrefix, transferPrefix, updatePrefix } = toolsPrefixManagementApi();
    const { token } = useToken();
    const { user } = useUser();
    const [loading, setLoading] = useState(true);
    const [localDetails, setLocalDetails] = useState(undefined);
    const [reason, setReason] = useState(undefined);
    const [reasonType, setReasonType] = useState(undefined);
    const [reasonModalOpen, setReasonModalOpen] = useState(false);
    const [transferModalOpen, setTransferModalOpen] = useState(false)
    const [transferData, setTransferData] = useState(undefined);


    const [modalOpen, setModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(undefined);
    const [modalTitle, setModalTitle] = useState(undefined);

    const [BrandOwnerID, setBrandOwnerID] = useState(0);
    const [PrefixTypeNUM, setPrefixTypeNUM] = useState(8);
    const [Searchtype, setSearchtype] = useState(12);
    const [Name, setName] = useState("");
    const [PrefixStart, setPrefixStart] = useState(0);
    const [PrefixStop, setPrefixStop] = useState(0);
    const [PrefixQty, setPrefixQty] = useState(1);
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const {error} = useToolsNotifications();
    const [isSearchTypeDisabled, setIsSearchTypeDisabled] = useState(true);

    const suspendPrefixHandler = () => {
        // {ID , reason, token}
        setReasonModalOpen(true);
        setReasonType("suspend");
    }
    const cancelPrefixHandler = () => {
        // {ID , reason, token}
        setReasonModalOpen(true);
        setReasonType("cancel");
    }
    const transferPrefixHandler = () => {
        // {id , company_id, token, startDate, endDate}
        setTransferModalOpen(true);
    }
    const activatePrefixHandler = () => {
        // {token} , prefix_id
        activatePrefix({token: token}, {token: token}, details.ID).then(result => {
            if(result.code == 1) {
                if(result.response != undefined) {
                    let comp = <Grid><Typography>Activated prefix nr {result.response.ID} for GLN {result.response.BrandOwnerGLN}</Typography></Grid>
                    setModalComponent(comp);
                    setModalTitle("Prefix Activated Successfully");
                }
            }
        });
    }
    const createPrefixHandler = () => {
        // localDetails
        createPrefix(localDetails).then(result => {
            if(result.code == 1) {
                if(result.response != undefined) {
                    let comp = <Grid><Typography>Created prefix for {result.companyName}</Typography></Grid>
                    setModalComponent(comp);
                    setModalTitle("Prefix Created Successfully");
                }
            } else {
                if(result.response != undefined) {
                    error(result.response)
                }
            }
        });
    }
    const updatePrefixHandler = () => {
        updatePrefix(localDetails).then(result => {
            if(result.code == 1) {
                if(result.response != undefined) {
                    let comp = <Grid><Typography>Updated prefix nr {result.response.ID} for GLN {result.response.BrandOwnerGLN}</Typography></Grid>
                    setModalComponent(comp);
                    setModalTitle("Prefix Updated Successfully");
                }
            }
        });
    }

    const storeLocalSave = (() => {
        setLocalDetails({
            PrefixTypeNUM: PrefixTypeNUM,
            searchtype: Searchtype,
            Name: Name,
            PrefixStart: PrefixStart,
            PrefixStop: PrefixStop,
            PrefixQty: PrefixQty,
            StartDate: StartDate,
            EndDate: EndDate,
            ID: details.ID, //string
            company_id: BrandOwnerID,
            id: details.ID, //int
            selected_supplier: [],
            token: token, 
            user_type: user.user_type 
        });
    })

    const storeLocalCreate = (() => {
        setLocalDetails({
            company_id: BrandOwnerID,
            Name: Name,
            PrefixTypeNUM: PrefixTypeNUM,
            PrefixStart: PrefixStart,
            PrefixStop: PrefixStop,
            PrefixQty: PrefixQty,
            token: token,
            user_type: user.user_type,
            searchtype: Searchtype,
            supplierselected: []
        })
    })

    const showNewButtons = () => {
        return  <Grid item container direction="row">
                    <Grid item container xs={6} columnSpacing={2}>
                        <Grid item><Button variant="orange-secondary" style={inputText} onClick={close}>Close</Button></Grid>
                    </Grid>
                    <Grid item container xs={6} justifyContent={"flex-end"}>
                        <Grid item><Button variant="orange" style={inputText} onClick={storeLocalCreate}>Save</Button></Grid>
                    </Grid>
                </Grid>
    }

    const showEditActiveFragment = () => {
        return  <Grid item container direction="row">
                    <Grid item container xs={6} columnSpacing={2}>
                        <Grid item><Button variant="orange-secondary-nosize" style={inputText} onClick={suspendPrefixHandler}>Suspend Prefix</Button></Grid>
                        <Grid item><Button variant="orange-secondary-nosize" style={inputText} onClick={cancelPrefixHandler}>Cancel Prefix</Button></Grid>
                    </Grid>
                    <Grid item container xs={6} justifyContent={"flex-end"}>
                        <Grid item><Button variant="orange" style={inputText} onClick={storeLocalSave}>Save Prefix</Button></Grid>
                    </Grid>
                </Grid>
    }

    let reasonStyle = {
        color: 'white',
        backgroundColor: '#E16C43',
        padding: '5px',
        borderRadius: '3px',
        fontWeight: '700'
    }

    const showEditCancelledFragment = () => {
        return  <Grid item container direction="column" rowSpacing={1}>
                    <Grid item container xs={6} direction="row" style={reasonStyle}>
                        <Grid item><Typography>Reason for cancellation:</Typography></Grid>
                        <Grid item><Typography>{details.remarks}</Typography></Grid>
                    </Grid>
                    {/* <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Previous Owner :</Typography></Grid>
                        <Grid item><Typography>{details.remarks}</Typography></Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Last Transferred At :</Typography></Grid>
                        <Grid item><Typography>{details.last_transferred_at}</Typography></Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Transferred By :</Typography></Grid>
                        <Grid item><Typography>{details.last_transferred_by}</Typography></Grid>
                    </Grid> */}
                    <Grid item container xs={12} direction="row" justifyContent={"flex-end"}>
                        <Grid item><Button variant="orange" style={inputText} onClick={activatePrefixHandler}>Activate Prefix</Button></Grid>
                    </Grid>
                </Grid> 
    }

    const showEditTransferedFragment = () => {
        return  <Grid item container direction="column" rowSpacing={1}>
                    <Grid item container xs={6} direction="row" style={reasonStyle}>
                        <Grid item><Typography>Reason for transfer:</Typography></Grid>
                        <Grid item><Typography>{details.remarks}</Typography></Grid>
                    </Grid>
                    {/* <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Previous Owner :</Typography></Grid>
                        <Grid item><Typography>{details.remarks}</Typography></Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Last Transferred At :</Typography></Grid>
                        <Grid item><Typography>{details.last_transferred_at}</Typography></Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Transferred By :</Typography></Grid>
                        <Grid item><Typography>{details.last_transferred_by}</Typography></Grid>
                    </Grid> */}
                    <Grid item container xs={12} direction="row" justifyContent={"flex-end"}>
                        <Grid item><Button variant="orange" style={inputText} onClick={activatePrefixHandler}>Activate Prefix</Button></Grid>
                    </Grid>
                </Grid> 
    }

    const showEditReplaceFragment = () => {
        return  <Grid item container direction="column" rowSpacing={1}>
                    <Grid item container xs={6} direction="row" style={reasonStyle}>
                        <Grid item><Typography>Reason for replace:</Typography></Grid>
                        <Grid item><Typography>{details.remarks}</Typography></Grid>
                    </Grid>
                    {/* <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Previous Owner :</Typography></Grid>
                        <Grid item><Typography>{details.remarks}</Typography></Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Last Transferred At :</Typography></Grid>
                        <Grid item><Typography>{details.last_transferred_at}</Typography></Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Transferred By :</Typography></Grid>
                        <Grid item><Typography>{details.last_transferred_by}</Typography></Grid>
                    </Grid> */}
                    <Grid item container xs={12} direction="row" justifyContent={"flex-end"}>
                        <Grid item><Button variant="orange" style={inputText} onClick={activatePrefixHandler}>Activate Prefix</Button></Grid>
                    </Grid>
                </Grid> 
    }

    const showEditSuspendedFragment = () => {
        return  <Grid item container direction="column" rowSpacing={1}>
                    <Grid item container xs={6} direction="row" style={reasonStyle}>
                        <Grid item><Typography>Reason for suspension:</Typography></Grid>
                        <Grid item><Typography>{details.remarks}</Typography></Grid>
                    </Grid>
                    {/* <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Previous Owner :</Typography></Grid>
                        <Grid item><Typography>{details.remarks}</Typography></Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Last Transferred At :</Typography></Grid>
                        <Grid item><Typography>{details.last_transferred_at}</Typography></Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row">
                        <Grid item><Typography>Transferred By :</Typography></Grid>
                        <Grid item><Typography>{details.last_transferred_by}</Typography></Grid>
                    </Grid> */}
                    <Grid item container xs={12} direction="row" justifyContent={"flex-end"}>
                        <Grid item><Button variant="orange" style={inputText} onClick={activatePrefixHandler}>Activate Prefix</Button></Grid>
                    </Grid>
                </Grid> 
    }

    const showEditFragment = () => {
        let fragment = "";
        if(details !== undefined) {
            switch(details.statusName.toLowerCase()) {
                case STATUS_ACTIVE:
                    fragment = showEditActiveFragment();
                    break;
                case STATUS_SUSPENDED:
                    fragment = showEditSuspendedFragment();
                    break;
                case STATUS_CANCELLED:
                    fragment = showEditCancelledFragment();
                    break;
                case STATUS_DELETED:
                    fragment = showEditCancelledFragment();
                    break;
                case STATUS_REPLACED:
                    fragment = showEditReplaceFragment();
                    break;
                case STATUS_TRANSFERED:
                    fragment = showEditTransferedFragment();
                    break;
                case STATUS_TRANSFERED_EXPIRED:
                    fragment = showEditTransferedFragment();
                    break;
                
                default:
                    fragment = "";
                    break;
            }
        }
        
        return fragment;
    }

    const typeData = {0 : {label: "New Prefix", fragment: showNewButtons}, 1 : {label: "Edit Prefix", fragment: showEditFragment}}

    useEffect(() => {
        
        if(details !== undefined) {
            setBrandOwnerID(details.BrandOwnerID)
            setPrefixTypeNUM(details.PrefixTypeNUM)
            setSearchtype(details.searchtype)
            setName(details.Name)
            setPrefixStart(details.PrefixStart)
            setPrefixStop(details.PrefixStop)
            setPrefixQty(details.PrefixQty)
            setStartDate(details.StartDate)
            setEndDate(details.EndDate)
        } else {
            setBrandOwnerID(undefined);
            setPrefixTypeNUM(8);
            setSearchtype(12);
            setName("");
            setPrefixStart(0);
            setPrefixStop(0);
            setPrefixQty(1);
            setStartDate("");
            setEndDate("");
        }
    }, [details,companies])

    useEffect(() => {
        if(localDetails !== undefined) {
            if(modalType === 1) {
                // Update
                updatePrefixHandler();
            } else {
                // create
                createPrefixHandler();
            }
            setLocalDetails(undefined);
        }
    }, [localDetails])

    useEffect(() => {
        if(modalComponent !== undefined){
            setModalOpen(true);
        }
    }, [modalComponent])

    useEffect(() => {
        if(reason !== undefined) {
            if(reasonType === "suspend") {
                suspendPrefix({ID: details.ID, reason: reason, token: token}).then(result => {
                    if(result.code == 1) {
                        if(result.response != undefined) {
                            let comp = <Grid><Typography>Suspended prefix nr {result.response.ID} for GLN {result.response.BrandOwnerGLN}</Typography></Grid>
                            setModalComponent(comp);
                            setModalTitle("Prefix Suspended Successfully");
                        }
                    }
                });
            }
            if(reasonType === "cancel") {
                cancelPrefix({ID: details.ID, reason: reason, token: token}).then(result => {
                    if(result.code == 1) {
                        if(result.response != undefined) {
                            let comp = <Grid><Typography>Cancelled prefix nr {result.response.ID} for GLN {result.response.BrandOwnerGLN}</Typography></Grid>
                            setModalComponent(comp);
                            setModalTitle("Prefix Cancel Successfully");
                        }
                    }
                });
            }
            setReasonType(undefined);
            setReason(undefined);
        }
    }, [reason])


    useEffect(() => {
        if(transferData !== undefined) {          
            transferPrefix(transferData).then(result => {
                if(result.code == 1) {
                    if(result.response != undefined) {
                        let comp = <Grid><Typography>Transferred prefix nr {result.response.ID} for GLN {result.response.BrandOwnerGLN}</Typography></Grid>
                        setModalComponent(comp);
                        setModalTitle("Prefix Transferred Successfully");
                    }
                }
            });
            
            setTransferData(undefined);
        }
    }, [transferData])

    const DateValue = ((string) => {
        if(string === undefined){
            return null;
        } else {
            return dayjs(string, "DD/MM/YYYY")
        }   
    });

    const handleModalClose = () => {
        setModalOpen(false);
        setModalComponent(undefined);
        setModalTitle("");
        close();
    };

    const handleReasonModalCloseSuccess = (value) => {
        setReason(value)
        setReasonModalOpen(false);
    };

    const handleReasonModalCloseFail = (value) => {
        setReasonModalOpen(false);
    };

    const handleTransferModalCloseSuccess = (supplier, startDate, endDate) => {
        setTransferData({id: details.ID , company_id: supplier, token: token, startDate: startDate, endDate: endDate})
        setTransferModalOpen(false);
    };

    const handleTransferModalCloseFail = (supplier, startDate, endDate) => {
        setTransferModalOpen(false);
    };

    const typeOnChange = (e) => {
        setPrefixTypeNUM(e)
            
        // If type = 8, set single digit
        if (e === 8) {
            let digit = 12;
            setSearchtype(digit)
            setIsSearchTypeDisabled(true);
        } else {
            setIsSearchTypeDisabled(false);
        }
    }

    const digitOnChange = (e) => {
        setName('')
        if(e === '') {
            setPrefixStart(0)
            setPrefixStop(0)
            setPrefixQty(0)
        } else {
            setName('')
            setSearchtype(e)
    
            let gtinType = Number(Searchtype);
            let prefixLength = e;
            
            let diffVal = gtinType-prefixLength;
            //let prefixName = this.state.initialPrfix == '' ? '' : this.state.prefixName;
            let prefixStart = paddy(Name, diffVal);
            let prefixEnd = paddy(Name, diffVal,9);
    
            let PrefixQty = ((Number(prefixEnd)-Number(prefixStart))+ 1) / 10 ;
            if (PrefixQty < 1) { PrefixQty = 1}
    
            
            if(!isNaN(prefixStart) && !isNaN(prefixEnd) ){
                setPrefixStart(prefixStart)
                setPrefixStop(prefixEnd)
                setPrefixQty(PrefixQty)
            }

        }
        
        
    
    }

    const paddy = (num, padlen, padchar) => {
        if (padlen < 0) { padlen = 0};
        var pad_char = typeof padchar !== 'undefined' ? padchar : '0';
        var pad = new Array(1 + padlen).join(pad_char);
        return (num+pad);
    }

    const nameOnChange = (e) => {

        var prefixNameValue = e.trim();
        var inputLength = Math.min(parseInt(PrefixTypeNUM), parseInt(Searchtype));
        
        if(!isNaN(Number(prefixNameValue))){
            if(e.length <= inputLength){
                
                // Get the gtin type (length)
                let gtinType = Number(PrefixTypeNUM);

                // Prefix part of the gtin
                let prefixLength = Number(Searchtype);

                // Get the difference (variable part of the prefix)
                let diffVal = gtinType - prefixLength;
                console.log(diffVal);
                if (diffVal < 0) { diffVal = 0; }

                // Obtain the prefix content via input
                let prefixName = e;

                // Calculate start and end
                let prefixStart = paddy(prefixName, diffVal);
                let prefixEnd   = paddy(prefixName, diffVal, 9);

                // Calculate the quantity of variations
                let PrefixQty = ((Number(prefixEnd)-Number(prefixStart)) +1) / 10;
                if (PrefixQty < 1) { PrefixQty = 1}

                // Store all
                setPrefixStart(prefixStart)
                setPrefixStop(prefixEnd)
                setPrefixQty(PrefixQty)
                setName(prefixName)
            }
        }

    }
    let inputText = {fontWeight: "100"};  

    return  <Grid container direction="column" rowSpacing={2}>
                <Grid item container direction="row" xs={12}>
                    <Grid item xs={6}>
                        <Typography variant={"mainTitleEnrichments"}>{modalType !== undefined ? typeData[modalType].label : ""}</Typography>
                    </Grid>
                    {modalType === 1 ? 
                        <Grid item container xs={6} justifyContent={"flex-end"}>
                            <Grid item><Button variant="orange" style={inputText} onClick={transferPrefixHandler}>Transfer</Button></Grid>
                        </Grid>
                    :
                        ""
                    }
                </Grid>
                <Grid item container direction="row">
                    <FormControl fullWidth>
                        <InputLabel id="brand-owner-select-label">Select Company</InputLabel>
                        <Select
                            id="brand-owner-basic"
                            labelId="brand-owner-select-label"
                            label="Brand Owner"
                            value={BrandOwnerID}
                            onChange={(e) => {setBrandOwnerID(e.target.value)}}
                        >
                            {companies.map((company) => (
                                <MenuItem value={company.id}>{company.CompanyName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item container direction="row" columnSpacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="type-basic-select-label">Type</InputLabel>      
                            <Select
                                id="type-basic"
                                labelId="type-basic-select-label"
                                label="Type"
                                value={PrefixTypeNUM}
                                onChange={(e) => {typeOnChange(e.target.value)}}
                            >
                                {types.map((t) => (
                                    <MenuItem value={t.id}>{t.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>      
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth disabled={isSearchTypeDisabled}>
                            <InputLabel id="digit-basic-select-label">Digits</InputLabel>  
                            <Select
                                id="digit-basic"
                                labelId="digit-basic-select-label"
                                label="Digits"
                                value={Searchtype}
                                onChange={(e) => {digitOnChange(e.target.value)}}

                            >
                                {digits.map((d) => (
                                    <MenuItem value={d.id}>{d.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container direction="row" columnSpacing={2}>
                    <Grid item xs={12}>
                        <TextField 
                            label="Prefix"
                            id="prefix-basic" 
                            variant="outlined" 
                            style={inputText}
                            value={Name}
                            onChange={(e) => {nameOnChange(e.target.value)}}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                    </Grid>
                </Grid>
                <Grid item container direction="row" columnSpacing={2}>
                    <Grid item xs={4}>
                        <TextField 
                            label="Start"
                            id="Start-basic" 
                            variant="outlined" 
                            style={inputText}
                            value={PrefixStart}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField 
                            label="Stop"
                            id="Stop-basic" 
                            variant="outlined" 
                            style={inputText}
                            value={PrefixStop}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField 
                            label="Qty"
                            id="Qty-basic" 
                            variant="outlined" 
                            style={inputText}
                            value={PrefixQty}
                        />
                    </Grid>
                </Grid>
                <Grid item container direction="row" columnSpacing={2}>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Start Date"
                                value={DateValue(StartDate)}
                                onChange={(newValue) => {
                                    setStartDate(newValue !== null ? newValue.format("DD/MM/YYYY") : "");
                                }}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="End Date"
                                value={DateValue(EndDate)}
                                onChange={(newValue) => {
                                    setEndDate(newValue !== null ? newValue.format("DD/MM/YYYY") : "");
                                }}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                {modalType !== undefined ? typeData[modalType].fragment() : ""}
                <SimpleDialog open={modalOpen} title={modalTitle} component={modalComponent} onClose={handleModalClose} />
                <ReasonDialog open={reasonModalOpen} onCloseSuccess={handleReasonModalCloseSuccess} onCloseFail={handleReasonModalCloseFail} />
                <TransferDialog open={transferModalOpen} onCloseSuccess={handleTransferModalCloseSuccess} onCloseFail={handleTransferModalCloseFail}  suppliers={companies} />
            </Grid>  
                
}