import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Typography, TextField, FormControlLabel, Switch, Select, MenuItem, InputLabel, FormControl, InputAdornment } from '@mui/material';
import Header from "../../../Header/Header";
import useToken from '../../../App/useToken';
import toolsManagementApi from "../../../../tools/toolsManagementApi";
import { SimpleDialog } from "../../../Dialog/SimpleDialog";
import { useNavigate } from "react-router-dom";
import UploadProfileImg from "../../../UploadProfileImg/UploadProfileImg";
import defaultImg from "../../../../img/defaultCompany.jpg"

export default function CompanyNew() {
    const { token } = useToken();
    const navigate = useNavigate();
    const { getCountries, createCompany } = toolsManagementApi();
    const [countryOptions, setCountryOptions] = useState([]);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorModalTitle, setErrorModalTitle] = useState(undefined);
    const [errorModalComponent, setErrorModalComponent] = useState(undefined);
    const [updateImg, setUpdateImg] = useState(false);
    const [company, setCompany] = useState({
        token: token,
        CompanyLogo: undefined,
        CompanyLogoFile: undefined,
        CompanyName: undefined,
        CompanyGLN: undefined,
        enrichmentPermissions: false,
        CompanyType: undefined,
        ParentCompany: undefined,
        BatchUserId: undefined,
        WebServiceGLN: undefined,
        WebServiceKey: undefined,
        accountNumber: undefined,

        AddressLine1: undefined,
        AddressLine2: undefined,
        CityName: undefined,
        PostalCode: undefined,
        Country: undefined,
        IsCompliant: undefined,
        CompliantPercentage: undefined,
    });

    const mandatoryFields = [
        "CompanyName",
        "CompanyGLN",
        "CompanyType",
        "accountNumber",
    ]

    const companyTypeOptions = [
        { label: 'Supplier/Manufacturer', value: '1' },
        { label: 'Data Recipient', value: '7' },
        { label: 'Data Controller', value: '8' },
        { label: 'Third Party', value: '9' },
    ]

    const companyParentOptions = [
        { label: 'GS1 Retailers', value: '1' },
        { label: 'GS1 Suppliers', value: '2' },
        { label: 'GS1 South Africa', value: '3' },
    ]

    useEffect(() => {
        getCountries({token: token}).then(result => {
            if(result.code === 1) {
                if(result.response != undefined) {
                    setCountryOptions(result.response)
                }
            }
        });
    }, [])

    useEffect(()=> {
        if(errorModalComponent !== undefined && errorModalTitle !== undefined){
            setErrorModalOpen(true);
        }
        
    }, [errorModalComponent, errorModalTitle])

    useEffect(() => {
        if(updateImg) {
            setUpdateImg(false);
            if(company.CompanyLogoFile !== undefined) {
                getBase64(company.CompanyLogoFile).then(result => {
                    setCompanyKeyValue("CompanyLogo", result)
                })
            } else {
                setCompanyKeyValue("CompanyLogo", undefined)
            }
        }
    }, [company, updateImg])

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
          };
          console.log(fileInfo);
        });
      };

    const setCompanyImageFile = (value) => {
        setCompanyKeyValue("CompanyLogoFile", value);
        setUpdateImg(true);
    }

    const setCompanyKeyValue = (key, value) => {
        let copyOfObject = { ...company };
        copyOfObject[key] = value;
        setCompany(copyOfObject);
    };

    const ImageThumb = ({ image }) => {
        if(image !== undefined) {
          return <img src={URL.createObjectURL(image)} alt={image.name} width={"80px"} height={"80px"}/>;
        }
        return "";
      };

    const addNewCompany = () => {
        if(checkMandatoryFields() === true) {
            createCompany(company).then(result => {
                if(result.code === 1) {
                    setErrorModalTitle("Success");
                    setErrorModalComponent(<Typography>{"Company Created Successfully"}</Typography>);
                    if(result.response != undefined) {
                        console.log(result.response)
                    }
                } else {
                    setErrorModalTitle("Server Error");
                    setErrorModalComponent(<Typography>{result.response}</Typography>);
                }
            });
        } else {
            setErrorModalTitle("Mandatory fields needed");
            setErrorModalComponent(<Typography>Please, fill all the mandatory fields</Typography>);
        } 
    }

    const cancelNewCompany = (() => {
        navigate("/company");
    })

    const checkMandatoryFields = (() => {
        let correct = true;
        mandatoryFields.map((field) => {
            if(company[field] === undefined) {
                correct =  false
            }
        })
        console.log(correct);
        return correct;
    })

    const handleCloseErrorModal = (() => {
        setErrorModalOpen(false);
        setErrorModalTitle(undefined);
        setErrorModalComponent(undefined);
    })

    let bodyWrapperStyle = {width:"100%", height:"80%", };
    let inputText = {fontWeight: "100", width:"100%"};

    return  <Grid height="100%">
                <Header name={"Insert Company"}/>
                <Grid container direction="column" style={bodyWrapperStyle}>
                    <Box style={{width: "100%", height: "100%", overflow: "auto", paddingTop: "25px", paddingBottom: "25px"}}>
                        <Grid container direction="column" xs={12} rowSpacing={4} justifyContent="center" alignItems="center">
                            <Grid container item xs={8} style={{maxWidth: "800px"}} rowSpacing={1} justifyContent="center" alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="productTitle">Company Details</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <UploadProfileImg value={company.CompanyLogoFile} setValue={setCompanyImageFile} defaultImage={defaultImg} />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        required
                                        label="Company Name"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.CompanyName}
                                        onChange={(e) => {setCompanyKeyValue("CompanyName", e.target.value)}}
                                    />        
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        required
                                        type="number"
                                        label="Company GLN"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.CompanyGLN}
                                        onChange={(e) => {setCompanyKeyValue("CompanyGLN", e.target.value)}}
                                    />                            
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControlLabel control={<Switch style={{color:"primary"}} value={company.enrichmentPermissions} onChange={(e) => {setCompanyKeyValue("enrichmentPermissions", e.target.checked)}}/>} label="Enrichment permissions" />
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl fullWidth>
                                        <InputLabel id="company-type-select-label">Select Company Type</InputLabel>
                                        <Select
                                            required
                                            id={"company-type-basic"}
                                            labelId="company-type-select-label"
                                            fullWidth
                                            value={company.CompanyType}
                                            label="Select Company Type"
                                            onChange={(e) => {setCompanyKeyValue("CompanyType", e.target.value)}}
                                        >
                                            {companyTypeOptions.map((op) => (
                                                <MenuItem value={op.value}>{op.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl fullWidth>
                                        <InputLabel id="company-parent-select-label">Select Company Parent</InputLabel>
                                        <Select
                                            id={"company-parent-basic"}
                                            labelId="company-parent-select-label"
                                            label="Select Company Parent"
                                            fullWidth
                                            value={company.ParentCompany}
                                            onChange={(e) => {setCompanyKeyValue("ParentCompany", e.target.value)}}
                                        >
                                            {companyParentOptions.map((op) => (
                                                <MenuItem value={op.value}>{op.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        label="Batch User Id"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.BatchUserId}
                                        onChange={(e) => {setCompanyKeyValue("BatchUserId", e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        label="Web Service GLN3"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.WebServiceGLN}
                                        onChange={(e) => {setCompanyKeyValue("WebServiceGLN", e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        label="Web Service Key"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.WebServiceKey}
                                        onChange={(e) => {setCompanyKeyValue("WebServiceKey", e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        required
                                        type="number"
                                        label="Account number"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.accountNumber}
                                        onChange={(e) => {setCompanyKeyValue("accountNumber", e.target.value)}}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={8} style={{maxWidth: "800px"}} rowSpacing={1} justifyContent="center" alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="productTitle">Physical Address</Typography> 
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        label="Address 1"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.AddressLine1}
                                        onChange={(e) => {setCompanyKeyValue("AddressLine1", e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        label="Address 2"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.AddressLine2}
                                        onChange={(e) => {setCompanyKeyValue("AddressLine2", e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        label="City Name"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.CityName}
                                        onChange={(e) => {setCompanyKeyValue("CityName", e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        label="Postal Code"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.PostalCode}
                                        onChange={(e) => {setCompanyKeyValue("PostalCode", e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl fullWidth>
                                        <InputLabel id="country-select-label">Select Country</InputLabel>
                                        <Select
                                            id={"country-basic"}
                                            labelId="country-select-label"
                                            fullWidth
                                            value={company.Country}
                                            label="Select Country"
                                            onChange={(e) => {setCompanyKeyValue("Country", e.target.value)}}
                                        >
                                            {countryOptions.map((op) => (
                                                <MenuItem value={op.country_code.trim()}>{op.country_name.trim()}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControlLabel control={<Switch style={{color:"primary"}} value={company.IsCompliant} onChange={(e) => {setCompanyKeyValue("IsCompliant", e.target.checked)}}/>} label="BEE Status Compliant" />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        type={"number"}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                          }}
                                        label="% Compliant"
                                        id={"all-basic"}
                                        variant="outlined" 
                                        style={inputText}
                                        value={company.CompliantPercentage}
                                        onChange={(e) => {setCompanyKeyValue("CompliantPercentage", e.target.value)}}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" columnSpacing={2} xs={8} style={{maxWidth: "800px"}} justifyContent="center" alignItems="center">
                                <Grid item xs={4}>
                                    <Button variant="orange-secondary" style={inputText} onClick={cancelNewCompany}>Cancel</Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button variant="orange" style={inputText} onClick={addNewCompany}>Create</Button>
                                </Grid>
                            </Grid>
                        </Grid>    
                    </Box>
                </Grid>
                <SimpleDialog 
                    open={errorModalOpen} 
                    onClose={handleCloseErrorModal} 
                    title={errorModalTitle} 
                    component={errorModalComponent} 
                />
            </Grid>
}