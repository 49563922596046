import React, { useState, useEffect } from "react";
import { Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

export default function QcFilters({qcFilters, setSupplierFilter}) {
	const getBySupplier = (ipgln) => {
        setSupplierFilter([{columnField: "InformationProviderName", operatorValue: "equals", value: ipgln}])
    };

    let qcFilterName = {fontSize: '14px', textAlign: 'left', color: '#4D4D4D', fontWeight: '400'};
    let qcFilterCount = {color: '#1EB1E0', fontSize: '16px', textAlign: 'right', fontWeight: '400'};
    let qcFiltersLink = {"&": {fontSize: '13px', fontFamily: 'Proxima Nova', color: '#203040', fontWeight: 700, paddingLeft: '25px!important'}, "&:hover": {background: '#1EB1E0', borderRight: '2px solid #21b2e2', padding: '7.5px'} };
    let noFilterFound = {fontSize: '13px', width:'90%', textAlign: 'left'};
    //sx={{padding: '7.5px 25px 7.5px 25px'}}
	return 	<List>
	            {qcFilters !== undefined && Object.keys(qcFilters).length > 0 ?
	                Object.entries(qcFilters).map(([value, key]) =>  
                    	<ListItem key={value} sx={{padding: '0'}}>
                    		<ListItemButton onClick={() => getBySupplier(value)} disableGutters sx={{padding: '7.5px 25px 7.5px 25px', "&": {fontSize: '13px', fontFamily: 'Proxima Nova', color: '#203040', fontWeight: 700}, "&:hover": {background: 'rgba(30, 177, 224, 0.1)'}}}>
                    			<Grid container>
                    				<Grid item xs={10} style={qcFilterName}>{value}</Grid>
                    				<Grid item xs={2} style={qcFilterCount}>{key}</Grid>
                    			</Grid>
                    			
                    		</ListItemButton>
                    	</ListItem>
	                )
	            :<ListItem style={noFilterFound}>No filters found</ListItem>}
	        </List>;
}