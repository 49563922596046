import React, { useState, useEffect } from "react";
import ToolsApi from './ToolsApi';
import config from "../config/config"

export default function toolsMessagesBoardApi() {
    const { post, deleteCall, put } = ToolsApi();

    const createMsg = async (body) => {
        let url = config.API_URL + "/api/admin/message-board";
        
        let query = undefined
        return post(url, query, body);
    };

    const updateMsg = async (body, msgId) => {
        let url = config.API_URL + "/api/admin/message-board/" + msgId;
        
        let query = undefined
        return put(url, query, body);
    };

    const deleteMsg = async (query, msgId) => {
        let url = config.API_URL + "/api/admin/message-board/" + msgId;
        
        let body = undefined
        return deleteCall(url, query, body);
    };

    return {
        createMsg,
        updateMsg,
        deleteMsg
    }
}