import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Typography, TextField,IconButton, OutlinedInput, FormControlLabel, Switch, Select, MenuItem, InputLabel, FormControl, InputAdornment, Tooltip } from '@mui/material';
import {useParams} from "react-router-dom";
import { SimpleDialog } from "../../../Dialog/SimpleDialog";
import Header from "../../../Header/Header";
import useToken from '../../../App/useToken';
import toolsManagementApi from "../../../../tools/toolsManagementApi";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { useNavigate } from "react-router-dom";
import useUser from "../../../App/useUser";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import defaultImg from "../../../../img/defaultProfile.jpg"
import UploadProfileImg from "../../../UploadProfileImg/UploadProfileImg";
import UserPassword from "./UserPassword";
import { options } from "../../../../permission/selectOptions";
import usrBack from "../../../../img/userbackground.png"; 
import ProfileImg from "../../../ProfileImg/ProfileImg";

const ENTITY_NAME = "User"

export default function UserNew() {
    const { company } = useParams();
    const { token } = useToken();
    const {user} = useUser();
    const navigate = useNavigate();
    const { createUser, getCompanyListFull } = toolsManagementApi();
    const [phoneValidationError, setPhoneValidationError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [userTypeOptions, setUserTypeOptions] = useState([]);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorModalTitle, setErrorModalTitle] = useState(undefined);
    const [errorModalComponent, setErrorModalComponent] = useState(undefined);
    const [updateImg, setUpdateImg] = useState(false);
    const [newUserId, setNewUserId] = useState(undefined);
    const [newUser, setNewUser] = useState({
        token: token,
        created_by: user.id,
        isDataController: false,
        created_role: user.user_type,
        //Account Info
        user_company: company,
        user_type: undefined,
        userLanguage: "EN",
        apiUser: false,
        user_name: undefined,
        password: undefined,
        //Personal Info
        user_image: undefined,
        user_image_file: undefined,
        first_name: undefined,
        last_name: undefined,
        email: undefined,
        user_telephone: undefined,
        
    });

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(baseURL);
          };
        });
      };

    const setUserImageFile = (value) => {
        setUserKeyValue("user_image_file", value);
        setUpdateImg(true);
    }

    useEffect(() => {
        if(updateImg) {
            setUpdateImg(false);
            if(newUser.user_image_file !== undefined){
                getBase64(newUser.user_image_file).then(result => {
                    setUserKeyValue("user_image", result)
                    
                })
            } else {
                setUserKeyValue("user_image", undefined)
            }
        }
    }, [newUser, updateImg])

    useEffect(() => {
        if(user !== undefined) {
            let tempOptions = options[user.user_type][ENTITY_NAME]["user_type"];
            setUserTypeOptions(tempOptions);
        }
    }, [user])

    useEffect(() => {
        console.log("ENTRA")
        console.log(company)
        if(!errorModalOpen && newUserId !== undefined) {
            
            if(company !== "" && company !== undefined) {
                navigate("/editcompany/" + company)
            } else {
                navigate("/edituser/" + newUserId)
            }
        }
    }, [errorModalOpen])

    const availableLanguages = [
        {iso: "EN", label: "English"},
    ];

    const mandatoryFields = [
        "user_company",
        "user_type",
        "apiUser",
        "user_name",
        "password",
        "first_name",
        "last_name",
        "email",
    ]

    const cancelNewUser = () => {
        navigate("/usermanagement");
    }

    const handleCloseErrorModal = (() => {
        setErrorModalOpen(false);
        setErrorModalTitle(undefined);
        setErrorModalComponent(undefined);
    })

    const setUserKeyValue = (key, value) => {
        let copyOfObject = { ...newUser };
        copyOfObject[key] = value;
        setNewUser(copyOfObject);
    };

    useEffect(() => {
        getCompanyListFull({token: token, searchTerms: "0"}).then(result => {
            if(result.code === 1) {
                if(result.response != undefined) {
                    setCompanyOptions(result.response)
                    
                }
            }
        }).catch((error) => {
            setCompanyOptions([{id: user.company_id, CompanyGLN : user.InformationProviderGLN, CompanyName : user.companyName}])
        });
    }, [])

    useEffect(()=> {
        if(errorModalComponent !== undefined && errorModalTitle !== undefined){
            setErrorModalOpen(true);
        }
    }, [errorModalComponent, errorModalTitle])

    let bodyWrapperStyle = {width:"100%", height:"80%", paddingTop: "100px"};
    let inputText = {fontWeight: "100", width:"100%"};

    const handlePhoneChange = (value, info) => {
        setUserKeyValue("user_telephone", value)
    }

    const addNewUser = () => {
        if(checkMandatoryFields()) {
            if(validateFields()) {
                createUser(newUser).then(result => {
                    if(result.code === 1) {
                        setErrorModalTitle("Success");
                        setErrorModalComponent(<Typography>{"User Created Successfully"}</Typography>);
                        if(result.response != undefined) {
                            setNewUserId(result.response.id)
                        }
                    } else {
                        setErrorModalTitle("Server Error");
                        setErrorModalComponent(<Typography>{result.response}</Typography>);
                    }
                });
            } else {
                setErrorModalTitle("Validation Fields gone wrong");
                setErrorModalComponent(<Typography>Please, check the validated fields.</Typography>);
            }
        } else {
            setErrorModalTitle("Mandatory fields needed");
            setErrorModalComponent(<Typography>Please, fill all the mandatory fields</Typography>);
        } 
    }

    const validateFields = (() => {
        let validFields = true;

        if(newUser.user_telephone !== undefined && newUser.user_telephone !== "") {
            validFields = validFields & matchIsValidTel(newUser.user_telephone)
            if(!matchIsValidTel(newUser.user_telephone)) {
                setPhoneValidationError(true);
            }
        }

        return validFields
    })

    const checkMandatoryFields = (() => {
        let correct = true;
        mandatoryFields.map((field) => {
            if(newUser[field] === undefined) {
                correct =  false
            }
        })
        return correct;
    })

    const renderValueCompanyOptions = (value) => {
        const selectedCompany = companyOptions.find(({ id }) => id == value)
        return  selectedCompany !== undefined ? selectedCompany.CompanyName !== undefined ? selectedCompany.CompanyName : undefined : undefined;
    }

    let cancelBtn = {
        border: '1px solid #DF5E3F',
        borderRadius: '0px',
        minWidth: '124px',
        color: '#DF5E3F',
        textTransform: 'none'
    }

    let saveBtn = {
        border: '1px solid #1EB1E0',
        borderRadius: '0px',
        minWidth: '124px',
        color: '#1EB1E0',
        textTransform: 'none'
    }

    let userBoxStyle = {
        backgroundImage: `url(${usrBack})`,
        backgroundRepeat:"no-repeat",
        backgroundSize:"contain" ,
        backgroundColor: "#FBFBFB",
        
    }
    const gridStyle = {
        background: "#FFFFFF",
        borderRadius:"10px", 
        maxWidth: "600px",
        padding: "25px 10px"
    }

    const inputSize = 11;

    return  <Grid height="100%"  sx={userBoxStyle}>
                <Header name={"Insert User"}/>
                <Grid container direction="column" style={bodyWrapperStyle}>
                    <Box style={{width: "100%", height: "100%", overflow: "auto", paddingTop: "25px", paddingBottom: "25px"}}>
                        <Grid container direction="column" xs={12} rowSpacing={4} justifyContent="center" alignItems="center">
                            <Grid container item direction="row" xs={12} justifyContent="center" alignItems="flex-start" >
                                <Grid item xs={0.5}></Grid>
                                <Grid container item xs={5} style={gridStyle} rowSpacing={2} justifyContent="center" alignItems="center">
                                    <Grid item xs={inputSize}>
                                        <Typography variant="userInfoTitle">Account Info</Typography>
                                    </Grid>
                                    <Grid item xs={inputSize}>
                                        <FormControl fullWidth>
                                            <InputLabel id="company-type-select-label">User Company</InputLabel>
                                            <Select
                                                required
                                                id={"company-type-basic"}
                                                labelId="company-type-select-label"
                                                fullWidth
                                                value={newUser.user_company}
                                                label="Select Company"
                                                onChange={(e) => {setUserKeyValue("user_company", e.target.value)}}
                                                renderValue={(value) => {return renderValueCompanyOptions(value)}}
                                            >
                                                {companyOptions.map((op) => (
                                                    <MenuItem value={op.id}>{op.CompanyName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>      
                                    </Grid>
                                    <Grid container item direction="row" columnSpacing={1} xs={inputSize}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="company-type-select-label">User Type</InputLabel>
                                                <Select
                                                    required
                                                    id={"user-type-basic"}
                                                    labelId="company-type-select-label"
                                                    fullWidth
                                                    value={newUser.user_type}
                                                    label="Select Type"
                                                    onChange={(e) => {setUserKeyValue("user_type", e.target.value)}}
                                                    renderValue={(selectedValue) => {return userTypeOptions !== undefined ? userTypeOptions.find(({ value }) => value == selectedValue).label : ''}}
                                                >
                                                    {userTypeOptions.map((type) => (
                                                        <MenuItem value={type.value}>{type.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="company-type-select-label">Language</InputLabel>
                                                <Select
                                                    required
                                                    id={"company-type-basic"}
                                                    labelId="company-type-select-label"
                                                    fullWidth
                                                    value={newUser.userLanguage}
                                                    label="Select Language"
                                                    onChange={(e) => {setUserKeyValue("userLanguage", e.target.value)}}
                                                    renderValue={(value) => {return availableLanguages.find(({ iso }) => iso == value).label}}
                                                >
                                                    {availableLanguages.map((op) => (
                                                        <MenuItem value={op.iso}>{op.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={inputSize}>
                                        <FormControl fullWidth>
                                            <InputLabel id="company-type-select-label">Api User</InputLabel>
                                            <Select
                                                required
                                                id={"company-type-basic"}
                                                labelId="company-type-select-label"
                                                fullWidth
                                                value={newUser.apiUser}
                                                label="Api User"
                                                onChange={(e) => {setUserKeyValue("apiUser", e.target.value)}}
                                                renderValue={(value) => {return value ? "Yes" : "No"}}
                                            >
                                                <MenuItem value={true}>Yes</MenuItem>
                                                <MenuItem value={false}>No</MenuItem>
                                            </Select>
                                        </FormControl>      
                                    </Grid>
                                    <Grid item xs={inputSize}>
                                        <TextField 
                                            required
                                            label="User Name"
                                            id={"all-basic"}
                                            variant="outlined" 
                                            style={inputText}
                                            value={newUser.user_name}
                                            onChange={(e) => {setUserKeyValue("user_name", e.target.value)}}
                                        />      
                                    </Grid>
                                    <Grid item xs={inputSize}>
                                        <UserPassword value={newUser.password} setUserPartial={setUserKeyValue} keyName="password"/>    
                                    </Grid>
                                </Grid> 
                                <Grid item xs={1}></Grid>   
                                <Grid container item xs={5} justifyContent="center" alignItems="center">
                                    <Grid container item xs={12} style={gridStyle} rowSpacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={inputSize}>
                                            <Typography variant="userInfoTitle">Personal Info</Typography> 
                                        </Grid>
                                        <Grid item xs={inputSize}>
                                            <ProfileImg imgUrl={newUser.user_image_file} size={"140px"} upload={true} cardSize={"150px"} setValue={setUserImageFile} />
                                        </Grid>
                                        <Grid container item direction="row" columnSpacing={1} xs={inputSize}>
                                            <Grid item xs={6}>
                                                <TextField 
                                                    required
                                                    label="First Name"
                                                    id={"all-basic"}
                                                    variant="outlined" 
                                                    style={inputText}
                                                    value={newUser.first_name}
                                                    onChange={(e) => {setUserKeyValue("first_name", e.target.value)}}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField 
                                                    required
                                                    label="Last Name"
                                                    id={"all-basic"}
                                                    variant="outlined" 
                                                    style={inputText}
                                                    value={newUser.last_name}
                                                    onChange={(e) => {setUserKeyValue("last_name", e.target.value)}}
                                                />
                                            </Grid>            
                                        </Grid>
                                        <Grid item xs={inputSize}>
                                            <TextField 
                                                required
                                                type={"email"}
                                                label="Email"
                                                id={"all-basic"}
                                                variant="outlined" 
                                                style={inputText}
                                                value={newUser.email}
                                                onChange={(e) => {setUserKeyValue("email", e.target.value)}}
                                            />
                                        </Grid>
                                        <Grid item xs={inputSize}>
                                            <MuiTelInput 
                                                helperText={phoneValidationError ? "Phone number not valid" : ""}
                                                error={phoneValidationError}
                                                preferredCountries={['ZA']}
                                                onlyCountries={['ZA']}
                                                fullWidth 
                                                label="Telephone" 
                                                value={newUser.user_telephone} 
                                                onChange={handlePhoneChange} 
                                            />
                                            
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ maxWidth: "600px", paddingTop: "10px"}} justifyContent="flex-end" alignItems="center">
                                        <Grid item style={{ marginRight: "10px"}}>
                                            <Button style={cancelBtn} onClick={cancelNewUser}>Cancel</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button style={saveBtn} onClick={addNewUser}>Save</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={0.5}></Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <SimpleDialog 
                    open={errorModalOpen} 
                    onClose={handleCloseErrorModal} 
                    title={errorModalTitle} 
                    component={errorModalComponent} 
                />
            </Grid>
}