import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Select, MenuItem, Button } from '@mui/material';
import toolsPrefixManagementApi from "../../tools/toolsPrefixManagementApi"
import { CatchingPokemonSharp } from "@mui/icons-material";

export default function PrefixManagementFilter({updateList, setUpdateList, filter, setOpenModal, setModalType, setPageNumber, page, setRows, setRowCount, rowCount, setLocalPage, setLoading, setPrefixType, setSearchPrefix}) {
    const { getPrefix } = toolsPrefixManagementApi();
    const handleOpen = (() => {
        setModalType(0)
    });
    const search = (() => {
        setLoading(true);
        setPageNumber(0);
        setLocalPage(0);
    });

    useEffect(() => {
        if(updateList) {
            setUpdateList(false);
            getPrefix(filter).then(result => {
                setLoading(false);
                if(result.code == 1) {
                    if(result.response != undefined) {
                        setRows(result.response.prefixdata);
                        setRowCount(result.response.total_prefix);
                    }
                }
            });
        }
    }, [updateList])

    useEffect(() => {
        if(page !== undefined && filter !== undefined) {
            if(filter.token !== "") {
                getPrefix(filter).then(result => {
                    setLoading(false);
                    if(result.code == 1) {
                        if(result.response != undefined) {
                            setRows(result.response.prefixdata);
                            setRowCount(result.response.total_prefix);
                        }
                    }
                });
            }
            setPageNumber(undefined);
        }
    }, [page, filter])

    // STYLES
    let filtersStyle = {height:"15%", padding: "25px", fontFamily: "Proxima Nova"};
    let gridCellStyle = {padding:"5px 5px", height: "50%"};
    let inputText = {fontWeight: "100", width:"100%"};    
    let inputStyle = { width: "100%" };
    let toggleStyle = {textTransform: "none", selected:{background: "primary"}};

    return  <Grid container direction="row" style={filtersStyle}>
                <Grid container direction="row" xs={9} spacing={3}>
                    <Grid item container direction="row" xs={2} spacing={3} alignItems="center" >
                        <Grid item xs={12}>
                            <Typography component="span">Prefixes </Typography><Typography variant="important">{rowCount}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item  container direction="row" xs={10} spacing={3} alignItems="center" justifyItems={"flex-start"}>
                        <Grid item xs={5}>
                            <TextField 
                                label="Search"
                                id={"all-basic"}
                                variant="outlined" 
                                style={inputText}
                                value={filter.search_prefix}
                                onChange={(e) => {setSearchPrefix(e.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Select
                                fullWidth
                                labelId="type-select-label"
                                id="type-sort-select"
                                value={filter.choose_prefix_filter_type}
                                onChange={(event) => setPrefixType(event.target.value)}
                            >
                                <MenuItem value={0}>All</MenuItem>
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={99}>Cancelled</MenuItem>
                                <MenuItem value={3}>Suspended</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="orange" style={inputText} onClick={search}>Search</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="row" xs={3} spacing={3} alignContent="center" justifyContent="flex-end">
                    <Grid item xs={4}>
                        <Button variant="orange-secondary" style={inputText} onClick={handleOpen}>Add Prefix</Button>
                    </Grid>
                </Grid>
            </Grid>
}