import ToolsApi from './ToolsApi';
import config from "../config/config"

export default function toolsUserApi() {
    const { post, get } = ToolsApi();

    const login = async (body) => {
        let url = config.API_URL + "/api/login";
        let query = undefined
        return post(url, query, body);
    };

    const recovery = async (body) => {
        let url = config.API_URL + "/api/rememberme";
        
        let query = undefined
        return post(url, query, body);
    };

    const resetPassword = async (body) => {
        let url = config.API_URL + "/api/resetrequest";
        let query = undefined
        return post(url, query, body)
    }

    return {
        login,
        recovery,
        resetPassword
    };
}