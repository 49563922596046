import React from "react";
import ReportUtils from './Utils.js';

const entities = [
    {name: 'User management', service: 'signed-user', path: 'signed-user', columns: [
            {type: 'text', field: 'creatorEmail', headerName: 'Admin email'},
            {type: 'text', field: 'creatorFirstName', headerName: 'Admin First name'},
            {type: 'text', field: 'creatorLastName', headerName: 'Admin Last name'},
            {type: 'select', field: 'action', headerName: 'Action', 
                options:[
                    {value: "UPDATE", label: "UPDATE"},
                    {value: "CREATE", label: "CREATE"},
                ]},
            {type: 'text', field: 'email', headerName: 'Email'}, 
            {type: 'text', field: 'first_name', headerName: 'First name'},
            {type: 'text', field: 'last_name', headerName: 'Last name'},
            {type: 'text', field: 'error', headerName: 'Error', hide: true},
            {type: 'date', field: 'created_at', headerName: 'Datetime', renderCell: (params) => ReportUtils.processDate(params.row.created_at)}
        ]
    },
    {name: 'Company management', service: 'company-historical', path: 'company-historical', columns: [
            {type: 'text', field: 'creatorEmail', headerName: 'Admin email'},
            {type: 'text', field: 'creatorFirstName', headerName: 'First name'},
            {type: 'text', field: 'creatorLastName', headerName: 'Last name'},
            {type: 'select', field: 'action', headerName: 'Action', 
                options:[
                    {value: "UPDATE", label: "UPDATE"},
                    {value: "CREATE", label: "CREATE"},
                ]},
            {type: 'text', field: 'CompanyName', headerName: 'Company name'}, 
            {type: 'text', field: 'CompanyGLN', headerName: 'Company GLN'},
            {type: 'text', field: 'error', headerName: 'Error', hide: true},
            {type: 'date', field: 'created_at', headerName: 'Datetime', renderCell: (params) => ReportUtils.processDate(params.row.created_at)}
        ]
    },
    {name: 'Login/Logout', service: 'login-logout', path: 'login-logout', columns: [
            {type: 'text', field: 'creatorFirstName', headerName: 'First name'},
            {type: 'text', field: 'creatorLastName', headerName: 'Last name'},
            {type: 'text', field: 'creatorEmail', headerName: 'Email'},
            {type: 'text', field: 'CompanyName', headerName: 'Company name'}, 
            {type: 'text', field: 'CompanyGLN', headerName: 'Company GLN'},
            {type: 'select', field: 'action', headerName: 'Action', 
                options:[
                    {value: "LOGIN", label: "LOGIN"},
                    {value: "LOGOUT", label: "LOGOUT"},
                ]},
            {type: 'text', field: 'error', headerName: 'Error', hide: true},
            {type: 'date', field: 'created_at', headerName: 'Datetime', renderCell: (params) => ReportUtils.processDate(params.row.created_at)}
        ]
    }
    ,
    {name: 'QC Management', service: 'qc-management', path: 'qc-management', columns: [
            {type: 'text', field: 'creatorFirstName', headerName: 'First name'},
            {type: 'text', field: 'creatorLastName', headerName: 'Last name'},
            {type: 'text', field: 'creatorEmail', headerName: 'Email'},
            {type: 'select', field: 'action', headerName: 'Action', 
                options:[
                    {value: "REJECTED PRODUCT", label: "REJECTED PRODUCT"},
                    {value: "ACCEPTED PRODUCT", label: "ACCEPTED PRODUCT"},
                    {value: "PRODUCT TO MANUALQC", label: "PRODUCT TO MANUALQC"},
                ]},
            {type: 'text', field: 'GTIN', headerName: 'GTIN'},
            {type: 'text', field: 'InformationProviderGLN', headerName: 'GLN'},
            {type: 'text', field: 'InformationProviderName', headerName: 'Company Name'},
            {type: 'text', field: 'Comments', headerName: 'Rejection'},
            {type: 'text', field: 'error', headerName: 'Error', hide: true},
            {type: 'date', field: 'created_at', headerName: 'Datetime', renderCell: (params) => ReportUtils.processDate(params.row.created_at)}
        ]
    }
    ,
    {name: 'Sync List Data', service: 'sync-list-data', path: 'sync-list-data', columns: [
            {type: 'text', field: 'GTIN', headerName: 'GTIN'},
            {type: 'text', field: 'SourceInformationProviderGLN', headerName: 'GLN'},
            {type: 'text', field: 'sourceName', headerName: 'Company name'},
            {type: 'text', field: 'RecipientInformationProviderGLN', headerName: 'Recipient'},
            {type: 'text', field: 'recipientName', headerName: 'Recipient name'},
            {type: 'text', field: 'gtinName', headerName: 'GTIN Name'},
            {type: 'date', field: 'publicationDate', headerName: 'Publication date', valueGetter: (params) => ReportUtils.processDate(params.row.publicationDate)},
            {type: 'select', field: 'gdsnRegistryStatus', headerName: 'GDSN Registration status', 
                options:[
                    {value: "REGISTERED", label: "REGISTERED"},
                    {value: "ACCEPTED PRODUCT", label: "ACCEPTED PRODUCT"},
                    {value: "PRODUCT TO MANUALQC", label: "PRODUCT TO MANUALQC"},
                ]},
            {type: 'select', field: 'status_name', headerName: 'GS1 Status', 
                options:[
                    {value: "PUBLISHED", label: "PUBLISHED"},
                    {value: "REJECTED", label: "REJECTED"},
                    {value: "WITHDRAWN/DELETED", label: "WITHDRAWN/DELETED"},
                    {value: "DISCONTINUED", label: "DISCONTINUED"},
                ]},
            {type: 'text', field: 'error', headerName: 'Error', hide: true},
            {type: 'date', field: 'lastSyncDate', headerName: 'Datetime', renderCell: (params) => ReportUtils.processDate(params.row.lastSyncDate)}
        ]
    }
    ,
    {name: 'Supplier report', service: 'supplier-report', path: 'supplier-report', columns: [
            {type: 'text', field: 'CompanyGLN', headerName: 'GLN'},
            {type: 'text', field: 'CompanyName', headerName: 'Company name'},
            {type: 'text', field: 'accountNumber', headerName: 'Account number'},
            {type: 'select', field: 'status', headerName: 'Company Status', 
                options:[
                    {value: "ENABLED", label: "ENABLED"},
                    {value: "DISABLED", label: "DISABLED"},
                ]},
            {type: 'text', field: 'ContactName', headerName: 'Primary Contact'},
            {type: 'text', field: 'ContactEmail', headerName: 'Email'},
            {type: 'date', field: 'lastPublishedDate', headerName: 'Last Published Date', renderCell: (params) => ReportUtils.processDate(params.row.lastPublishedDate)},
            {type: 'text', field: 'publishedGTINsCount', headerName: '# of published GTINs'},
            {type: 'text', field: 'hierarchiesCount', headerName: '# of hierarchies'}
        ]
    }
    ,
    {name: 'Data Recipient report', service: 'data-recipient-report', path: 'retailer-report', columns: [
            {type: 'text', field: 'CompanyGLN', headerName: 'GLN'},
            {type: 'text', field: 'CompanyName', headerName: 'Company name'},
            {type: 'text', field: 'accountNumber', headerName: 'Account number'},
            {type: 'select', field: 'status', headerName: 'Company Status', 
                options:[
                    {value: "ENABLED", label: "ENABLED"},
                    {value: "DISABLED", label: "DISABLED"},
                ]},
            {type: 'text', field: 'ContactName', headerName: 'Primary Contact'},
            {type: 'text', field: 'ContactEmail', headerName: 'Email'},
            {type: 'text', field: 'publishedToCount', headerName: '# of GTINs published to'},
            {type: 'text', field: 'hierarchiesCount', headerName: '# of hierarchies'}
        ]
    }
    ,
    {name: 'Product report', service: 'product-report', path: 'product-report', columns: [
            {type: 'text', field: 'InformationProviderGLN', headerName: 'Supplier GLN'},
            {type: 'text', field: 'CompanyName', headerName: 'Supplier Name'},
            {type: 'text', field: 'Category', headerName: 'Category'},
            {type: 'text', field: 'SubCategory', headerName: 'Subcategory'},
            {type: 'text', field: 'recipientCompanyName', headerName: 'Publish To'},
            {type: 'text', field: 'languageSpecificBrandName', headerName: 'Brand'},
            {type: 'text', field: 'gtinName', headerName: 'Product Name'},
            {type: 'text', field: 'status_name', headerName: 'Registration Status'},
            {type: 'date', field: 'publicationDate', headerName: 'Publication Date', renderCell: (params) => ReportUtils.processDate(params.row.publicationDate)},
            {type: 'date', field: 'lastSyncDate', headerName: 'Sync Date', renderCell: (params) => ReportUtils.processDate(params.row.lastSyncDate)},
            {type: 'text', field: 'image_id', headerName: 'With Images'}
        ]
    }
    ,
    {name: 'Historic Data', service: 'datanet-report', path: 'datanet-report', columns: [
            //{field: 'id', headerName: '', },
            {type: 'text', field: 'GTIN', headerName: 'GTIN' },
            {type: 'text', field: 'IPGLN', headerName: 'IPGLN' },
            {type: 'text', field: 'SupplierSKU', headerName: 'Supplier SKU' },
            //{type: 'text', field: 'QCStatus', headerName: 'QC Status', valueGetter: (params) => ReportUtils.processStatusQC},
            {type: 'text', field: 'QCStatusDesc', headerName: 'QC Status' },
            {type: 'date', field: 'DateCreated', headerName: 'Date Created' },
            {type: 'date', field: 'DiscontinueDate', headerName: 'Discontinue Date' },
            //{type: 'text', field: 'XMLData', headerName: 'XMLData' },
            {type: 'text', field: 'RetailerID', headerName: 'Retailer ID' },
            {type: 'text', field: 'dataRecipientGLN', headerName: 'Data Recipient GLN' },
            {type: 'text', field: 'ProductItemDataStatus', headerName: 'Item Data Status', renderCell: (params) => ReportUtils.processStatusQC},
            {type: 'date', field: 'StatusChangedDate', headerName: 'Status Changed Date' },
            {type: 'text', field: 'StatusChangedBy', headerName: 'Status Changed By', noSearch: true, renderCell: (params) => ReportUtils.processStatusQC},
            {type: 'text', field: 'XMLVersion', headerName: 'XML Version' , noSearch: true},
            {type: 'text', field: 'SourceSystem', headerName: 'Source System' },
            {type: 'text', field: 'ProductItemGTIN', headerName: 'Product Item GTIN' , noSearch: true}
        ]
    }
]

export default entities;