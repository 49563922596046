import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import useToken from '../App/useToken';
import toolsWorkListApi from "../../tools/toolsWorkListApi";
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import QcFilters from "./QcFilters";
import QcProductTable from "./QcProductTable";
import QcTotalProducts from "./QcTotalProducts";
import QcProductDetails from "./QcProductDetails";
import QcSelectSuppliersHeader from "./QcSelectSuppliersHeader";
import CircularProgress from '@mui/material/CircularProgress';
import useToolsNotifications from "../../tools/ToolsNotifications";

export default function AutoQc() {
    function getAutoQcDataResponse() {
        setLoading(true)
        getAutoQcData({"token": token}).then(result => {
            if( result != undefined && !result.error && typeof result.response === 'object') {
                setAutoQcData(result.response);
                //Summarize products by Supplier
                const products = result.response.qcproducts;
                const countedSuppliers = products.reduce((allProducts, product) => {
                  const currCount = allProducts[product.InformationProviderName] ?? 0;
                  return {
                    ...allProducts,
                    [product.InformationProviderName]: currCount + 1,
                  };
                }, {});
                setAutoQcFilter(countedSuppliers);
            }
            setLoading(false)
            console.log(loading)
        });
    }

    //Get auto qc data only for the first render
    useEffect(() => {
        getAutoQcDataResponse()
    }, []);

    const { token } = useToken();
    const [autoQcData, setAutoQcData] = useState([]);
    const [autoQcFilter, setAutoQcFilter] = useState([]);
    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const [selectedRowsCount, setSelectedRowsCount] = useState([0]);
    const { getAutoQcData, moveAutoQctoManualQc, getAutoQcById, getAutoQcRevisionById} = toolsWorkListApi();
    const [supplierFilter, setSupplierFilter] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalContentAttrLabel, setModalContentAttrLabel] = useState([]);
    const [modalContentProductRevisions, setModalContentProductRevisions] = useState([]);
    const [modalContentProducts, setModalContentProducts] = useState([]);
    const [modalContentParentProducts, setModalContentParentProducts] = useState([]);
    const [modalContentRejectionMessage, setModalContentRejectionMessage] = useState([]); 
    const [view] = useState('auto');
    const [loading, setLoading] = useState(false);
    const {error} = useToolsNotifications();
    
    const columns = [
        { field: 'GTIN', headerName: 'GTIN', minWidth: 150, sort: false},
        { field: 'gtinName', headerName: 'Description', minWidth: 380 },
        { field: 'InformationProviderName', headerName: 'Supplier', minWidth: 240},
        { field: 'InformationProviderGLN', hide: true },
        { field: 'publicationDate', headerName: 'Date', minWidth: 150 },
        { field: 'status', headerName: 'GDSN Status', minWidth: 150, renderCell: (params) => loadStatus(params.row)},
        { field: 'bypass', headerName: 'Bypass', minWidth: 100, renderCell: (params) => loadBypassButton(params.row)}
    ];

     const loadStatus = ((row)=> {
        if(row.GTIN != undefined) {
            if (row.status === 'REGISTERED') {
                return <Button variant="registered" size="small">{row.status.toLowerCase()}</Button>
            } else if(row.status === 'DISCONTINUED'){
                return <Button variant="discontinued" size="small">{row.status.toLowerCase()}</Button>
            } else if(row.status === 'IN_PROGRESS') {
                return <Button variant="inprogress" size="small">{row.status.toLowerCase()}</Button>
            } else if(row.status === '') {
                return <Button variant="nostatus" size="small">No status</Button>
            }
        }
    });


    const loadBypassButton = ((row)=> {
        if(row.GTIN != undefined) {
            return <Button variant="orange" size="small" onClick={() => {setLoading(true);handleByPassRow(row)}}>Bypass</Button>
        }
    });

    const onRowsSelectionHandler = (ids) => {
        let selectedRows = 0;
        selectedRows = ids.map((id) => autoQcData.qcproducts.find((row) => row.publishedGTIN + '-' + row.GTIN + '-' + row.InformationProviderGLN + '-' + row.targetMarket === id));
        setSelectedRowsData(selectedRows);
        setSelectedRowsCount(selectedRows.length);
    };

    const handleByPass = (() => {
        console.log(loading)
        moveAutoQctoManualQc({
            "token": token, 
            "data": selectedRowsData
        }).then(result => {
            setLoading(false);
            if(!result.error) {
                setOpenModal(false)
                getAutoQcDataResponse()
            }
        }).catch((e) => {
            setLoading(false);
            error("An unexpected error happened");
        });
    });

    const handleByPassRow = ((row) => {
        console.log(loading)
        moveAutoQctoManualQc({
            "token": token, 
            "data": [row]
        }).then(result => {
            setLoading(false);
            if(!result.error) {
                setOpenModal(false)
                getAutoQcDataResponse()
            }
        }).catch((e) => {
            setLoading(false);
            error("An unexpected error happened");
        });
    });

    const handleRowClick: GridEventListener<'rowClick'> = (row) => {
        setOpenModal(true);
        getAutoQcById({
            "token": token,
            "GTIN": row.GTIN,
            "GUID": row.GUID,
            "aID": row.aID,
            "toogle": false
        }).then(result => {
            setLoading(false);
            if(result.status === "success" && typeof result.response !== undefined) {
                setModalContentAttrLabel([result.response.attribute_label])
                if(row.GTIN === row.publishedGTIN) {
                    setModalContentParentProducts([result.response.parentproducts])
                }
                setModalContentProductRevisions([result.response.productrevisions.sort(function(a,b){return new Date(b.creationDateTime).getTime() - new Date(a.creationDateTime).getTime()})])
                setModalContentProducts([result.response.products])
                setModalContentRejectionMessage(result.response.rejectionmessage)
            }
        });
    };

    const handleOnCellClick = (params) => {
        if(typeof params !== undefined && params.field !== 'bypass') {
            if(params.field !== '__check__') {
                setLoading(true)
                handleRowClick(params.row)   
            }
        } else {
            setLoading(true)
            handleByPassRow(params.row)
        }
    };
   
    return  <Grid height="100%">
                <Header name="Auto QC"/>
                <Grid container sx={{height: '90%'}}>
                    <Grid item container xs={12} sx={{background: '#FBFBFB', height: '15%'}}>
                        <QcSelectSuppliersHeader></QcSelectSuppliersHeader>
                        <Grid item container xs={5} alignContent="center" >
                            <QcTotalProducts
                                view={view}
                                totalProducts={autoQcData.total_qcproducts !== undefined ? autoQcData.total_qcproducts:0}
                            />
                        </Grid>
                        <Grid item container xs={5} justifyContent="end" alignContent="center" paddingRight="52px">
                            <Button variant="orange-nosize" size="small" onClick={()=>{setLoading(true);handleByPass()}}><Typography sx={{fontSize: '16px'}}>Bypass {selectedRowsCount} items</Typography></Button>
                        </Grid>
                    </Grid>
                    <Grid item container sx={{height: '85%', overflowY: 'scroll', paddingBottom: '30px'}}>
                        <Grid item xs={2}>
                            <QcFilters
                                qcFilters={autoQcFilter}
                                setSupplierFilter={setSupplierFilter}
                            />
                            
                        </Grid>
                        {!loading?
                            <QcProductTable
                                view={view}
                                rows={autoQcData.qcproducts !== undefined ? autoQcData.qcproducts:[]}
                                columns={columns}
                                supplierFilter={supplierFilter}
                                onRowsSelectionHandler={onRowsSelectionHandler}
                                setQcData={setAutoQcData}
                                setQcFilter={setAutoQcFilter}
                                handleOnCellClick={handleOnCellClick}
                                handleRowClick={handleRowClick}
                                handleActionRow={handleByPassRow}
                            />
                        : <Grid container item justifyContent="center" alignContent="center" sx={{height: '85%'}}><CircularProgress /></Grid>}
                    </Grid>
                    <QcProductDetails
                        view={view}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        modalContentProducts={modalContentProducts}
                        modalContentAttrLabel={modalContentAttrLabel}
                        modalContentProductRevisions={modalContentProductRevisions}
                        modalContentParentProducts={modalContentParentProducts}
                        modalContentRejectionMessage={modalContentRejectionMessage}
                        handleActionRow={handleByPassRow}
                        getQcRevisionById={getAutoQcRevisionById}
                        handleRowClick={handleRowClick}
                        loading={loading}
                        setLoading={setLoading}
                    />
                </Grid>
                
            </Grid>;
}