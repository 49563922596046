import React from "react";
import { Grid, Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTheme } from '@mui/material/styles';

export default function ProductProperty({label, value}) {
    const theme = useTheme();
    return  <Grid item xs={12} md={6}>
                <Box width="300px">
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="PDLabel">{label}</Typography>
                        </Grid>
                        <Grid item>
                            {value === true || value === "true"? <CheckCircleOutlineIcon style={{color:theme.palette.positive.main}}/> : <Typography variant="productSubtitle"><RemoveCircleOutlineIcon/></Typography>}
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
}