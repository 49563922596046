import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextareaAutosize from '@mui/material/TextareaAutosize'; 
import { Grid, Typography, Select } from '@mui/material'; 
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export function RejectReasonDialog({ open, onCloseSuccess, onCloseFail, rejectionOptions, modalContentProducts}) {
    const [text, setText] = useState("");
    const [reasonCode, setReasonCode] = React.useState('');
    const [showCustomRejection, setShowCustomRejection] = useState(false);
    
    const handleChange = (event) => {
        setReasonCode(event.target.value);
    };
    
    const handleCloseSuccess = () => {
        onCloseSuccess(reasonCode, text);
    };

    const handleCloseFail = () => {
        onCloseFail(reasonCode, text);
    };
    
    const handleChangeCheckbox = (event) => {
        setShowCustomRejection(event.target.checked)
    };
    
    return (
        <Dialog open={open} onClose={handleCloseFail} fullWidth={true} maxWidth={"sm"} >
            <DialogTitle>Reason for rejection</DialogTitle>
            <DialogContent>
                <Grid container item>
                    {Object.entries(modalContentProducts).map(([key, value]) => 
                        <Typography style={{color: '#203040',fontSize: '15px',marginBottom: '15px', fontWeight: '500'}}>GTIN {value.GTIN}</Typography>
                    )}
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Reason</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={reasonCode}
                          label="Reason Code"
                          onChange={handleChange}
                        >
                        <MenuItem value="">Select Reason</MenuItem>
                        {rejectionOptions !== undefined && rejectionOptions.length > 0?
                        Object.entries(rejectionOptions[0]).map(([key, value]) => 
                          <MenuItem value={value.Code}>{value.Value}</MenuItem>
                        ):''}
                        </Select>
                    </FormControl>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox/>} label="Use custom rejection reason" onChange={handleChangeCheckbox}/>
                    </FormGroup>
                    {showCustomRejection?
                    <TextareaAutosize
                        aria-label="empty textarea"
                        placeholder="Reason..."
                        style={{ width: "100%" }}
                        minRows={8}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />:''}
                </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="orange" onClick={handleCloseFail}>Cancel</Button>
              <Button variant="orange" onClick={handleCloseSuccess}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
  }