import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import useToken from '../App/useToken';
import toolsMessagesBoardApi from "../../tools/toolsMessagesBoardApi";
import useToolsNotifications from "../../tools/ToolsNotifications";

export default function MessageItem({value, index, expanded, handleExpandedChange, refresh}) {
    const {updateMsg, deleteMsg} = toolsMessagesBoardApi()
    const { token } = useToken()
    const {error,  success} = useToolsNotifications();
    const [message, setMessage] = useState(undefined)

    useEffect(() => {
        if(value !== undefined && value.message !== undefined) {
            setMessage(value.message)
        }
    }, [value])

    const updateMessage = () => {
        updateMsg({message: message, token: token}, value.id).then(result => {
            if(!result.error) {
                success("Message updated succesfully");
                refresh();
            } else {
                error("Error updating the message");
            }
        })
    }

    const deleteMessage = () => {
        deleteMsg({token: token}, value.id).then(result => {
            if(!result.error) {
                success("Message deleted succesfully");
                refresh();
            } else {
                error("Error deleting the message");
            }
        })
    }

    return  <Accordion 
                expanded={expanded === index} 
                onChange={handleExpandedChange(index)} 
                sx={{width: "100%", padding: '10px', marginBottom: '10px'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={index}
                    id={index}
                >
                    <Typography sx={{ width: '33%', flexShrink: 0, fontSize:'14px', fontWeight: '700'}}>
                        {value.title}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', fontSize:'14px', fontWeight: '400'}}>{value.updated_at}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CKEditor
                        editor={ Editor }
                        data={message}
                        onChange={ ( event, editor ) => {
                            setMessage(editor.getData());
                        } }
                    />
                    <Grid container direction={"row"} spacing={1} sx={{marginTop: "5px"}}>
                        <Grid item>
                            <Button startIcon={<SaveIcon />} variant="orange" onClick={updateMessage}>Save</Button>
                        </Grid>
                        <Grid item>
                            <Button startIcon={<DeleteIcon />} variant="orange-secondary" onClick={deleteMessage}>Delete</Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
}