import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

function CircularProgressWithLabel(props) {
  const theme = useTheme();

  const setColor = ((props) => {
    let color = 'orange'
    if (props.value <= 25) {
      color = 'red'
    } else if(props.value > 25 && props.value <= 50) {
      color = 'orange'
    } else if(props.value > 50 && props.value <= 75) {
      color = 'lightOrange'
    } else {
      color = 'green'
    }
    return color
  })
  
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={100} color={setColor(props)} thickness={8} variant="determinate" {...props} value={props.value}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="circular" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic({total}) {
  const [progress, setProgress] = React.useState(1);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= total ? total : prevProgress + 1));
    }, 35);
      }, []);

  return <CircularProgressWithLabel value={progress} />;
}