import React, { useState, useEffect } from "react";
import ToolsApi from './ToolsApi';
import config from "../config/config"

export default function toolsManagementApi() {
    const { post, get, deleteCall } = ToolsApi();

    const searchManagement = async (body, path) => {
        let url = config.API_URL + "/api/"+path+"list";
        
        let query = undefined
        return post(url, query, body);
    };

    const searchManagementCount = async (body, path) => {
        let url = config.API_URL + "/api/"+path+"count";
        
        let query = undefined
        return post(url, query, body);
    };

    const getCompanyDetails = async (body) => {
        let url = config.API_URL + "/api/companydetails";
        
        let query = undefined
        return post(url, query, body);
    }

    const getDataControllers = async (query) => {
        let url = config.API_URL + "/api/users/data-controllers";
        
        let body = undefined
        return get(url, query, body);
    }

    const getUserDetails = async (body) => {
        let url = config.API_URL + "/api/getuser";
        
        let query = undefined
        return post(url, query, body);
    } 
    
    const getCompanyListFull = async (body) => {
        let url = config.API_URL + "/api/companylistfull";
        
        let query = undefined
        return post(url, query, body)
    } 

    const getCountries = async (body) => {
        let url = config.API_URL + "/api/countrylist";
        
        let query = undefined
        return post(url, query, body);
    }

    const createCompany = async (body) => {
        let url = config.API_URL + "/api/savecompany";
        
        let query = undefined
        return post(url, query, body);
    }

    const updateCompany = async (body) => {
        let url = config.API_URL + "/api/updatecompany";
        
        let query = undefined
        return post(url, query, body);
    } 

    const addDataController = async (body, company_id) => {
        let url = config.API_URL + "/api/company/" + company_id + "/add-data-controller";
        
        let query = undefined
        return post(url, query, body);
    } 

    const removeDataController = async (company_id, dc_id) => {
        let url = config.API_URL + "/api/company/" + company_id + "/data-controller/" + dc_id;
        
        let query = undefined
        let body = undefined
        return deleteCall(url, query, body);
    } 

    const updateUser = async (body) => {
        let url = config.API_URL + "/api/updateuser";
        
        let query = undefined
        return post(url, query, body);
    }

    const enableCompany = async (body) => {
        let url = config.API_URL + "/api/enable-company";
        
        let query = undefined
        return post(url, query, body);
    }

    const disableCompany = async (body) => {
        let url = config.API_URL + "/api/deletecompany";
        
        let query = undefined
        return post(url, query, body);
    }

    const createUser = async (body) => {
        let url = config.API_URL + "/api/insertuser";
        
        let query = undefined
        return post(url, query, body);
    }

    const generateQRCode = async (body) => {
        let url = config.API_URL + "/api/generate-qr-code";
        
        let query = undefined
        return post(url, query, body);
    }

    return {
        searchManagement,
        searchManagementCount,
        getCompanyDetails,
        getUserDetails,
        getDataControllers,
        getCompanyListFull,
        getCountries,
        createCompany,
        updateCompany,
        addDataController,
        removeDataController,
        updateUser,
        enableCompany,
        disableCompany,
        createUser,
        generateQRCode
    };
}