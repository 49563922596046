import { useState } from "react";

export default function useReportFilter() {
    const [filter, setFilter] = useState(
        {
            pageNumber: undefined,
            pageSize: 25,
            searchTerms: {
                QueryType: "or",
                OrderBy: "or",
            },
            token: "",
        }
    );

    const getSearchValueFilter = (key) => {
        return filter["searchTerms"][key];
    }

    const setFilterKeyValue = (key, value) => {
        let copyOfObject = { ...filter };
        copyOfObject[key] = value;
        setFilter(copyOfObject);
    };

    const setFilterSearchKeyValue = (key, value) => {
        let copyOfObject = { ...filter }
        if(value === ""){
            delete copyOfObject["searchTerms"][key];
        } else {
            copyOfObject["searchTerms"][key] = value;
        }
        setFilter(copyOfObject);
    };

    const setQueryType = (value) => {
        setFilterSearchKeyValue("QueryType", value)
    };

    const setOrderBy = (value) => {
        setFilterSearchKeyValue("OrderBy", value)
    };

    const setPageNumber = (value) => {
        setFilterKeyValue("pageNumber", value)
    };

    const setCallToken = (value) => {
        setFilterKeyValue("token", value)
    };

    return {
        filter,
        setFilterKeyValue,
        setFilterSearchKeyValue,
        setQueryType,
        setOrderBy,
        setCallToken,
        getSearchValueFilter,
        setPageNumber
    };
}
