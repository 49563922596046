import React from "react";
import { Typography, Box  } from '@mui/material';
import companyTypes from "./Detail/companyTypes";
import companyStatus from "./Detail/companyStatus"
import ProfileImg from "../ProfileImg/ProfileImg";

const entities = [
    {name: 'User management', service: 'user', path: 'user', columns: [
            {type: 'text', field: 'user_image', headerName: 'Image', flex: 0.5, minWidth: 75, renderCell: (params) => {return <ProfileImg imgUrl={params.row.user_image} />}},
            {type: 'text', field: 'id', headerName: 'ID', flex: 0.3, minWidth: 50}, 
            {type: 'text', field: 'email', headerName: 'Email', flex: 1, minWidth: 100},
            {type: 'text', field: 'first_name', headerName: 'First Name', flex: 1, minWidth: 100},
            {type: 'text', field: 'last_name', headerName: 'Last name', flex: 1, minWidth: 100},
            {type: 'text', field: 'user_telephone', headerName: 'Telephone', flex: 1, minWidth: 100},
            {type: 'text', field: 'user_type', headerName: 'Type', flex: 1, minWidth: 100},
            {type: 'text', field: 'CompanyName', headerName: 'Company Name', flex: 1, minWidth: 100},
            {type: 'text', field: 'InformationProviderGLN', headerName: 'Information Provider GLN', flex: 1, minWidth: 100},
            {type: 'text', field: 'is_active', headerName: 'Is Active', flex: 0.3, minWidth: 50, renderCell: (params) => userState(params.row.is_active)},
            {type: 'text', field: 'user_name', headerName: 'User Name', hide: true, flex: 1, minWidth: 100},
        ]
    },
    {name: 'Company management', service: 'company', path: 'company', columns: [
            {type: 'text', field: 'id', headerName: 'ID', flex: 0.3, minWidth: 50},
            {type: 'text', field: 'CompanyGLN', headerName: 'GLN', flex: 1, minWidth: 100},
            {type: 'text', field: 'CompanyName', headerName: 'Name', flex: 1, minWidth: 100},
            {type: 'text', field: 'CompanyType', headerName: 'Type', flex: 0.3, minWidth: 80, valueGetter: (params) => getCompanyType(params.row)},
            {type: 'text', field: 'status', headerName: 'Status', flex: 0.3, minWidth: 80, valueGetter: (params) => getCompanyStatus(params.row)},
            {type: 'text', field: 'count_users', headerName: 'Count Users', flex: 0.3, minWidth: 50},
            {type: 'text', field: 'enrichmentPermissions', headerName: 'Enrichment Permissions', flex: 0.3, minWidth: 120, renderCell: (params) => companyEnrichment(params.row.enrichmentPermissions)},
            {type: 'text', field: 'email', headerName: 'Email', flex: 1, minWidth: 100},
            {type: 'text', field: 'first_name', headerName: 'First Name', flex: 1, minWidth: 100},
            {type: 'text', field: 'last_name', headerName: 'Last Name', flex: 1, minWidth: 100},
            {type: 'text', field: 'user_telephone', headerName: 'User Telephone', flex: 1, minWidth: 100},
        ]
    },
]

const userState = ((active) => {
    let component = <Typography variant="userDisabled">Disabled</Typography>
    
    if(active == 1) {
        component = <Typography variant="userActive">Active</Typography>
    }

    return component
});

const getCompanyType = ((row)=> {
    return companyTypes[row.CompanyType]
});

const getCompanyStatus = ((row)=> {
   return companyStatus[row.status]
});

const companyEnrichment = ((enrichmentPermissions) => {
    return <Typography component="span" style={{fontSize: '12px', color: "#4D4D4D"}}>{enrichmentPermissions == 1?'Yes':'No'}</Typography>
});

export default entities;