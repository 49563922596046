import React, { useEffect, useState } from "react";
import Allergens from "../../ProductTab/Enrichments/Allergens";
import DAM from "../../ProductTab/Enrichments/DAM";
import Ecommerce from "../../ProductTab/Enrichments/Ecommerce";
import Healthcare from "../../ProductTab/Enrichments/Healthcare";
import Ingredients from "../../ProductTab/Enrichments/Ingredients";
import Nutritional from "../../ProductTab/Enrichments/Nutritional";
import DigitalAssets from "../../ProductTab/Enrichments/DigitalAssets";

const processAllergensRow = (rows) => {
    let res = {};
    rows.map((elem, index) => {
        if(index > 4) {
            if(res[elem[0]] === undefined){
                res[elem[0]] = [];
            }
            res[elem[0]].push({"allergenDescription" : elem[1], "LevelOfContainment" : elem[2]})
        }
    })

    return res
}

const allergensPreview = (data) => {
    return <Allergens data={data} />
}

const processDamRow = (rows) => {
    let res = {};

    let ipgln = rows[0][1]
    let tm = rows[1][1]

    rows.map((elem, index) => {
        if(index > 4) {
            if(res[elem[0]] === undefined){
                res[elem[0]] = {"image_id" : elem[0], "images": [], enrichments: true};
            }
            res[elem[0]]["images"].push({
                "GUID" : null,
                "GTIN" : elem[1],
                "image_name" : elem[7],
                "image_gtin" : null,
                "asset_name" : elem[7],
                "media_type" : null,
                "alt_tag" : elem[7],
                "effective_start_date" : elem[9],
                "effective_end_date" : elem[10],
                "created_at" : null,
                "updated_at" : null,
                "InformationProviderGLN" : ipgln,
                "TargetMarketCountryCode" : tm,
                "saveforweb" : null,
                "pID" : null,
                "thumbnail" : null,
                "damInfo" : {
                    "GUID": null,
                    "GTIN": elem[1],
                    "InformationProviderGLN": ipgln,
                    "TargetMarketCountryCode": tm,
                    "typeOfInformation": elem[2],
                    "uniformResourceIdentifier": elem[3],
                    "imageSource": elem[4],
                    "imageFacing": elem[5],
                    "imageBackground": elem[6],
                    "FileName": elem[7],
                    "fileFormatName": elem[8],
                    "fileEffectiveStartDateTime": elem[9],
                    "fileEffectiveEndDateTime": elem[10],
                    "fileVersion": elem[11],
                    "fileSize": elem[12],
                    "pID": null,
                    "created_at": null,
                    "updated_at": null,
                    "image_pID": null,
                    "source": null,
                    "lastModifiedBy": null
                }
            })
        }
    }) 

    return res
}

const damPreview = (data, ability, product) => {
    return <DAM data={data} isPreview={true} productPermission={product} userAbility={ability} />
}

const processEcommerceRow = (rows) => {
    let res = {};

    let ipgln = rows[0][1]
    let tm = rows[1][1]

    rows.map((elem, index) => {
        if(index > 4) {
            res[elem[0]] = {
                "GUID" : undefined,
                "GTIN" : elem[0],
                "InformationProviderGLN" : ipgln,
                "TargetMarketCountryCode" : tm,
                "GTIN_Name" : elem[1],
                "consumerUsageInstructions" : elem[3],
                "consumerStorageInstructions" : elem[4],
                "safetyWarnings" : elem[5],
                "tradeitemFeatureBenefit1" : elem[6],
                "tradeitemFeatureBenefit2" : elem[7],
                "tradeitemFeatureBenefit3" : elem[8],
                "tradeitemFeatureBenefit4" : elem[9],
                "tradeitemFeatureBenefit5" : elem[10],
                "tradeitemFeatureBenefit6" : elem[11],
                "tradeitemKeywords" : elem[12],
                "pID" : undefined,
                "created_at" : undefined,
                "updated_at" : undefined,
                "longProductDescription" :  elem[2],
                "source" : 'TS',
                "lastModifiedBy" : undefined,
            }
        }
    })
    
    return res
}

const ecommercePreview = (data) => {
    return <Ecommerce data={data} />
}

const processHealthcareRow = (rows) => {
    let res = {};
    rows.map((elem, index) => {
        if(index > 4) {
            if(res[elem[0]] === undefined){
                res[elem[0]] = [];
            }
            res[elem[0]].push({"agency" : elem[1], "code" : elem[2]})
        }
    })

    return res
}

const healthcarePreview = (data) => {
    return <Healthcare data={data} />
}

const processIngredientsRow = (rows) => {
    let res = {};
    rows.map((elem, index) => {
        if(index > 4) {
            if(res[elem[0]] === undefined){
                res[elem[0]] = [];
            }
            res[elem[0]].push({"ingredientStatement" : elem[1], "nonFoodIngredient" : elem[2] !== "no"})
        }
    })

    return res
}

const ingredientsPreview = (data) => {
    return <Ingredients data={data} />
}

/*

{
    "nutrientTypeCode": "CHOAVL",
    "dailyValueIntakePercent": "35.4",
    "measurementPrecisionCode": "APPROXIMATELY",
    "quantityContained": [
        "95.5 GRM"
    ]
}

*/

const processNutrientsRow = (rows) => {
    let res = {};
    rows.map((elem, index) => {
        if(index > 4) {
            if(res[elem[0]] === undefined){
                res[elem[0]] = {"processed":[], "nonProcessed":[]};
                res[elem[0]]["nonProcessed"].push({
                    "preparationStateCode" : undefined,
                    "nutrientBasisQuantity" : undefined,
                    "servingSize" : undefined,
                    "servingSizeDescription" : ["false en"],
                    "nutrientDetail" : [],
                    "calories" : {
                        "cal": 0,
                        "jul": "0 KJO"
                    },
                })
            }
            res[elem[0]]["nonProcessed"][0]["nutrientDetail"].push({
                "nutrientTypeCode": elem[1],
                "dailyValueIntakePercent": elem[5],
                "measurementPrecisionCode": "APPROXIMATELY",
                "quantityContained": [
                    elem[3] + " " + elem[4]
                ]
            })

            if(res[elem[0]]["nonProcessed"][0]["preparationStateCode"] === undefined && elem[6] !== undefined) {
                res[elem[0]]["nonProcessed"][0]["preparationStateCode"] = elem[6]
            }
            if(res[elem[0]]["nonProcessed"][0]["servingSize"] === undefined && elem[7] !== undefined) {
                res[elem[0]]["nonProcessed"][0]["servingSize"] = elem[7]
            }
            if(res[elem[0]]["nonProcessed"][0]["nutrientBasisQuantity"] === undefined && elem[8] !== undefined) {
                res[elem[0]]["nonProcessed"][0]["nutrientBasisQuantity"] = elem[8]
            }
            
        }
    })

    return res
}

const nutrientsPreview = (data) => {
    return <Nutritional data={data} />
}

const processDamImages = (images) => {
    console.log(images)
    return images
}

const imagesPreview = (data) => {
    
    return <DigitalAssets data={data} />
}

const TemplatesInfo = {
    "allergens" : { title: "allergens data" ,
                    url: "" , 
                    text: "In this section you can indicate the possible allergens appropriate to your products, and their level of containment. By enriching this data here you can ensure that your data recipients have the right allergen information to communicate possible risk to their shoppers and your consumers.<br> For ease of use, an allergen type code list is included in a separate tab in the template. <br><br>There are three levels of containment:<br> CONTAINS<br> - Intentionally included in the product.<br> FREE_FROM <br>- The product is free from the indicated substance. <br>MAY_CONTAIN<br> - substance is not intentionally included, but due to shared production facilities or other reasons, the product may contain the substance.<br> UNDECLARED<br> – not intentionally included and assessed not to require a cross-contact declaration. “Undeclared” may be defined by a regulatory authority in a target market. <br><br>Only one level of containment may be applied per GTIN and allergen type code combination.",
                    didYouKnowText: "An allergy occurs when the body's immune system sees a substance as harmful and overreacts to it. The substances that cause allergic reactions are allergens. When someone has allergies, their immune system makes an antibody, and the antibodies respond to allergens. <br>There are 14 main allergen items that must be highlighted on food ingredients lists. These are: celery, cereals containing gluten including wheat (such as spelt and Khorasan), rye, barley and oats, crustaceans – such as prawns, crabs and lobsters, eggs, fish, lupin, milk and molluscs – such as mussels and oysters.",
                    processRows: processAllergensRow, 
                    showPreview: allergensPreview },
    "digitalAssets" : { title: "digital assets" , 
                url: "" , 
                text: "In this section you can enrich the digital assets for your products, uploading single or multiple digital asset files. When loading digital asset files, the following naming convention is required: GTIN_GLN_Marketcode_ProductNameorDescription. <br>The following file types are supported in the application: .pdf .jpeg .jpg .tiff .png .gif.",
                didYouKnowText: "Product images are an essential element to ecommerce however often regular pack images, regardless of quality, simply are enough to engage and capture the attention of your customers. That’s where a HERO image comes in. A HERO image is an oversized image of your product that highlights important aspects such as brand, size, quality and differentiation points. This image will set your product apart on the digital shelf and help you to make a stronger connection with your customer towards the immediate and future path to purchase.",
                processRows: processDamImages, 
                showPreview: imagesPreview },
    "dam" : { title: "digital assets meta data" , 
                url: "" , 
                text: "In this section you can enrich information for the digital assets that are available in the application. This information includes details for the type of asset, the  source, the facing, the background, the name and validity period. This template will auto-populate with the digital asset information of all assets uploaded for your Information Provider.",
                didYouKnowText: "Digital Assets Meta Data: Metadata is used to help describe or classify digital assets and can be made up of three different types: Administrative, Descriptive or Structural.<br> 1. Administrative metadata refers to information about how long an asset may be used and the usage rights of the asset. <br> 2. Descriptive metadata refers to terms that would help with the searchability of the product. <br>3. Structural metadata refers to how the asset is configured or formatted.", 
                processRows: processDamRow,
                showPreview: damPreview },
    "healthcare" : { title: "healthcare data" ,
                    url: "" , 
                    text: "In this section you can enrich the healthcare agencies and their respective identifier codes appropriate to your listed healthcare products.<br> The enrichment of all agencies is supported, however not all are used locally. To confirm which are appropriate please contact GS1 South Africa.", 
                    didYouKnowText: "Regulatory agencies are a part of the executive branch and ensure the compliance of legislation. They monitor individual and corporate healthcare practitioners and facilities; inform the government about changes in the way the healthcare industry operates; ensure higher safety standards; and attempt to improve healthcare quality and follow local and national guidelines.",
                    processRows: processHealthcareRow, 
                    showPreview: healthcarePreview },
    "ingredients" : { title: "ingredients data" , 
                      url: "" , 
                      text: "In this section you can specify the ingredients (Food and Non-Food) of your products. By including the ingredients of your products here, in the data source for your data recipients, you can be assured that your consumers will have access to the information needed to be able to make an informed buying decision.<br><br> Note:<br> • When enriching ingredients, the text should be a single strand and all in lower case – only allergens should be capitalised. <br>• If your product is a Food product, enter 'no' in the 'Non-Food' column.", 
                      didYouKnowText: "Although it is a legal requirement, the ingredients list provides essential information to advise consumers of what they are purchasing and will eventually be using, possibly revealing hidden fats, added sugars, and other details that the Nutrition Facts label may not show.",
                      processRows: processIngredientsRow, 
                      showPreview: ingredientsPreview },
    "ecommerce" : { title: "marketing data" , 
                url: "" , 
                text: "In this section you can enrich attributes that pertain to the Finished Product and the messaging directed at the consumer. The specific attributes supported here provide valuable information to the shopper and consumer in respect of the product description, consumer usage and storage, safety warning, features, and benefits. Additionally, it provides search terms for use in ecommerce platforms to improve the searchability and location of your product online.",
                didYouKnowText: "A key element of product marketing is the product story. The information conveyed in the story enables a connection with customers and consumers by relating to real situations or needs that they have.",
                processRows: processEcommerceRow, 
                showPreview: ecommercePreview },
    "nutrients" : { title: "nutrients data" , 
                    url: "" , 
                    text: "In this section you can enrich the nutritional facts of your product. Like Ingredients, this information is valuable in helping your consumer to make an informed buying decision as well as better plan for how to consume your product.<br><br> For ease of use, a code list with the nutrient type code and nutrient name is included in a separate tab in the template. <br><br>Note: Do not use parentheses for the UOM e.g. ()", 
                    didYouKnowText: "The value of the products nutritional data exceeds meeting the legal requirements for a food label and empowers consumers with critical information to make the best choices for their health and wellbeing. <br>The information serves to help customers make informed purchasing decisions, by telling them exactly what is in a product so they can determine what the nutritional value is for their body and if that value meets their needs.",
                    processRows: processNutrientsRow, 
                    showPreview: nutrientsPreview },
}

export default TemplatesInfo;