import React from "react";
import {Grid, Typography } from '@mui/material';
import productDataDisplayName from './DisplayNames';

export default function RenderHelper() {

    const searchElement = (name, object) => {
        let element = undefined;
        if(object !== null && object !== undefined){
            if(object.hasOwnProperty(name)){
                element = object[name];
            } else {
                let values = object;
                if(typeof(object) === 'object') {
                    values = Object.values(object);
                } 
                for(let i=0; i< values.length; i++) {
                    if(typeof(values[i]) !== 'string') {
                        element = searchElement(name, values[i])
                        if(element !== undefined){
                            return element
                        }
                    }
                }
            }
        }

        return element;
    }

    const renderBasicElement = (displayName, element) => {
        return  <Grid item container direction="row">
                    <Grid item xs={6}><Typography variant="PDLabel">{displayName}</Typography></Grid>
                    <Grid item xs={6}><Typography variant="PDValue">{element !== undefined ? element : ''}</Typography></Grid>
                </Grid>
    }

    const renderObject = (obj, displayName) => {
        if(obj !== undefined && obj !== null && typeof obj === 'object') {
            let keys = Object.keys(obj);
            let data = keys.map(elem => {
                if(typeof(obj[elem]) === "object"){
                    return renderObject(obj[elem], elem)
                } else {
                    return  <Grid item container direction="row">
                                <Grid item xs={6}><Typography variant="PDLabel">{!isNaN(elem) ? productDataDisplayName[displayName] : productDataDisplayName[elem]}</Typography></Grid>
                                <Grid item xs={6}><Typography variant="PDValue">{obj[elem] !== undefined ? obj[elem]:''}</Typography></Grid>
                            </Grid>
                }
            })

            return data;    
        } else {
            return "";
        }
        
    }
    const renderElement = (displayName, element, extendedJson, data) => {
        if(extendedJson !== undefined){
            let dataElement = searchElement(element, extendedJson);
            switch(typeof(dataElement)) {
                case 'object':
                    let render;
                    if(Array.isArray(dataElement)) {
                        render = dataElement.map((e) => {
                            return renderObject(e, displayName);
                        })
                    } else {
                        render = renderObject(dataElement, displayName);
                    }                    
                    return render;
                default:
                    return renderBasicElement(displayName, data[element]);
            }
        }
        
    }
    const renderBlock = (definition, icon, title, extendedJson, data) => {
        return  <Grid item container direction="column" rowSpacing={2}>
                    <Grid item container direction="row">
                        <Typography variant="PDIcon">{icon}</Typography><Typography variant="PDTitle">{title}</Typography>
                    </Grid>
                    <Grid item container direction="column" rowSpacing={1} style={valuesStyle}>
                        {definition.map(elem => (
                            renderElement(elem[1].displayName, elem[0], extendedJson, data)
                        ))}
                    </Grid>
                </Grid> 
    }

    let valuesStyle = {paddingLeft: "25px"}

    return {
        renderBasicElement,
        renderObject,
        renderBlock,
    };
}