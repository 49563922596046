import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextareaAutosize from '@mui/material/TextareaAutosize';  

export function ReasonDialog({ open, onCloseSuccess, onCloseFail }) {
    const [text, setText] = useState("")
    
    const handleCloseSuccess = () => {
      onCloseSuccess(text);
    };

    const handleCloseFail = () => {
      onCloseFail(text);
    };

    return (
      <Dialog open={open} onClose={handleCloseFail} fullWidth={true} maxWidth={"sm"} >
        <DialogTitle>Reason</DialogTitle>
        <DialogContent>
            <Box
                noValidate
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                }}
            >
                <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Reason..."
                    style={{ width: "500px" }}
                    minRows={8}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
            </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="orange" onClick={handleCloseFail}>Cancel</Button>
          <Button variant="orange" onClick={handleCloseSuccess}>Submit</Button>
        </DialogActions>
      </Dialog>
    );
  }