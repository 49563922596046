import React from "react";
import { Grid, Typography, Button } from '@mui/material';
import ShowUsers from "./ShowUsers/ShowUsers";
import SelectDataControllers from "./SelectDataControllers";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

export default function CompanyUsers({addUserComponent, quickEdit, setUserView, setUpdated, companyId, users, dataControllers, possibleDataControllers, primary, addUser, removeUserCompany, removeDataControllerCompany}) {
    let addDataControllerComponent = <SelectDataControllers setUpdated={setUpdated} dataControllers={possibleDataControllers} companyId={companyId} />

    return  <Grid item>
                <ShowUsers removeUser={undefined} users={users} title={"Company Users"} addUser={addUserComponent} primary={primary} setUserView={setUserView}/>
                <ShowUsers removeUser={removeDataControllerCompany} users={dataControllers} title={"Data Controllers"} filterKey={"isDataController"} filterValue={true} addUser={addDataControllerComponent} primary={undefined}/>
            </Grid>
}