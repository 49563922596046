import React, { useState, useEffect } from "react";
import { Chip, Grid, Button, Typography } from '@mui/material';
import TemplatesInfo from "../Templates/TemplatesInfo.js";
import EastIcon from '@mui/icons-material/East';
import EnrichmentTemplate from "../EnrichmentTemplate"
import DOMPurify from "dompurify";

export default function StepOne({setType, type, handleNext, handlePrev}) {
    const onChangeType = (nType) => {
        if(nType === type) {
            setType(undefined)
        } else {
            setType(nType)
        }
    } 
    
    const showTypes = () => {
        let components =    Object.keys(TemplatesInfo).map((elem) => (
                                <Grid item><Button variant={elem === type ? "keywordOrangeSelected" : "keywordOrange"} onClick={() => onChangeType(elem)}><Typography variant={"enrichmentsButton"} >{TemplatesInfo[elem].title}</Typography></Button></Grid>
                            ));
    
        return components;
    }

    let gridStyle = { marginLeft: "50px", marginRight: '50px', padding: "50px", minWidth: "400px", maxWidth: "1600px", width: "100%", height: "100%", overflow: "hidden", backgroundColor : "#FBFBFB" }
    let insideGrid = { padding: "15px", backgroundColor : "#FFFFFF", borderSize: "0px" }
    let stepGridStyle = {
        boxShadow: "6px 6px 10px rgba(30, 177, 224, 0.1)",
        margin: "50px"
    }

    return  <Grid container item xs={12}>
                <Grid item container xs={12} style={stepGridStyle}>
                    <Grid item container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item>
                        <Chip label="1" color="primary" size="medium" 
                            sx={{width: "40px",
                            height: "40px",
                            borderRadius: "100px",fontFamily: 'Proxima Nova',
                            fontStyle: "normal",
                            fontWeight: "700",
                            padding: "10px",
                            fontSize: "28px",
                            lineHeight: "34px",
                            alignItems: "center",
                            textAlign: "center"}}/>
                        </Grid>
                        <Grid item><Typography variant={"mainTitleEnrichments"}>Select Enrichment</Typography></Grid>
                    </Grid>
                    <Grid item container style={{paddingLeft: '68px', paddingRight: '20px'}}>
                    <Grid item container xs={12}>
                        <Typography variant={"mainEnrichmentsText"} >
                           Enriched product data helps improve your product visibility online, the placement of your product on the digital shelf, and shares information needed by consumers to make an informed buying decision, therefore having the potential to increase your sales.
                        </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Typography variant={"mainEnrichmentsText"}>Select the section for which you would like to enrich your product data.</Typography>
                    </Grid>
                    <Grid item container xs={12} style={{paddingTop: '16px'}}  spacing={4} columnSpacing={1} rowSpacing={1}>{showTypes()}</Grid>
                    <Grid item container xs={12} ><Typography variant={"mainEnrichmentsText"}>All enrichment is completed at GTIN level and requires a leading zero.</Typography></Grid>
                    <Grid item container xs={12} columnSpacing={1} justifyContent="flex-end" p={1}><Button variant={type === undefined ? "keywordGrey" : "enrichmentsBlue"} disabled={type === undefined ? true : false}  onClick={handleNext} endIcon={<EastIcon />} ><Typography variant={"enrichmentsButton"} >Next Step</Typography></Button></Grid>
                    </Grid>
                </Grid>
                {type !== undefined ? 
                    <Grid container item xs={12} style={gridStyle} direction="row" columnSpacing={5}>
                        <Grid item  xs={6} alignContent={"center"} justifyContent={"center"} style={insideGrid}>
                            <EnrichmentTemplate type={type} />
                        </Grid>
                        <Grid item  xs={6} alignContent={"center"} justifyContent={"center"} style={insideGrid}>
                            <Grid container item xs={12} direction="column">
                                <Grid item><Typography variant="titleEnrichments" style={{width: "100%", fontSize: "20px", fontWeight: "700", textTransform: "uppercase"}} >Did you know:</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography 
                                        variant={"mainEnrichmentsText"} 
                                        style={{width: "100%"}}
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(TemplatesInfo[type].didYouKnowText, { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> 
                    :
                        ""
                }            
            </Grid>;
}