import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import { Grid, Tabs, Link, Box, Typography, Button } from '@mui/material';
import Header from "../Header/Header";
import ProductHierarchy from "../ProductHierarchies/ProductHierarchies";
import ProductSyncData from "../ProductSyncData/ProductSyncData";
import CircularStatic from "../CircularStatic/CircularStatic";
import ProductTab from "../ProductTab/ProductTab";
import useToken from '../App/useToken';
import toolsProductDetailApi from "../../tools/toolsProductDetailApi";
import useProductDetailFilter from '../App/useProductDetailFilter';
import CircularProgress from '@mui/material/CircularProgress';
import ImgProdDefault from "../../img/defaultProduct.png";
import ImgBrokenLink from "../../img/brokenLink.png";
import { useNavigate } from "react-router-dom";
import { SimpleDialog } from '../Dialog/SimpleDialog';
import { MultiExportDialog } from "../Dialog/MultiExportDialog";
import config from "../../config/config";
import typeLables from "../ProductHierarchyElement/TypeLabels";
import useUser from '../App/useUser';
import { useSelector } from 'react-redux';
import { Can } from "@casl/react";
import { Product } from "../../entities/Product";
import { Company } from "../../entities/Company";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const FOOD_CATEGORY = "50000000";

export default function ProductDetail() {
    const userAbility = useSelector((state) => state.userAbility.value);
    const navigate = useNavigate();
    const {publishedGTIN, GTIN, GLN, MarketCode} = useParams();
    const { token } = useToken();
    const { getDataDefinition, getEditProductList, getProductMenu, getProductSyncList, exportCsv, exportPdf } = toolsProductDetailApi();
    const {editProductListFilter, productSyncListFilter, setEditProductListFilter, setProductSyncListFilter, checkCompleteEditProductList, checkCompleteProductSyncList,} = useProductDetailFilter();
    const [loading, setLoading] = useState(true);
    const [dataDefinition, setDataDefinition] = useState(undefined);
    const [productMenu, setProductMenu] = useState([]);
    const [product, setProduct] = useState(undefined);
    const [syncList, setSyncList] = useState([]);
    const [withdrawnList, setWithdrawnList] = useState([]);
    const [hierarchy, setHierarchy] = useState(0);
    const [enrichments, setEnrichments] = useState(undefined);
    const [completeness, setCompleteness] = useState(0);
    const [exportCsvData, setExportCsvData] = useState(undefined);
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [exportModalTitle, setExportModalTitle] = useState(undefined);
    const [exportModalComponent, setExportModalComponent] = useState(undefined);
    const [multiExportModalOpen, setMultiExportModalOpen] = useState(false);
    const [multiExportData, setMultiExportData] = useState(undefined);
    const [packagingInformation] = useState([]);
    const [productPermission, setProductPermission] = useState(undefined);
    const [companyPermission, setCompanyPermission] = useState(new Company(undefined, undefined, undefined, undefined))
    const [refresh, setRefresh] = useState(true)
    const { user, getUser } = useUser();
    const [messageId, setMessageId] = useState('');
    const [imgSrc, setImgSrc] = useState(ImgBrokenLink);

    const userHasPermission = () => {
        if(user != undefined) {
            return user.user_type === "1"
        }
    }

    useEffect(() => {
        getDataDefinition().then(result => {
            if(!result.error) {
                if(result.data != undefined) {
                    setDataDefinition(result.data)
                }
            }
        });
        getProductMenu().then(result => {
            if(!result.error) {
                if(result.data != undefined) {
                    setProductMenu(result.data)
                }
            }
        });

        setEditProductListFilter({cu_gtin_code: GTIN, ipGLN: GLN, marketCode: MarketCode, token: token});
        setProductSyncListFilter({gln: GLN, gtin: GTIN, marketCode: MarketCode, publishedGTIN: publishedGTIN, token: token});
        
    }, [publishedGTIN, GTIN, GLN, MarketCode])

    useEffect(() => {
        if(checkCompleteEditProductList()) {
            getEditProductList(editProductListFilter).then(result => {
                if(result.code === 1) {
                    if(result.response != undefined && result.response.new_product != undefined && result.response.new_product.products != undefined) {
                        if(Array.isArray(result.response.new_product.products) && result.response.new_product.products.length > 0){
                            let product = result.response.new_product.products[0];
                            setProduct(product);

                            setProductPermission(new Product(product.pID, product.InformationProviderGLN, product.gtin))
                            setCompanyPermission(new Company(user.company_id, user.InformationProviderGLN, undefined, user.enrichmentPermissions))

                            let marketing = {'1ws': product.enrichments['1WS_Processed'].ecommerce, 'TS' : product.enrichments['TrustedSource'].ecommerce}
                            let ingredients = {'1ws': product.enrichments['1WS_Processed'].ingredients , 'TS' : product.enrichments['TrustedSource'].ingredients, 'Food' : product.Category === FOOD_CATEGORY ? true : false};
                            let nutritional = {'1ws': {'processed': product.enrichments['1WS_Processed'].nutritional, 'nonProcessed' : undefined}, 'TS': {'processed': product.enrichments['TrustedSource'].nutritional, 'nonProcessed' : undefined}};
                            let allergens = {'1ws': product.enrichments['1WS_Processed'].allergens, 'TS' : product.enrichments['TrustedSource'].allergens};
                            let healthcare = {'1ws': product.enrichments['1WS_Processed'].healthcare, 'TS' : product.enrichments['TrustedSource'].healthcare};
                            let dam = {'image_id' : product.image_id, 'images' : product.images};
                            let packaging = {'1ws': product.enrichments['1WS_Processed'].packaging, 'TS' : []};
                            let sustainability = {'1ws': product.enrichments['1WS_Processed'].sustainability, 'TS' : []};
                            
                            // Nutritional non processed
                            product.enrichments['1WS'].map((elem) => {
                                if(Object.keys(elem).indexOf("nutrientInformation") !== -1){
                                    nutritional['1ws']['nonProcessed'] = elem["nutrientInformation"];
                                }
                            })

                            delete product.enrichments;
                            delete product.healthcare;

                            setCompleteness(product.enrichmentInfo.completed*100/product.enrichmentInfo.total);
                            setEnrichments({
                                "basicData" : product,
                                "ecommerce" : marketing, 
                                "ingredients" : ingredients,
                                "nutritional" : nutritional,
                                "allergens" : allergens,
                                "healthcare" : healthcare,
                                "dam" : dam,
                                "packaging": packaging,
                                "sustainability": sustainability
                            });
                            
                            if(product.hierarchy !== undefined) {
                                product.hierarchy.map((elem, index) => {
                                    if(publishedGTIN === elem[0].gtin) {
                                        setHierarchy(index)
                                    }
                                })
                            }
                        }
                    }
                }
                setRefresh(false)
            });
        }
    }, [editProductListFilter])

    useEffect(() => {
        if(checkCompleteProductSyncList()) {
            getProductSyncList(productSyncListFilter).then(result => {
                if(result.code === 1) {
                    if(result.response != undefined){
                        if( result.response.sync != undefined && Array.isArray(result.response.sync)) {
                            if(user.user_type == 2) {
                                let ipglns = []
                                result.response.sync.map((value)=>{
                                    if(value.GLN == user.InformationProviderGLN) {
                                        ipglns.push(value)
                                    }
                                })
                                let orderedSync = ipglns.sort((a, b) => a.lastSyncDate.localeCompare(b.lastSyncDate));
                                setSyncList(ipglns)
                            } else {
                                let orderedSync = result.response.sync.sort((a, b) => a.lastSyncDate.localeCompare(b.lastSyncDate)); 
                                setSyncList(orderedSync);
                            }
                        }
                        if( result.response.withdrawn != undefined && Array.isArray(result.response.withdrawn)) {
                            setWithdrawnList(result.response.withdrawn);
                        }
                    }
                }
            });
        }
    }, [productSyncListFilter])

    useEffect(() => {
        if(exportCsvData !== undefined) {
            exportCsv(exportCsvData).then(result => {
                if(result.code === 1) {
                    setExportModalTitle("Export CSV Successfully");
                    setExportModalComponent( <Button 
                                            variant='main' 
                                            component={Link}
                                            href={result.response}
                                            download="export.csv"
                                            onClick={(e) => setExportModalOpen(false)}>
                                                    Download csv
                                            </Button>);
                } else {
                    setExportModalTitle("Export Error");
                    setExportModalComponent(<Typography>An error occurred during the export</Typography>);
                }
            });
            setExportCsvData(undefined);
        }
    }, [exportCsvData])

    useEffect(()=> {
        if(exportModalTitle !== undefined && exportModalComponent !== undefined){
            setExportModalOpen(true);
        }
    }, [exportModalTitle, exportModalComponent])

    useEffect(() => {
        if(multiExportData !== undefined){
            exportPdf(multiExportData).then(result => {
                if(result.code === 1) {
                    setExportModalTitle("Export PDF Successfully");
                    setExportModalComponent( <Button 
                                            variant='main' 
                                            component={Link}
                                            href={result.response}
                                            download="multiexport.zip"
                                            target="_blank"
                                            onClick={(e) => setExportModalOpen(false)}>
                                                    Download pdf
                                            </Button>);
                } else {
                    setExportModalTitle("Export Error");
                    setExportModalComponent(<Typography>An error occurred during the export</Typography>);
                }
            });
        }
    }, [multiExportData])

    useEffect(() => {
        setMessageId(syncList !== undefined && syncList.length > 0?syncList[0].messageID !== undefined?syncList[0].messageID:'':'');
    }, [syncList]);

    useEffect(() => {
        if(product) {
            let image = undefined;
            let images = product.images;
            images.map((imageElem) => {
                if(imageElem.pID === product.image_id) {
                    image = imageElem.image_name;
                }
            })

            if(image != undefined) {
                let img = image;

                if(img.indexOf("http") != -1){
                    img = config.mediaPath +'thumbnail?url=' + encodeURIComponent(img);
                }else{
                    img = config.mediaPath + 'thumbnail?url=' + encodeURIComponent(config.API_URL + config.detailmgPath + img);
                }

                setImgSrc(img);

                return 
            } else {
                setImgSrc(ImgProdDefault);
            }
        }
    }, [product]);

    const imageOnErrorHandler = ((e) => {
        e.target.onError = null;
        e.target.src = {ImgBrokenLink};
        setImgSrc(ImgBrokenLink);
    })
    
    const hierarchyElementClick = ((GTIN) => {
        setRefresh(true)
        let targetUrl = "/product-detail/" + publishedGTIN + "/" + GTIN + "/" + GLN + "/" + MarketCode;
        navigate(targetUrl);
    })

    const enrichmentsClick = (() => {
        let targetUrl = "/importexcelfile";
        navigate(targetUrl);
    })

    const handleExportCsv = (() => {
        if(product !== undefined && product.hierarchy !== undefined){
            let processedElems = [];
            product.hierarchy[hierarchy].map((elem) => {
                processedElems.push({
                    GTIN: elem.GTIN,
                    InformationProviderGLN: elem.InformationProviderGLN,
                    TargetMarketCountryCode: elem.TargetMarketCountryCode,
                })
            })
            setExportCsvData({search_gtin: processedElems, token: token})
        }
    })

    const handleMultiExport = (nameFormat) => {
        if(product !== undefined) {
            setMultiExportData({
                search_gtin: [{
                    GTIN: publishedGTIN,
                    InformationProviderGLN: GLN,
                    TargetMarketCountryCode: MarketCode,
                }], 
                token: token, 
                format: nameFormat
            })
        }
        
    }

    const handleExportPdf = (() => {
        setMultiExportModalOpen(true);
        
    })

    const handleCloseExportModal = (() => {
        setExportModalOpen(false);
    })

    const handleCloseMultiExportModalSuccess = ((nameFormat) => {
        handleMultiExport(nameFormat);
        setMultiExportModalOpen(false);
    })

    const handleCloseMultiExportModalFail = (() => {
        setMultiExportModalOpen(false);
    })


    let bodyWrapperStyle = {width:"100%", height:"90%"};
    let imgStyle = {width: "50%",};
    let inputText = {fontWeight: "100", width:"100%"};   
    let marker1 = {backgroundColor: "orange"};
    let rigthColumn = {height: '100%', overflowY: 'scroll'};
    let rejectedReason = {background: 'linear-gradient(0deg, rgba(227, 5, 5, 0.2), rgba(227, 5, 5, 0.2)), #FFFFFF', border: '1px solid #E30505', borderRadius: '4px', padding: '12px 20px'};

    return  <Grid container direction="column" height="100%">
                <Header name="Product Detail"/>
                {userAbility !== undefined ?
                    
                    <Grid container direction="row" style={bodyWrapperStyle}>
                        <Grid container height="100%" item direction="column" xs={12} sm={12} md={12} lg={8} xl={9}>
                            {product !== undefined ? 
                                <Grid sx={{ height: { xs: "30%", md: "30%", lg: "30%", xl: "25%" }, }} container direction="row" paddingTop="20px" paddingBottom="20px">
                                    <Grid container direction="column" xs={2} justifyContent="center" alignItems="center">
                                        <Box component="img" style={imgStyle} src={imgSrc} onError={imageOnErrorHandler}></Box>
                                    </Grid>
                                    <Grid container direction="column" xs={6} alignItems="flex-start" justifyContent="center" rowSpacing={1}>
                                        <Grid item><Typography variant="productTitle">{product.gtinName}</Typography></Grid>
                                        <Grid item><Typography variant="productType">{typeLables[product.productType]}</Typography></Grid>
                                        <Grid item><Typography variant="productSubtitle">{product.informationProviderName}</Typography></Grid>
                                    </Grid>
                                    <Grid container direction="column" xs={2} justifyContent="center" alignItems="center">
                                        <Grid item>
                                            <CircularStatic total={completeness} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="blue">Completeness</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" xs={2} justifyContent="center" alignItems="center" rowSpacing={0.5}>
                                        <Can I="enrich" this={companyPermission} ability={userAbility}>
                                            <Grid item width={"60%"}>
                                                <Button width="120px" variant="orange-secondary" style={inputText} onClick={enrichmentsClick} >Enrichments</Button>
                                            </Grid>
                                        </Can>
                                        <Grid item width={"60%"}><Button width="120px" variant="orange" style={inputText} onClick={handleExportCsv} >Export csv</Button></Grid>
                                        <Grid item width={"60%"}><Button width="120px" variant="orange" style={inputText} onClick={handleExportPdf} >Export pdf</Button></Grid>
                                    </Grid> 
                                    {product.rejectionReason !== undefined && product.rejectionReason !== null && product.rejectionReason !== ''?
                                        <Grid container item xs={12} sx={{paddingLeft: '50px'}}>
                                            <Grid container item style={rejectedReason} alignContent="center" xs={10} justifyContent="start">
                                                <WarningAmberIcon color="rejectedReason" /><Typography sx={{fontSize: '14px', lineHeight: '17px', color: '#B91C1C', paddingTop: '3px', paddingLeft: '10px'}}> Rejected Product: {product.rejectionReason}</Typography>
                                            </Grid>
                                        </Grid>
                                    :''}                     
                                </Grid>
                                :
                                <CircularProgress />
                            }
                            <Grid sx={{ height: { xs: "70%", md: "70%", lg: "70%", xl: "75%" }, }} container direction="row" >
                            {refresh?
                                <CircularProgress />
                                :
                                <ProductTab tabsDefinition={productMenu} enrichments={enrichments} definition={dataDefinition} productPermission={productPermission} userAbility={userAbility} messageId={messageId}/>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item direction="column"  xs={0} sm={0} md={0} lg={4} xl={3} style={rigthColumn}>
                            <Grid container direction="row" >
                                {product !== undefined && product.hierarchy !== undefined && product.hierarchy.length > 0
                                ?
                                    <ProductHierarchy hierarchies={product.hierarchy} hierarchy={hierarchy} setHierarchy={setHierarchy} hierarchyElementClick={hierarchyElementClick} setRefresh={setRefresh}/>
                                    : 
                                    <CircularProgress />}
                            </Grid>
                            <Grid height="15%" container direction="column">
                                {syncList !== undefined && syncList.length> 0 
                                ?
                                    <ProductSyncData 
                                        sync={syncList} 
                                        withdrawn={withdrawnList}
                                        filter={editProductListFilter}
                                        setFilter={setEditProductListFilter}
                                        setMessageId={setMessageId}
                                    />
                                : 
                                    ''}
                            </Grid>
                        </Grid> 
                    </Grid>
                :
                    ''}
                <SimpleDialog 
                    open={exportModalOpen} 
                    onClose={handleCloseExportModal} 
                    title={exportModalTitle} 
                    component={exportModalComponent}
                    textButton="Close"
                />
                <MultiExportDialog 
                    open={multiExportModalOpen} 
                    onCloseSuccess={handleCloseMultiExportModalSuccess}
                    onCloseFail={handleCloseMultiExportModalFail}
                />
            </Grid>
}