const propertiesDefinition = [
    { key: "isTradeItemDispatchUnit", displayName : "DISPATCH UNIT"}, 
    { key: "hasBatchNumber", displayName : "BATCH NUMBER"},
    { key: "vat_exempt", displayName : "VAT EXEMPT"},
    { key: "isConsumerUnit", displayName : "CONSUMER UNIT"},
    { key: "isInvoiceUnit", displayName : "INVOICE UNIT"}, 
    { key: "isNetContentDeclaration", displayName : "NET CONTENT DECLARED"},
    { key: "geneticallyModifiedDeclarationCode", displayName : "GENETICALLY MODIFIED"},
    { key: "isOrderableUnit", displayName : "ORDER UNIT"},
    { key: "isVariableWeightItem", displayName : "VARIABLE WEIGHT ITEM"} ,
    { key: "dangerousGoodsHazardousCode", displayName : "DANGEROUS GOODS"},
    { key: "packagingMarkedReturnable", displayName : "RETURNABLE"},
    { key: "productMarkedWithIngredients", displayName : "MARKED WITH INGREDIENTS"},
    { key: "handlingInstructionsCodeReference", displayName : "HANDLING INSTRUCTIONS"},
    { key: "isBaseUnit", displayName: 'BASE UNIT'},
    { key: "isBarcodeSymbologyDerivable", displayName: "Barcode Symbology Derivable"},
    { key: "isBarCodeOnPkgVariableMeasureBarCode", displayName: "IS BAR CODE ON PKG VARIABLE MEASURE"},
    { key: "isTradeItemImplantable", displayName: "IS TRADE ITEM IMPLANTABLE"},
    { key: "isTradeItemRequiredToRemainSterile", displayName: "IS TRADE ITEM REQUIRED TO REMAIN STERILE"},
    { key: "isNetContentDeclaration", displayName: "IS NET CONTENT DECLARATION"},
    { key: "isRawMaterialIrradiated", displayName: "IS RAW MATERIAL IRRADIATED"},
    { key: "isTradeItemADisplayUnit", displayName: "IS TRADE ITEM A DISPLAY UNIT"},
    { key: "isRegulatedForTransportation", displayName: "IS REGULATED FOR TRANSPORTATION"}
]

export default propertiesDefinition;