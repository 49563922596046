import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Fab, Chip, Typography, Box } from '@mui/material';
import ProductHierarchy from "../ProductHierarchy/ProductHierarchy";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; 

export default function ProductHierarchies({hierarchies,hierarchy, setHierarchy, hierarchyElementClick, setRefresh}) {
    const navigate = useNavigate();
    const {GTIN, GLN, MarketCode} = useParams();

    const left = () => {
        setRefresh(true)
        setHierarchy(hierarchy-1 >= 0 ? hierarchy-1 : 0)
    }

    const right = () => {
        setRefresh(true)
        setHierarchy(hierarchy+1 < hierarchies.length ? hierarchy+1 : hierarchies.length-1)
    }

    useEffect(() => {
        
        let publishedGTIN = hierarchies[hierarchy][0].GTIN;
        let targetUrl = "/product-detail/" + publishedGTIN + "/" + GTIN + "/" + GLN + "/" + MarketCode;
        navigate(targetUrl);
    },[hierarchy])

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    
    return  hierarchies !== undefined 
            ? 
                <Grid Grid container justifyContent="center" alignItems="center" padding="15px 25px" height="100%" width="100%">
                    <Grid 
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item>
                            <Fab 
                                size="small"
                                color="primary" 
                                aria-label="add"
                                disabled={hierarchy-1 < 0} 
                                onClick={left}
                            >
                                <ArrowBackIosNewIcon />
                            </Fab>
                        </Grid>
                        <Grid item>
                            <Typography variant="blueHierarchy">Hierarchies </Typography><Typography variant="importantHierarchy">{hierarchy+1}/{hierarchies.length}</Typography>
                        </Grid>
                        <Grid item>
                            <Fab 
                                size="small"
                                color="primary" 
                                aria-label="add"
                                disabled={hierarchy === hierarchies.length-1} 
                                onClick={right}
                            >
                                <ArrowForwardIosIcon />
                            </Fab>
                        </Grid>
                        
                        
                    </Grid>                    
                    <Grid item container height="100%" width={"100%"} style={{overflow: "auto"}}>
                        {hierarchies.map((hierarchyElem, key) =>(
                            <TabPanel value={hierarchy} index={key} style={{width: "100%"}} className="hierarchies">
                                <ProductHierarchy data={hierarchyElem} hierarchyElementClick={hierarchyElementClick} />
                            </TabPanel>
                        ))}
                    </Grid>
                </Grid>
            : 
            '';
            

}