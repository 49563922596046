import React from "react";
import DashPanel from "./DashPanel";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

export default function PublishedDashPanel({panel}) {
    let panelStyle = {
        backgroundColor: "#1EB1E0", 
        color: "white",
        borderRadius: "10px",
        padding: "25px 10px 25px 40px",
        justifyContent: "center",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "22px",
        marginRight: "10px",
    }
    return  <DashPanel panelStyle={panelStyle} panelData={panel} icon={<AssignmentTurnedInIcon />} status={"1"}/>;
}