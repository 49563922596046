import React, { useState, useEffect } from "react";
import ToolsApi from './ToolsApi';
import config from "../config/config"

export default function toolsPrefixManagementApi() {
    const { post, put } = ToolsApi();

    const getPrefix = async (body) => {
        let url = config.API_URL + "/api/get_prefix";
        
        let query = undefined
        return post(url, query, body);
    } 
    /**
     * 
     *  prefix_id: 90422
     *  token: "e8199d3149541f493d84a3e41aee1b07"
     */
    const getPrefixDetails = async (body) => {
        let url = config.API_URL + "/api/getprefixdetails";
        
        let query = undefined
        return post(url, query, body);
    } 

    const getCompanySupplierList = async (body) => {
        let url = config.API_URL + "/api/companysupplierlist";
        
        let query = undefined
        return post(url, query, body);
    }  
    
    const getCompanyList = async (body) => {
        let url = config.API_URL + "/api/companylist";
        
        let query = undefined
        return post(url, query, body);
    } 

    const suspendPrefix = async (body) => {
        let url = config.API_URL + "/api/suspendprefix";
        
        let query = undefined
        return post(url, query, body);
    }
    
    const cancelPrefix = async (body) => {
        let url = config.API_URL + "/api/deleteprefix";
        
        let query = undefined
        return post(url, query, body);
    } 
 
    const transferPrefix = async (body) => {
        let url = config.API_URL + "/api/transferprefix";
        
        let query = undefined
        return post(url, query, body);
    }

    const activatePrefix = async (query, body, prefix_id) => {
        let url = config.API_URL + '/api/prefix/' + prefix_id + '/activate'

        return put(url, query, body);
    }

    const createPrefix = async (body) => {
        let url = config.API_URL + "/api/create_prefix";
        
        let query = undefined
        return post(url, query, body);
    }

    const updatePrefix = async (body) => {
        let url = config.API_URL + "/api/update_prefix";
        
        let query = undefined
        return post(url, query, body);
    }

    return {
        getPrefix,
        getPrefixDetails,
        getCompanySupplierList,
        getCompanyList,
        suspendPrefix,
        cancelPrefix,
        transferPrefix,
        activatePrefix,
        createPrefix,
        updatePrefix,
    };
}